@use "./abstracts" as *;
@use '../styles/abstracts/mixins';

.dark {
  * {
    --bg: var(--clr-grey-900);
    --bg-nav: var(--clr-grey-800);
    --text: var(--clr-grey-100);
    --link: var(--clr-grey-50);
  }
}

html {
  // Baseline
  --primary: var(--clr-blue-700);
  --delete: var(--clr-red-600);
  --bg: var(--clr-grey-50);
  --bg-nav: var(--clr-grey-900);
  --text: var(--clr-grey-1000);
  --text-dim: var(--clr-grey-900);
  --link: var(--clr-blue-700);
  // Hover
  --delete-hover: var(--clr-red-700);
  --primary-hover: var(--clr-blue-900);
  --link-hover: var(--clr-blue-800);
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0 !important;
}

#root {
  height: 100%;
}

*:not(i) {
  font-family: var(--font-family-sans-serif);
  font-weight: var(--fw-regular);
}

main {
  display: block;
}

h1 {
  font-size: 2em;
  margin: 0;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

a {
  background-color: transparent;
}

abbr, address, article, aside, audio, b, blockquote, body, canvas, caption, cite, code, dd, del, details, dfn, div, dl, dt, em, fieldset, figcaption, figure, footer, form, h1, h2, h3, h4, h5, h6, header, hgroup, html, i, iframe, ins, kbd, label, legend, li, mark, menu, nav, object, ol, p, pre, q, samp, section, small, span, strong, sub, tbody, td, tfoot, th, thead, time, tr, ul, var, video {
  margin: 0;
  padding: 0;
  vertical-align: top;
  -webkit-box-sizing: border-box;
  box-sizing: border-box
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none;
}

* {
  margin: 0;
  padding: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  display: block;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
}

body {
  display: block;
  color: #252525;
  font-size: 16px;
  line-height: 1.46;
  -webkit-font-smoothing: antialiased;
}

button, input, select, textarea {
  font-family: inherit;
  vertical-align: baseline;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  border-radius: 0;
}

input[type="button"], input[type="reset"], input[type="submit"] {
  cursor: pointer;
}

fieldset {
  border: 0 none;
  padding: 0;
  margin: 0;
}

legend {
  display: none;
}

button[disabled] {
  opacity: 0.4;
}

[dir=rtl] input[type=email], [dir=rtl] input[type=number], [dir=rtl] input[type=tel], [dir=rtl] input[type=url], [dir=rtl] input[type=password] {
  direction: ltr;
  text-align: right;
}

input[type=text]::-ms-clear, input[type=tel]::-ms-clear, input[type=email]::-ms-clear, input[type=number]::-ms-clear {
  display: none;
}

select::-ms-expand {
  display: none;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #fff inset;
  -moz-box-shadow: 0 0 0 1000px #fff inset;
  box-shadow: 0 0 0 1000px #fff inset;
}

input::placeholder {
  color: #c7c7c7;
}

html, body {
  margin: 0;
  padding: 0;
}

a {
  color: #252525;
}

a.link {
  color: #909090;
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  font-size: inherit;
  line-height: inherit;
}

ul, li, ol {
  list-style: none;
}

body {
  height: 100%;
  //background-color: #f4f4f4;
}


input[type="checkbox"]:not(old), input[type=radio]:not(old) {
  margin: 0 0 -28px 0;
  padding: 0;
  opacity: 0;
  width: 30px;
  height: 30px;
  display: block;
}

input[type="checkbox"]:not(old) + label, input[type=radio]:not(old) + label {
  display: block;
  float: left;
  background: transparent;
  word-wrap: break-word;
  word-break: break-word;
  cursor: pointer;
}

[lang="ko"] input[type="checkbox"]:not(old) + label, input[type=radio]:not(old) + label {
  word-break: keep-all;
}

[dir="rtl"] input[type="checkbox"]:not(old) + label, [dir="rtl"] input[type=radio]:not(old) + label {
  float: right;
  padding-right: 30px;
  padding-left: 0;
}

input[type="checkbox"]:not(old) + label, input[type=radio]:not(old) + label {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.5;
  color: #636363;
}

input[type="checkbox"]:not(old) + label > span, input[type=radio]:not(old) + label > span {
  display: inline-block;
  width: 21px;
  height: 21px;
  margin: 2px 13px 10px 0;
  border-radius: 100%;
  vertical-align: middle;
}

input[type="checkbox"]:not(old) + label > span {
  margin-right: 18px;
}

[dir="rtl"] input[type="checkbox"]:not(old) + label > span {
  margin-left: 18px;
  margin-right: 0;
}

input[type=radio]:not(old) + label > span {
  margin-right: 18px;
  margin-left: 0px;
  top: 3px;
}

[dir="rtl"] input[type=radio]:not(old) + label > span {
  margin-left: 18px;
  margin-right: 0px;
  left: inherit;
  right: 2px;
}

input[type="checkbox"]:not(old) + label > span, input[type=radio]:not(old) + label > span {
  position: absolute;
  top: -2px;
  left: 4px;
  background-color: transparent;
  border: 1px solid #8f8f8f;
}

[dir="rtl"] input[type="checkbox"]:not(old) + label > span {
  position: absolute;
  left: auto;
  right: 2px;
}

input[type="checkbox"]:not(old):checked + label > span {
  background-color: #0381fe;
  border-color: #0381fe;
}

input[type="checkbox"]:not(old):checked + label > span:after {
  box-sizing: border-box;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  position: relative;
  left: 6px;
  top: 2px;
  width: 8px;
  height: 11px;
  border-width: 2px;
  color: #fafafa;
  content: '';
  display: table;
  border-style: solid;
  border-top: 0;
  border-left: 0;
}

[dir="rtl"] input[type="checkbox"]:not(old):checked + label > span:after {
  right: 5px;
  left: inherit;
}

input[type=radio]:not(old):checked + label > span:after {
  display: block;
  content: '';
  position: absolute;
  top: 3px;
  left: 3px;
  width: 13px;
  height: 13px;
  background: #3e91ff;
  border-radius: 50%;
  z-index: -5;
}

[dir="rtl"] input[type=radio]:not(old):checked + label > span:after {
  left: inherit;
  right: 3px;
}

input[type=radio]:not(old):checked + label > span {
  background-color: #fff;
  border-color: #3e91ff;
}

input[type="checkbox"]:not(old):disabled + label, input[type=radio]:not(old):disabled + label {
  opacity: 0.4;
}

input[type="checkbox"]:not(old):disabled + label > span, input[type=radio]:not(old):disabled + label > span {
  opacity: 0.4;
}

input[type="checkbox"]:not(old) + label, input[type="radio"]:not(old) + label {
  position: relative;
  padding-left: 33px;
}

button:focus {
  box-shadow: 0 0 0 0.2rem rgba(62, 145, 255, 0.40);
  outline: 0;
}

a:focus, input[type="checkbox"]:not(old):focus + label > span, input[type=radio]:not(old):focus + label > span, input[type="search"]:not(old):focus {
  box-shadow: 0 0 0 0.1rem rgba(62, 145, 255, .40);
  outline: 0;
}

.react-datepicker{
  li{
    height: 2.3rem !important;
  }
}

.caption {
  font-size: 0.812rem;
}

.pages-layout {
  display: flex;
  flex-direction: column;
  padding: 3rem 9.37rem 1.56rem 9.37rem;
  row-gap: 1rem;

  @media only screen and (max-width: 1024px) {
    padding: 2rem 2.43rem 1.56rem 2.43rem;
  }

  @media only screen and (max-width: 425px) {
    padding: 1rem 0.93rem 1.56rem 0.93rem;
  }
}
