@use '../../../styles/abstracts/mixins';
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;

  .title {
    width: clamp(15ch, 80%, 35ch);
    font-size: 2.5rem;
    font-weight: var(--fw-semi-bold);
    line-height: 125%;
    text-align: center;
    align-self: center;
    padding: 1rem 0 1rem 0;
    @include  mixins.phone{
      font-size: 1.5rem;
    }
  }

  .carousel {
    display: flex;
    flex-direction: row;
    position: relative;
    width: 100%;
    height: 12rem;

    .slider {
      padding: 1rem 1rem;
      width: 100%;
      position: relative;
      margin: 0 auto;

      .content{
        width: 100%;
      }

      .slide {
        margin: 1rem;
        width: 12rem;
        height: 6rem;
        position: relative;

        .logoImg{
          padding: .5rem;
          width: 100%;
          height: 100%;
          object-fit: contain;
          @media only screen and (max-width: 767px) {
              width: 75%;
              height: 75%;
          }
          @media only screen and (min-width: 768px) and (max-width: 1024px) {
              width: 90%;
              height: 90%;

            }
        }

        .hasCertificat{
          cursor: pointer;
        }

        .navLink{
          width: 100%;
          height: 100%;
        }

      }
    }

    .btnLayout {
      position: absolute;
      display: inline-flex;
      justify-content: space-between;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      padding-bottom: 1rem;
      @include mixins.phone {
        display: none;
      }

      .btn {
        z-index: 2;
        width: 2.5rem;
        height: 2.5rem;
        margin: 0;
        padding: 0;
        border: 0;
        border-radius: 15%;

        .icon {
          font-size: 3rem;
        }
      }

      .btnBack {
        left: 0;
      }

      .btnNext {
        left: 100%;
      }

      button {
        background-color: transparent;
      }

      button[disabled] {
        opacity: 0;
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .logoImg {
    width: 20%;
    height: 20%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .logoImg {
    width: 75%;
    height: 75%;

  }
}