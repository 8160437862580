@use '../../../../styles/abstracts/mixins';
@use "../../../../styles/abstracts/variables" as *;

.order_card {
  & header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.625rem;
    background-color: $color_neutral_grey_light;

    label {
      font-size: 0.812rem;
    }

    p {
      font-weight: var(--fw-semi-bold);
    }

    @include mixins.phone {
      flex-direction: column;
    }
  }

  & main {
    display: flex;
    flex-direction: row;
    column-gap: 1.25rem;
    justify-content: space-between;

    &>div:first-child {
      width: 100%;
    }

    .options {
      display: flex;
      flex-direction: column;
      row-gap: 0.652rem;
      justify-content: space-between;
      min-width: 250px;

      .reports {
        display: flex;
        flex-direction: column;
        row-gap: 0.652rem;
      }
    }

    @include mixins.phone {
      flex-direction: column;
      row-gap: 0.652rem;
    }
  }
}