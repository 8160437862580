@use '../../../styles/abstracts/mixins';

.loading {
  display: flex;
  justify-content: center;
  margin-top: 4rem;
}
.bannerLogo {
  max-height: 40rem;
  width: 100%;
  padding: 0 10rem;
  @include mixins.phone {
    padding: 0;
  }
}
.layout {
  min-height: 40rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 10rem;
  @include mixins.phone {
    padding: 0 2rem;
  }

  .title {
    padding: 1rem 0;
    font-weight: var(--fw-semi-bold);
    font-size: 3rem;
    text-align: center;
    @include  mixins.phone{
      font-size: 1.5rem;
      padding: 1rem 0 0 0;
    }
  }

  .description {
    padding: 1rem 0;
    font-size: 1rem;
    text-align: left;
    .seenMoreButton{
      color: #007AFF;
      text-decoration: underline;
    }
  }

  .videoLayout {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2%;

    .left {
      width: 49%;
      padding-bottom: 3.5rem;
      @include mixins.phone{
        display: none;
      }

      .defaultSubSegmentVideo {
        padding: 0.5rem 0;
        width: 100%;
        position: relative;

        img {
          cursor: pointer;
          width: 100%;
          height: 27rem;
          object-fit: cover;
          overflow: hidden;
          @include mixins.phone{
            height: 11rem;
          }
        }

        .iconPlay {
          cursor: pointer;
          font-size: 6rem;
          color: white;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      .additionalVideosLayout {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;

        .subSegmentVideo {
          width: 30%;

          img {
            cursor: pointer;
            width: 100%;
            height: 8rem;
            object-fit: cover;
            overflow: hidden;
            @include mixins.phone{
              height: 3rem;
            }
          }
        }
      }
    }

    .right {
      width: 49%;
      @include mixins.phone{
        width: 100%;
      }

      h1 {
        padding-top: 1rem;
        font-weight: var(--fw-semi-bold);
        font-size: 1.5rem;
        @include mixins.phone{
          font-size: 1rem;
        }
      }

      .details {
        font-size: 1rem;
        @include mixins.phone{
          font-size: .8rem;
        }
        .seenMoreButton{
          color: #007AFF;
          text-decoration: underline;
        }
      }

      hr {
        margin: 0.25rem 0 1rem 0;
        color: var(--clr-grey-500);
        background-color: var(--clr-grey-500);
        border: none;
        height: 0.1rem;

      }

      .mobileVideo {
        display: none;
        @include mixins.phone{
          display: block;
          width: 100%;
          padding: .5rem 0;
        }

        .defaultSubSegmentVideo {
          padding: 0.5rem 0;
          width: 100%;
          position: relative;

          img {
            cursor: pointer;
            width: 100%;
            height: 27rem;
            object-fit: cover;
            overflow: hidden;
            @include mixins.phone{
              height: 11rem;
            }
          }

          .iconPlay {
            cursor: pointer;
            font-size: 6rem;
            color: white;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }

        .additionalVideosLayout {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          gap: 1rem;


          .subSegmentVideo {
            width: 50%;

            img {
              cursor: pointer;
              width: 100%;
              height: 11rem;
              object-fit: cover;
              overflow: hidden;
              @include mixins.phone{
                height: 5rem;
              }
            }
          }
        }
      }

      .productTitle {
        @include mixins.maxmq(lg) {
          display: none;
        }

      }

      .imgMapMobile {
        display: none;
        @include mixins.phone{
          display: block;
          width: 100%;
          margin: .5rem 0 0 0;
        }

        img {
          display: block;
        }

        span {
          cursor: pointer;
        }
      }

      .carouselDesktop {
        @include mixins.phone{
          margin: 1rem 0;
        }

        .btns {
          margin-top: 1rem;
          display: flex;
          justify-content: center;
          gap: 0.5rem;
          @include mixins.phone{
            display: none;
          }

          .btn {
            padding: 0;
            border: 0;

            &[disabled] {
              display: none;
            }
          }

          .icon {
            font-size: 2.5rem;
          }

        }

        .slider {
          width: 100%;
          height: 25rem;
        }
      }


    }

  }

  .caption {
    font-weight: var(--fw-semi-bold);
  }

  .sheet {
    display: flex;
    align-items: center;
    padding-top: 1rem;

    h1{
      padding: 0 !important;
      text-decoration: underline;
      color: #007AFF;
      cursor: pointer;
    }
    p {
      color: #007AFF;
      text-decoration: underline;
      cursor: pointer;
    }

    .icon {
      font-size: 2rem;
      cursor: pointer;
      @include mixins.phone{
        display: none;
      }
    }
  }

  .imgMapWeb {
    width: 80%;
    margin: 2rem auto;
    @include mixins.phone{
      display: none;
    }

    img {
      display: block;
    }

    span {
      cursor: pointer;
    }
  }

  .productsList {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    @include mixins.phone{
      gap: 1rem;
    }


    object {
      margin: 1rem auto 0 auto;
      width: 20%;
    }

    h1 {
      font-size: 2rem;
      font-weight: var(--fw-semi-bold);
      text-align: center;
      @include mixins.phone{
        font-size: 1.5rem;
      }
    }

    .gridContainer {
      display: grid;
      justify-content: center;
      align-items: center;
      grid-gap: 1rem;
      grid-template-columns: repeat(auto-fill, 23rem);
      min-height: 25rem;
    }

    .pagination {
      grid-column: 2;
      grid-row: 2;
      margin: 1rem 0;
      @include mixins.phone{
        margin: 1rem 0 2rem 0;
      }
    }
  }

  .adRobotLayout {
    display: flex;
    flex-direction: column;
    justify-content: start;
    gap: 1rem;
    margin-bottom: 2rem;

    h1 {
      font-size: 2.5rem;
      font-weight: var(--fw-semi-bold);
      text-align: left;
      @include mixins.phone{
        font-size: 1.5rem;
        text-align: center;
      }
    }

    p {
      font-size: 1.25rem;
      text-align: left;
      @include mixins.phone{
        font-size: .8rem;
        text-align: center;
      }
    }

    .bDetails {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      background-color: #F4F4F4;
      @include mixins.phone{
        flex-direction: column;
      }


      .bRight {
        padding: 3rem 2rem;
        display: flex;
        flex-direction: column;
        justify-content: start;
        gap: 1rem;
        @include mixins.phone{
          padding: 1rem;
          align-items: center;
        }

        p {
          @include mixins.phone{
            font-size: .8rem;
          }
        }

        .imgContainer {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #F4F4F4;

          img {
            width: 34rem;
            object-fit: cover;
            overflow: hidden;
          }
        }

        .iconContainer {

          .btnLink {
            margin: 0 auto;

            .btn {
              background: black;
              border-color: black;
              border-radius: 1rem;
              height: 3rem;
              width: 6rem;

              .icon {

              }
            }
          }
        }
      }
    }
  }

  .contactLayout {
    width: 100%;
    padding: 1rem 0 2rem 0;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;

    h1 {
      margin-bottom: 1rem;
      font-weight: var(--fw-semi-bold);
      text-align: center;
    }

    .btnLink {
      margin: 0 auto;

      .btn {
        border-radius: 1.5rem;
        height: 3rem;
      }
    }
  }
}