@use '../../../../styles/abstracts/mixins';

.productInfoSection {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 1rem 4rem;
  @include mixins.phoneAndTablet {
    flex-direction: column;
    padding: 1rem 1.5rem 0;
  }


  .productDisplay {
    width: 40%;
    text-align: center;
    display: inline-flex;
    flex-direction: column;

    .overViewImg {
      margin-top: 1.5rem;
      width: 100%;
      object-fit: contain;
    }

    .colorCardTitle {
      padding: 1rem;
      margin-bottom: 0.5rem;
      font-size: 1.2rem;
      text-align: center;
      mix-blend-mode: difference;
    }

    .imageContainer {
      position: relative;
      @include mixins.phoneAndTablet {
        display: none;
      }

      .contain{
        .overViewImg {
          background-position: top;
          width: 100%;
          object-fit: contain;
        }

        .iconPlay {
          cursor: pointer;
          font-size: 6rem;
          color: white;
          background-color: rgba(175, 174, 174, 0.22);
          padding: 1rem;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }



    }

    .carouselDesktop {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: auto;
      padding: 2rem 0;
      @include mixins.phoneAndTablet {
        display: none;
      }

      .buttons {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        .btn {
          height: 4rem;
          padding: 0;
          border: 0;

          .icon {
            font-size: 4rem;
          }
        }

      }

      .slider {
        width: 100%;
        margin: 0 auto;

        .slide {
          padding: 0.5rem;

          .contain{
            width: 100%;
            height: 100%;

            .videoThumbnail{
              position: relative;
              cursor: pointer;
              width: 100%;
              height: 100%;

              .slideVideo{
                width: 100%;
                height: 100%;
              }
              .icon{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 2.5rem;
                color: black;
                opacity: 0.7;
                pointer-events: none;
              }
              .slideVideo {
                width: 100%;
                height: 100%;
              }
            }


            .slideImg {
              width: 100%;
              object-fit: contain;
              margin: auto;
              box-sizing: border-box
            }
          }

        }
      }
    }

    .carouselMobile {
      display: none;
      flex-direction: column;
      margin: auto;
      padding: 1.5625rem 0;
      width: 100%;
      @include mixins.phoneAndTablet {
        display: flex;
      }

      .slider {
        margin: 0 auto;
        width: 100%;

        .slide {
          padding: 0.5rem;

          .contain{
            width: 100%;
            height: 100%;

            .slideImg {
              width: 100%;
              object-fit: contain;
              margin: auto;
              box-sizing: border-box
            }
          }

        }
      }
      .pickerDots {
        display: inline-flex;
        justify-content: center;
        gap: 0.5rem;

        .icon {
          width: .7rem;
          height: .7rem;
          cursor: pointer;
          border-radius: 50%;
          border-width: 0;
        }

        button {
          background-color: var(--clr-grey-200);
        }

        button[disabled] {
          opacity: 1;
          background-color: black;
        }
      }

    }

    @include mixins.phoneAndTablet {
      width: 100%;
    }
  }

  .more{
    color: #007AFF;
    text-decoration: underline;
    cursor: pointer;
    padding-left: 1.6rem;
  }

  .productInfoContainer {
    width: 60%;
    @include mixins.phoneAndTablet {
      width: 100%;
    }
    padding: 2.4rem;
    @include  mixins.tablet{
      padding: 2rem;
    }
    @include  mixins.phone{
      padding: .5525rem 0;
    }

    .titleContainer {
      position: relative;
      line-height: 150%;

      h1 {
        font-size: 2.5rem;
        font-weight: var(--fw-semi-bold);
        margin-bottom: 0.5rem;
        @include  mixins.tablet{
          font-size: 2rem;
        }
        @include  mixins.phone{
          font-size: 1.5rem;
        }
      }

      h2 {
        margin: 0.5rem 0;
        font-size: 1rem;
        @include  mixins.tablet{
          font-size: .9rem;
        }
        @include  mixins.phone{
          font-size: .8rem;
        }
      }

      p {
        margin: 1.2rem 0;
        font-size: 0.875rem;
        @include  mixins.phone{
          font-size: .8rem;
        }
      }

    }

    Button {
      margin-top: 1.2rem;
    }

    .qty {
      opacity: 1;
      pointer-events: auto;
      display: flex;
      flex-direction: column;

      h1 {
        font-size: 1.5rem;
        margin-top: auto;
        margin-bottom: auto;
        @include  mixins.tablet{
          font-size: 1.3rem;
        }
        @include  mixins.phone{
          font-size: 1rem;
        }
      }

      .numbers {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .center {
          flex-direction: row;
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 1rem;
          width: 100%;
          @include mixins.phone{
            flex-direction: column;
            align-items: self-start;
            gap: 2rem;
          }


          .row{
            flex-direction: column;
            display: flex;
            justify-content: center;
            align-items: center;
            @include mixins.phone{
              width: 100%;
            }

            .qtyButtons {
              display: inline-flex;
              justify-content: flex-start;
              margin: auto 0;
              @include mixins.phone{
                width: 100%;
              }

              .buttons {
                margin: 0;
                width: 5rem;
                height: 5rem;
                border-radius: 0;
                @include mixins.phone{
                  width: 30%;
                  font-size: 3rem;
                }
              }

              .textInput {
                margin: 0;
                font-size: 1.5rem;
                width: 5rem;
                height: 5rem;
                @include mixins.phone{
                  width: 40%;
                  font-size: 3rem;
                }
              }

              .input {
                width: 100%;
                height: 100%;
                margin: 0;
                border-top: 0.125rem solid #3e4042;
                border-bottom: 0.125rem solid #3e4042;
                border-right: 0.125rem solid #3e4042;
                border-left: 0.125rem solid #3e4042;
                border-radius: 0;
                text-align: center;
                padding: 0;
              }
            }

            h1 {
              width: 15rem;
              font-size: 1.25rem;
              font-weight: var(--fw-semi-bold);
              text-align: center;
              line-height: 200%;
              border-right: 0.125rem solid #3e4042;
              border-left: 0.125rem solid #3e4042;
              border-bottom: 0.125rem solid #3e4042;
            }

            h1 {
              text-align: center;
              @include mixins.phone{
                width: 100%;
              }
            }
          }
        }

        .right {
          @include mixins.phone{
            width: 100%;
          }
          .container {
            width: 26rem;
            border: 1px solid #333333;
            border-radius: 7px;
            padding: 1.5rem;
            cursor: pointer;
            @include mixins.phone{
              width: 100%;
              padding: .5rem;
            }

            .first {
              display: flex;
              flex-direction: row;
              gap: 1rem;
              margin-bottom: 1rem;
              @include mixins.phone{
                gap: .5rem;
                margin-bottom: .5rem;
              }

              .iconContainer {
                display: flex;
                flex-direction: column;
                justify-content: center;

                .calculator {
                  font-size: 2.5rem;
                }

              }

              h1 {
                font-size: 1.5rem;
                font-weight: var(--fw-semi-bold);
                @include mixins.phone{
                  font-size: 1.25rem;
                }
              }
            }

            p {
              font-size: 1.25rem;
              @include mixins.phone{
                font-size: 1rem;
              }
            }
          }
        }
      }

      .addCartButton {
        width: 20rem;
        @include mixins.phone{
          width: 100%;
        }
      }
    }

    .selectVariant{
      @include mixins.phoneAndTablet{
        width: 100%;
      }
    }

    .featuresContainer {
      @include mixins.phoneAndTablet{
        padding-bottom: 1rem;
      }

      h3 {
        margin-top: 0.5rem;
        font-size: 1.5rem;
        padding-bottom: 1.2rem;
        font-weight: var(--fw-semi-bold);
        line-height: 1.8rem;
        @include  mixins.tablet{
          font-size: 1.4rem;
        }
        @include  mixins.phone{
          font-size: 1.2rem;
        }
      }

      ul {
        margin-bottom: 0.5rem;
        padding-left: 1.6rem;
        list-style-type: disc;
      }

      li {
        font-size: 0.875rem;
        line-height: 150%;
        list-style-position: outside;
        @include  mixins.phone{
          font-size: .75rem !important;
        }
      }

    }

    .docContainer {
      display: flex;
      flex-direction: row;
      @include  mixins.phone{
        flex-direction: column;
        align-items: flex-start;
        padding-left: 1rem;
      }

      .Container {
        display: flex;
        flex-direction: column;


        .doc {
          display: flex;
          flex-direction: row;
          height: 100%;
          margin-right: 2rem;

          .ds_img {
            padding: 0.2rem;
            width: 4rem;
            height: 4rem;
            @include mixins.tablet {
              width: 3.5rem;
              height: 3.5rem;
            }
            @include mixins.phone {
              width: 3rem;
              height: 3rem;
            }
          }

          .container {
            height: 4rem;
            display: flex;
            flex-direction: column;
            justify-content: center;

            p {
              font-weight: var(--fw-semi-bold);
              text-decoration: underline;
              @include mixins.phone {
                font-size: .7rem;
              }
            }
          }
        }
      }
    }
    .garntieContainer {
      display: flex;
      flex-direction: column;
      @include  mixins.phone{
        padding-left: 1rem;
      }


      .doc {
        display: flex;
        flex-direction: row;
        height: 100%;
        margin-right: 2rem;

        .pdf_img {
          padding: 0.2rem;
          width: 4rem;
          height: 4rem;
          @include mixins.tablet {
            width: 3.5rem;
            height: 3.5rem;
          }
          @include mixins.phone {
            width: 3rem;
            height: 3rem;
          }
        }

        .container {
          height: 4rem;
          display: flex;
          flex-direction: column;
          justify-content: center;

          p {
            font-weight: var(--fw-semi-bold);
            text-decoration: underline;
            @include mixins.phone {
              font-size: .7rem;
            }
          }
        }
      }
    }

    .layout {
      display: flex;
      flex-direction: column;

      h3 {
        margin: 1rem 0 0.875rem 0;
        font-size: 1.5rem;
        font-weight: var(--fw-semi-bold);
        line-height: 1.8rem;
        @include mixins.phone{
          font-size: 1.25rem;
        }
      }

      .element {
        display: none;
        flex-direction: row;
        justify-content: space-between;
        background: #F4F4F4;
        padding: 0.5rem;
        margin-bottom: 1rem;
        height: 5rem;
        @include mixins.phone{
          display: flex;
        }

        .text {
          display: flex;
          align-items: center;

          p {
            text-transform: uppercase;
            font-size: 1rem;
          }
        }
      }

      .container {
        display: grid;
        grid-gap: 0.5rem;
        @include mixins.phone{
          display: none;
        }

        .fbuttons {
          padding: 0.5rem;
          margin-top: 0;
          width: 10rem;
        }

        .sbuttons {
          padding: 0.5rem;
          margin-top: 0;
          width: 15rem;
        }

        .content {
          font-size: 1.5rem;
          line-height: 1.8rem;
        }

        .colorCardContainer {
          width: 160px;
          cursor: pointer;
          position: relative;
          background: #f5f5f5;
          display: flex;
          flex-direction: column;
          transition: all 0.2s ease-out;

          &:hover {
            color: #1976d2;
            background: #fcfcfc;
            box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
          }

          .colorCardImageDiv {
            width: 100%;
            height: 80px;
            position: relative;
            border: 1px solid #333333;
          }

          .colorCardDetails {
            width: 100%;
            height: 45px;
            align-self: end;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 2rem 1rem 2rem 1rem;

            p {
              font-size: 1rem;
              text-align: center;
            }
          }
        }

        .selected {
          border: 3px solid #007AFF;
        }
      }

      .selectedContainer{
        height: 8rem !important;
      }

      .containerMobile{
        position: relative;
        background-color: #F4F4F4;
        width: 100%;
        height: 28rem;
        display: none;
        box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
        @include mixins.phone{
          display: flex;
        }
        .scrollableContainer{
          flex-direction: column;
          align-items: center;
          gap: .5rem;
          padding: 1.5rem 0;
          display: none;
          overflow: auto;
          height: 100%;
          width: 100%;
          @include mixins.phone{
            display: flex;
          }

          .sbuttons{
            padding: 0.5rem;
            margin-top: 0;
            font-size: 1.5rem;
            line-height: 1.8rem;
            height: 5rem !important;
            width: 80% !important;
          }

          .colorCardContainer {
            width: 80%;
            cursor: pointer;
            position: relative;
            background: #ffffff;
            display: flex;
            flex-direction: row;
            transition: all 0.2s ease-out;

            &:hover {
              color: #1976d2;
              background: #fcfcfc;
            }

            .colorCardImageDiv {
              width: 100%;
              height: 80px;
              position: relative;
              border: 1px solid #333333;
            }

            .colorCardDetails {
              width: 100%;
              height: 45px;
              align-self: end;
              display: flex;
              flex-direction: column;
              justify-content: center;
              padding: 2rem 1rem 2rem 1rem;

              p {
                font-size: 1rem;
                text-align: center;
              }
            }

          }
          .selected {
            border: 3px solid #007AFF !important;
          }
        }
        .rectangle {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 2rem;
          background-color: rgb(219 219 219 / 38%);
          z-index: 1;
        }

      }

      .inputJoint {
        margin-top: 0;
        display: none;
        @include mixins.phone{
          display: block;
        }
        select {
          background-color: #f5f5f5;
          font-size: 1rem;
        }
      }

      .after {
        &:after {
          top: 0.8rem;
        }
      }

      .information {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        @include mixins.phone{
          margin-left: 2rem;
        }

        .prices {
          p {
            font-size: 1.25rem;
            @include mixins.phone{
              font-size: 1rem;
            }

            span {
              font-weight: var(--fw-semi-bold);
            }

            .price {
              color: #007AFF;
            }
          }

          .iconContainer {
            display: flex;
            justify-content: center;
            align-items: center;

            .icon {
              font-size: 1.5rem;
            }
          }
          .mobileMessages{
            display: none;
            @include mixins.phoneAndTablet{
              display: flex;
            }
            .helper {
              font-size: 1.5rem;
              @include mixins.phone{
                font-size: 1rem;
              }
              .link {
                font-weight: var(--fw-semi-bold);
                font-size: 1.25rem;
                @include mixins.phone{
                  font-size: 1rem;
                }
                cursor: pointer;
                text-decoration: underline;
                text-align: center;
                color: #007AFF;
              }
              .loginlink {
                font-weight: var(--fw-medium);
              }
            }
          }
          .warnings {
            margin: 1rem 0;

            .warning {
              display: flex;
              flex-direction: row;
              gap: 0.2rem;
              color: #E03229;
              font-size: 1.25rem;


              &.shakeError {
                padding: 0.5rem;
                animation: shake 0.2s ease-in-out 0s 2;
                box-shadow: 0 0 0.5em red;
              }
            }
          }

          .info {
            margin: 1rem 0;
            display: flex;
            flex-direction: row;
            gap: 0.2rem;
            color: #28a745;
            font-size: 1.25rem;
            @include mixins.phone{
              font-size: 1rem;
            }
          }
        }

        .messages {
          display: flex;
          flex-direction: column;
          justify-content: start;

          p{
            @include mixins.phone{
              font-size: 1rem;
            }
          }

          @include mixins.phoneAndTablet{
            display: none;
          }

          .helper {
            font-size: 1.5rem;
            display: flex;
            justify-content: center;
            align-items: center;

            .link {
              font-weight: var(--fw-semi-bold);
              font-size: 1.25rem;
              cursor: pointer;
              text-decoration: underline;
              text-align: center;
              color: #007AFF;
            }
            .loginlink {
              font-weight: var(--fw-medium);
            }
          }
        }
      }

    }
  }
}


.specBody {
  padding-top: 2.4rem;
  padding-left: 1.6rem;
  list-style-type: disc;

  .specElement {
    padding: 0 1.2rem;
    font-size: 1.4rem;
    line-height: 200%;
  }
}

.compatibilityBody {
  display: flex;
  flex-direction: column;

  h3 {
    padding: 2.4rem 0;
    font-size: 1.6rem;

    span {
      font-weight: bold;
    }
  }
}
