@use "../../../../../styles/abstracts/variables" as *;

.reports {
    display: flex;
    flex-direction: column;
    padding-bottom: 0.625rem;
    border-bottom: 1px solid $color_neutral_grey_light;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
    }

    .main {
        display: flex;
        flex-direction: column;
        padding-left: 1.25rem;
        overflow: hidden;
        height: 0;

        &.expanded {
            height: auto;
        }

        .document {
            display: flex;
            flex-direction: row;
            column-gap: 0.125rem;
            align-items: center;
        }
    }

    a {
        display: block;
        color: $color_primary;
        text-decoration: underline;

        &:hover {
            text-decoration: underline;
        }
    }
}