@use '../../../styles/abstracts/mixins';

.Layout {
  display: inline-flex;

  .Node {
    display: inline-flex;
    height: 2rem;

    a {
      line-height: 2rem;
      text-align: center;
    }

    .Bold {
      font-weight: var(--fw-semi-bold);
    }

    svg {
      height: 100%;
      margin: auto 1rem;
    }

  }
}