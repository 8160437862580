@use '../../../../styles/abstracts/mixins';

.architectPickerContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .architectPickerTitle {
    width: clamp(15ch, 80%, 35ch);
    font-size: 2.5rem;
    font-weight: var(--fw-semi-bold);
    line-height: 125%;
    text-align: center;
    align-self: center;
    padding: 2rem 0 1rem 0;
    @include mixins.phone{
      font-size: 1.25rem;
      padding: 1rem 0 .5rem 0;
    }
  }

  .architectPickerDescription {
    width: 100%;
    font-size: 2rem;
    line-height: 125%;
    text-align: center;
    align-self: center;
    @include mixins.phone{
      font-size: 1rem;
      padding-bottom: 1.56rem;
    }
  }

  ul {
    width: 100%;
    padding: 1rem 0;
    display: inline-flex;
    justify-content: center;
    @include mixins.phone{
      display: none;
    }

    li {
      padding: 1rem 4rem;
      font-size: 1.8vw;

      a:hover {
        font-weight: var(--fw-semi-bold);
        text-decoration: underline;
      }
    }

    li + li {
      border-left: 0.1rem solid var(--clr-grey-500);
    }

  }

  .discoverLayout {
    margin: 1rem 0;
    padding: 0 15rem;
    display: inline-flex;
    flex-direction: column;
    justify-content: start;
    width: 100%;
    gap: 1rem;
    @include mixins.phone{
      padding: 0;
      margin: 0 0 1.56rem 0;
    }

    .card {
      height: 25rem;
      display: inline-flex;
      flex-direction: row;
      justify-content: space-between;
      @include mixins.phone{
        height: 14.5rem;
        flex-direction: column;
      }

      .left {
        width: 49.5%;
        background-color: #F4F4F4;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        @include mixins.phone{
          width: 100%;
          height: 100%;
        }
      }

      .right {
        width: 49.5%;
        border-radius: 0.2rem;
        overflow: hidden;
        position: relative;
        color: var(--text);
        display: inline-flex;
        flex-direction: column;
        background-color: #F4F4F4;
        @include mixins.phone{
          width: 100%;
          justify-content: center;
          padding: 1rem 0;

        }

        .details {
          width: 100%;
          margin: auto;
          box-sizing: border-box;
          @include mixins.phone{
            display: flex;
            justify-content: center;
          }

          h1 {
            padding: 0 2rem;
            font-weight: var(--fw-bold);
            font-size: 2.4rem;

            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            @include mixins.phone{
              font-weight: var(--fw-semi-bold);
              font-size: 1.3rem;
            }

          }

          h2 {
            padding: 0 2rem;
            font-weight: var(--fw-semi-bold);
            font-size: 2rem;
          }

          p {
            padding: 0 2rem;
            margin-top: 0.5rem;
            font-weight: var(--fw-regular);
            font-size: 1rem;

            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 5;

            @include mixins.phone{
              display: none;
            }
          }
        }

        .icon {
          height: 50%;
          margin: 1rem;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center center;
        }

      }

      transition: ease-in-out 0.1s;

      &:hover {
        transform: translateY(-0.188rem) scale(1.03);
        box-shadow: 0 0.313rem 0.938rem rgba(0, 0, 0, 0.35);
        border: 0.3rem solid;
        border-image-slice: 1;
        border-image-source: linear-gradient(#00b5ee, #0937ad);
      }
    }
  }
}
