@use '../../../styles/abstracts/mixins';

.newsletterContainer {
  --bg: var(--clr-grey-100);
  --text: var(--clr-grey-900);
  --text-dim: var(--clr-grey-500);
  color: var(--text);
  background: var(--bg);
  padding: 2rem 10rem;
  display: inline-flex;
  justify-content: center;
  @include mixins.maxmq(lg) {
    flex-direction: column;
    padding: 3rem 1rem 3rem 1rem;
    margin: 0;
  }

  .newsletterHalf {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 50%;
    @include mixins.maxmq(lg) {
      margin: 0 auto;
    }

    .newsletterHeader {
      font-size: 1.2rem;
      font-weight: var(--fw-semi-bold);
      line-height: 2.8rem;
      @include mixins.maxmq(lg) {
        text-align: center;
      }
    }

    .newsletterParagraph {
      font-size: 1rem;
      margin: 1.6rem 0;
      line-height: 2.4rem;
      @include mixins.maxmq(lg) {
        text-align: center;
      }
    }

    .button {
      margin: 0 auto;
    }
  }

  .newsletterVertical {
    border-left: solid black;
    border-width: 0.063rem;
    margin: 0 5rem;
    justify-self: center;
    @include mixins.maxmq(lg) {
      display: none;
    }
  }

  hr {
    display: none;
    margin: 3rem 0 3rem 0;
    height: 100%;
    @include mixins.maxmq(lg) {
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: 50%;
    }
  }
}
