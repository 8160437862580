@use '../../../../styles/abstracts/mixins';

.variantGridBody {
  width: 80%;
  height: 100%;
  min-height: 60rem;
  padding-left: 20px;
  @include mixins.phone{
    display: none;
  }

  hr {
    color: var(--clr-grey-1000500);
    background-color: var(--clr-grey-1000);
    height: 0.025rem;
    margin: 1rem 0;
  }

  .title {
    margin-top: 2rem;
    font-size: 2.4rem;
    font-weight: var(--fw-semi-bold);
  }
}
