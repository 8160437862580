
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 2999999999;
  background-color: rgba(71, 66, 66, 0.3);
}

.modal {
  position: fixed;
  z-index: 3000000000;
  top: 0;
  right: 0;
  width: 719px;
  height: 100%;
  background: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  overflow-x: hidden;
  overflow-y: scroll;
  @media (max-width: 768px) {
    width: 100%;
  }

  .closeButton {
    position: absolute;
    top: 40px;
    right: 40px;
    font-size: 20px;
    cursor: pointer;
    background: none;
    border: none;
    color: black;
    font-weight: var(--fw-semi-bold);
  }

  .manage {
    margin: 35px 40px 30px 40px;

    h1 {
      font-weight: var(--fw-bold);
      margin-bottom: 30px;
      font-size: 1.25rem;
    }
    p{
      font-size: 1rem;
    }
  }

  .privacy {
    margin: 15px 40px 30px 40px;
    display: flex;
    align-items: center;

    p {
      font-size: 0.6rem;
      color: black;
      text-decoration: underline;
      white-space: nowrap;
    }
  }

  .documentsSection{
    margin-left: 30px;
  }

  .button{
    margin: 50px;
    display: flex;
    justify-content: space-between;


    .first,
    .second {

      width: auto;
      padding: 10px;
      margin-right: 5rem;
      font-weight: var(--fw-semi-bold);
      cursor: pointer;
      white-space: nowrap;
    }

    .first {
      background-color: black;
      color: white;
      border: none;
    }

    .second {
      background-color: lightgray;
      color: black;
      border: none;
    }
  }

}
