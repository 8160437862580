@use "../../../styles/abstracts";
@use '../../../styles/abstracts/mixins';
@use "../../../styles/abstracts/variables" as *;

.card {
    border: 1px solid $color_neutral_grey;
    padding: 1.25rem;
    height: 200px;
    width: 430px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .title {
        font-size: 1.56rem;
        font-weight: var(--fw-semi-bold);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @include abstracts.phone {
            max-width: 300px;
        }
    }

    .footer {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .options {
            display: flex;
            column-gap: 0.625rem;
            justify-content: flex-end;
            flex-grow: 1;
            
            .textlink {
                cursor: pointer;
                color: $color_primary;
            }
    
            .textlinkerror {
                cursor: pointer;
                color: $color_semantic_alert;
            }
        }

    }

}