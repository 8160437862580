.modal {
  position: fixed;
  z-index: 3000000000;
  bottom: 0;
  left: 0;
  width: 100%;
  background: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

  .top {
    margin: 1.25rem 3.1rem 0.6rem 3.1rem;

    h1 {
      font-weight: var(--fw-semi-bold);
      font-size: 1.5rem;
    }
    p {
      font-size: 1rem;
    }
  }

  .bottom {
    margin: 0px 3.1rem 0.6rem 3.1rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    p {
      margin-right: 25rem;
      font-size: 0.8rem;
      color: black;
      text-decoration: underline;
      white-space: nowrap;
    }

    .first,
    .second {
      width: auto;
      padding: 0.6rem;
      margin-right: 5rem;
      margin-top: 1rem;
      margin-bottom: 1rem;
      font-weight: var(--fw-semi-bold);
      cursor: pointer;
      white-space: nowrap;
    }

    .first {
      background-color: black;
      color: white;
      border: none;
    }

    .second {
      background-color: lightgray;
      color: black;
      border: none;
    }
  }

}
