@use '../../styles/abstracts/mixins';

.loading {
  width: 100%;
  min-height: 40rem;

}

.layout {
  width: 100%;
  @include mixins.phone {
    padding: 0 2rem;
  }

  .body {
    padding: 2rem 10rem;
    @include mixins.phone {
      padding: 0;
    }

    .description {
      padding: 0 1rem;

      .texte {
        h1 {
          text-align: center;
          text-transform: uppercase;
          font-weight: var(--fw-semi-bold);
          font-size: 1.8rem;
          line-height: 2rem;
          padding: 0 15%;
          @include mixins.phone{
            margin-top: 1rem;
            font-size: 1.2rem;
            line-height: 1.5rem;
            padding: 0;
          }
        }

        .longTexte {
          padding: 2rem 0;
          @include mixins.phone{
            padding: 1rem 0;
          }

          p {
            text-align: center;
            font-size: 1.1rem;
            @include mixins.phone{
              font-size: .8rem;
              text-align: start;
            }
          }

          h2 {
            padding: 1rem 0;
            text-align: center;
            font-size: 1.6rem;
            font-weight: var(--fw-semi-bold);
          }

          a {
            color: #007AFF;
            text-decoration: underline;
          }

        }
      }

      .imgMap {
        padding: 0 25%;
        margin: 2rem auto;
        @include mixins.phone{
          padding: 0;
        }

        img {
          display: block;
        }

        span {
          cursor: pointer;
        }

        .details {
          margin-top: 0.1rem;
          margin-left: 0.5rem;
          position: relative;
          background-color: #F4F4F4;
          width: 32rem;
          z-index: 99;
          display: flex;
          flex-direction: row;
          @include mixins.phone {
            width: 12rem;
            left: -250%;
          }

          .vr {
            background-color: #007AFF;
            border-left: 0.2px solid #000;
            width: 1rem;
            @include mixins.phone{
              display: none;
            }
          }

          .texte {
            padding: 1rem;
            width: 100%;
            @include mixins.phone{
              padding: .5rem;
            }

            h1 {
              text-align: left;
              padding: 0;
              font-size: 1.4rem;
              margin-bottom: 1rem;
              color: #007AFF;
              @include mixins.phone {
                font-size: .8rem;
                line-height: 1rem;
                margin: .5rem 0 .5rem 0;
              }
            }

            ul {
              li {
                font-size: 1.1rem;
                list-style: inside;
                @include mixins.maxmq(xl) {
                  font-size: .8rem;
                }

              }
            }
          }

        }

      }

      .video {
        padding: 1rem 0;

        h1 {
          text-align: center;
          text-transform: uppercase;
          font-weight: var(--fw-semi-bold);
          font-size: 1.8rem;
          line-height: 2rem;
          @include mixins.phone{
            font-size: 1.2rem;
            line-height: 1.5rem;
          }
        }

        .container {
          margin-top: 1rem;
          display: flex;
          align-items: center;
          flex-direction: column;

          p {
            font-size: 1.4rem;
          }

          .imgContainer {
            margin-top: 1rem;
            position: inherit;
            width: 50%;
            height: 27rem;
            @include mixins.phone{
              height: 11rem;
              width: 100%;
            }

            img {
              position: relative;
              cursor: pointer;
              width: 100%;
              object-fit: cover;
              height: 23rem;
              @include mixins.phone{
                height: 11rem;
              }
            }

            .iconPlay {
              cursor: pointer;
              font-size: 6rem;
              color: white;
              position: relative;
              top: -15rem;
              left: 25rem;
              transform: translate(-50%, -50%);
              @include mixins.phone{
                font-size: 4rem;
                top: -5rem;
                left: 10rem;
              }
            }

          }
        }

      }
    }
  }

  .contact_layout {
    position: relative;
    padding-top: 2rem;
    @include mixins.phone{
      padding-top: 0;
    }

    .contact_body {
      background-color: #F4F4F4;
      padding: 2rem 0;
      margin-bottom: 2rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      @include mixins.phone{
        padding: 1rem 0 .5rem 0 ;
        margin-bottom: 1rem;
      }

      h2 {
        padding: 1rem 0;
        text-align: center;
        font-size: 1.6rem;
        font-weight: var(--fw-semi-bold);
      }

      p {
        text-align: center;
        padding: 0 15vw;
        font-size: 1.6rem;
        @include mixins.phone{
          font-size: 1rem;
        }
      }

      .btnLink {
        margin: 2rem 35vw;
        @include mixins.phone{
          margin: 1rem;
        }
        .btn {
          width: 100%;
          @include mixins.phone{
            font-size: 1rem;
          }
        }

      }
    }
  }
}