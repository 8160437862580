@keyframes spin {
    from {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(360deg)
    }
}

.spinner {
    display: "inline-block";
    animation: spin 1s linear infinite;
}