@use '../../../../../styles/abstracts/mixins';

.searchBarContainer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 2rem;
  @include mixins.maxmq(lg) {
    .sortByContainer {
      display: none;
    }
    .favoriteContainer {
      display: none;
    }
  }

  .filterBtn {
    font-weight: bold;
    @include mixins.minmq(lg) {
      display: none;
    }
  }
}
