@use '../../../styles/abstracts/mixins';

.navDrop {
  overflow: hidden;
  position: absolute;
  background: #fff;
  visibility: hidden;
  transition-property: all;
  transition-delay: 0.2s;
  border-radius: 0.2rem;
  z-index: 2100;

  &.active {
    padding-top: 1rem;
    visibility: visible;
    transition: none 0s ease 0s;
  }

  .inner {
    visibility: hidden;
    overflow: hidden;
    position: relative;
    height: 0;
    margin: 0 auto;
    transition-property: height;
    transition-delay: 0.1s;

    &.active {
      visibility: visible;
      z-index: 2100;
      transition: none 0s ease 0s;
      height: 16rem;
    }

    .dropdowncards{
      width: 100%;
      display: grid !important;
      grid-gap: 1%;
      padding: 1rem .5rem;
    }

    ul {
      position: relative;
      opacity: 0;
      transform: translateY(-30px);
      transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1) 0.1s, opacity 0.4s cubic-bezier(0.4, 0, 0.2, 1) 0.1s;
      pointer-events: none;
      display: flex;
      flex-direction: column;
      height: 0;


      &.active {
        opacity: 1;
        transform: translateY(0);
        pointer-events: auto;
        height: auto;
      }

      li {
        clear: both;
        padding: 0.25rem;
        width: 100%;

        div {
          cursor: pointer;
        }

        .link {
          display: inline-block;
          overflow: hidden;
          position: relative;
          padding: 0.5rem;
          border-radius: 1.5rem;
          color: #000;
          font-size: 14px;
          font-weight: var(--fw-regular);
          line-height: 1.66;
          text-align: left;
          transition: ease 0.3s;

          &.active {
            font-weight: var(--fw-semi-bold);
            text-decoration: underline;
            text-underline-offset: 0.5rem;
          }
        }

        .segmentPickerCard {
          cursor: pointer;
          border-radius: 0.2rem;
          height: 10rem;
          overflow: hidden;
          position: relative;
          color: var(--text);
          display: inline-flex;
          flex-direction: column;

          p {
            margin: 0;
            text-align: center;
            padding: 1rem .5rem;
            font-weight: 600;
            font-size: 1rem;
            background-color: #F4F4F4;
            width: 15rem;
            min-height: 4rem;
          }

          img {
            width: 15rem;
            object-fit: cover;
            overflow: hidden;
            margin: auto 0 0 0;
            display: block;
            box-sizing: border-box;
          }

          transition: ease-in-out 0.1s;

          &:hover {
            transform: translateY(-0.188rem) scale(1);
            box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.35);
            border: 0.2rem solid;
            border-image-slice: 1;
            border-image-source: linear-gradient(#00b5ee, #0937ad);
          }
        }

        .element {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          background: #F4F4F4;
          padding: 0.5rem;
          height: 5rem;

          &:hover {
            transform: translateY(-0.188rem) scale(1);
            box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.35);
            border: 0.2rem solid;
            border-image-slice: 1;
            border-image-source: linear-gradient(#00b5ee, #0937ad);
          }


          .text {
            display: flex;
            align-items: center;

            p {
              text-transform: uppercase;
              font-size: .8rem;
            }
          }
          img {
            box-sizing: border-box;
            object-fit: contain;
            overflow: hidden;
            width: 4rem;
            height: 4rem;
          }
        }
      }

    }
  }

}