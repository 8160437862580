@use '../../../styles/abstracts/mixins';

.container {
    position: relative;

    .picture {
        width: 100%;
        object-fit: cover;
        transition: opacity 0.3s ease-in-out;
    }

    .wmh {
        position: absolute;
        top: 1.25rem;
        left: 1.25rem;
        width: 80px;
        height: 80px;

        @include mixins.phone{
            width: 50px;
            height: 50px;
        }

        @include mixins.tablet{
            width: 100px;
            height: 100px;
        }
    }
}