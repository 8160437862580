@use '../../../../styles/abstracts/mixins';

.sectionContainer {
  grid-area: main;
  padding: 0 1.6rem;
  width: 100%;
  @include mixins.phone{
    padding: 0;
  }

  .gridContainer {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fill, 23%);
    min-height: 25rem;

    @include mixins.maxmq(hug){
      grid-template-columns: repeat(auto-fill, 32%);
    }
    @include mixins.maxmq(xxl){
      grid-template-columns: repeat(auto-fill, 48%);
    }

    @include mixins.tablet{
      grid-template-columns: repeat(auto-fill, 48%);
    }
    @include mixins.phone{
      grid-template-columns: repeat(auto-fill, 100%);
    }
  }

  .pagination {
    grid-column: 2;
    grid-row: 2;
  }
}

@include mixins.maxmq(lg) {
  .sectionContainer {
    width: 100%;
  }
}
