@use '../../../styles/abstracts/mixins';

.layout {
  padding: 1rem 4rem;
  width: 100%;
  @include mixins.phone{
    display: none;
  }

  .header {
    width: 100%;
    display: grid;
    grid-gap: 1%;
    grid-template-columns: repeat(4, 24.25%);
    justify-content: center;
    border-bottom: 2px solid black;
    border-top: 2px solid black;

    h1 {
      padding: 1rem 0;
      font-size: 1.5vw;
      font-weight: var(--fw-semi-bold);
      cursor: pointer;

      &:hover {
        background-color: #dfe9f1;
      }
    }
  }

  .body {
    width: 100%;
    padding: 2rem 0;
    display: grid;
    grid-column-gap: 10%;
    grid-row-gap: 2rem;
    grid-template-columns: repeat(2, 45%);
    justify-content: center;

    .segment {

      h1, h2 {
        font-size: 1.4rem;
        font-weight: var(--fw-semi-bold);
        text-transform: uppercase;
      }

      h1 {
        padding: 1rem;
        background-color: #F4F4F4;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        cursor: pointer;

        &:hover {
          background-color: #dfe9f1;
        }
      }

      p {
        font-size: 1.1rem;
      }

      .detail{
        margin-top: 1rem;
      }
      .content {
        font-size: 1.2rem;
        padding: 1rem;

        b {
          font-weight: var(--fw-semi-bold);
        }

        .specElement {
          padding: 0.5rem 1rem;

          li {
            font-size: 1.2rem;
            list-style-position: outside;
          }
        }

        .imgWrapper {
          width: 100%;
          display: flex;
          flex-direction: row;
          img{
            width: 20rem;
            margin: .5rem;
          }
        }

        .usageElement {
          padding: 0.5rem 1rem;

          h2 {
            padding-bottom: 1rem;
          }

          li {
            font-size: 1.2rem;
            list-style: none;

            ul {
              padding-bottom: 1rem;

              li {
                margin-left: 2rem;
                font-size: 1.2rem;
                list-style: outside;
              }
            }
          }
        }

        .detailElement {
          padding: 0.5rem 1rem;

          h2 {
            padding-bottom: 1rem;
            display: flex;
            justify-content: center;


          }

          .array {
            .row {
              width: 100%;
              display: flex;
              flex-direction: row;
              border-bottom: 1px solid #ddd;
            }
            .col {
              width: 33.33%;
              padding: 1rem;
              border-right: 1px solid #ddd;
              box-sizing: border-box;
            }
            .row:last-child {
              border-bottom: none;
            }
            .col:last-child {
              border-right: none;
            }
            .row:nth-child(odd) {
              background-color: #F4F4F4;
            }
          }

        }

        .applicationElement {

          .container {
            h2 {
              padding: 1rem 0;
            }

            a {
              color: #007bff;
              text-decoration: underline;
              transition: all 0.2s ease-in-out;

              &:hover {
                color: #0056b3;
              }
            }
          }
        }

      }

      //remove borders from first .accordionHeader
      .accordionHeader:first-child {
        border-top: none;
      }

      .accordionHeader {
        background-clip: padding-box;
        border-bottom: 0.1rem solid;
        border-top: 0.1rem solid;
        margin-bottom: 0.5rem;

        h1 {
          width: 100%;
          padding: 1rem;
          background-color: unset;
          box-shadow: unset;
          cursor: pointer;
          font-size: 1.3rem;
          font-weight: var(--fw-semi-bold);
        }
      }

      .documentsSection {
        position: relative;

        .documentsContent {
          width: 100%;
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: start;
          height: 100%;
          gap: 1rem;
          @include mixins.maxmq(lg) {
            grid-template-columns: repeat(1, 1fr);
          }

          .documentsButton {
            width: 100%;
            cursor: pointer;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            padding: 0.5rem;
            border: 0.1rem solid;
            border-radius: 0.3rem;
            transition: ease-in-out 0.3s;

            &:hover {
              background-color: var(--clr-grey-100);
            }

            .documentsIconBlack {
              font-size: 2.5rem;
            }

            p {
              width: 100%;
              font-size: 1.125rem;
              font-weight: var(--fw-semi-bold);
              margin-left: 1.2rem;
            }

            .iconDownload {
              color: #1976D2;
              font-size: 3rem;
              margin-left: 1rem;
            }
          }
        }
      }
    }
  }
}
.layoutMobile{
  display: none;
  @include mixins.phone{
    display: block;
  }
  margin-bottom: 1rem;
  .Container{
    margin: 0;
    .accordionContainer{
      padding: 1rem;
      margin: 0 0 .5rem;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
      background-color: #f4f4f4;
      h1{
        font-size: 1.375rem;
        font-weight: var(--fw-semi-bold);
      }
      .iconClass{
        top: 1rem;
        right: .5rem;
      }

    }
    .content {
      font-size: 1.2rem;
      padding: 1rem 0;

      b {
        font-weight: var(--fw-semi-bold);
      }

      .specElement {
        padding: 0.5rem 1rem;

        li {
          font-size: 1.2rem;
          list-style-position: outside;
        }
      }

      .imgWrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
      }

      .usageElement {
        padding: 0.5rem 1rem;

        h2 {
          padding-bottom: 1rem;
        }

        li {
          font-size: 1.2rem;
          list-style: none;

          ul {
            padding-bottom: 1rem;

            li {
              margin-left: 2rem;
              font-size: 1.2rem;
              list-style: outside;
            }
          }
        }
      }

      .detailElement {

        h2 {
          padding-bottom: 1rem;
          display: flex;
          text-align: center;
          justify-content: center;
          font-weight: var(--fw-semi-bold);
        }

        .array {
          .row {
            width: 100%;
            display: flex;
            flex-direction: row;
            padding-bottom: 1rem;

            .col {
              width: 50%;
              padding: 1rem;
              @include mixins.phone{
                font-size: .8rem;
              }
            }
          }

          .row:nth-child(odd) {
            background-color: #F4F4F4;
          }
        }
      }

      .applicationElement {

        .container {
          h2 {
            padding: 1rem 0;
          }

          a {
            color: #007bff;
            text-decoration: underline;
            transition: all 0.2s ease-in-out;

            &:hover {
              color: #0056b3;
            }
          }
        }
      }

    }
    .documentsContent {
      width: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: start;
      height: 100%;
      gap: 1rem;
      @include mixins.maxmq(lg) {
        grid-template-columns: repeat(1, 1fr);
      }

      .documentsButton {
        width: 100%;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding: 0.5rem;
        border: 0.1rem solid;
        border-radius: 0.3rem;
        transition: ease-in-out 0.3s;

        &:hover {
          background-color: var(--clr-grey-100);
        }

        .documentsIconBlack {
          font-size: 2.5rem;
        }

        p {
          width: 100%;
          font-size: 1.125rem;
          font-weight: var(--fw-semi-bold);
          margin-left: 1.2rem;
        }

        .iconDownload {
          color: #1976D2;
          font-size: 3rem;
          margin-left: 1rem;
        }
      }
    }
  }
}
