@use '../../../../../styles/abstracts/mixins';
@use "../../../../../styles/abstracts/variables" as *;

.product {
  display: flex;
  flex-direction: row;
  column-gap: 0.625rem;

  @include mixins.phone {
    flex-direction: column;
    row-gap: 0.625rem;
  }

  .productImage {
    width: 150px;
    height: 150px;

    @include mixins.phone {
      width: 100%;
      height: 100%;
    }
  }

  .info {
    flex-grow: 1;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(7, auto);

    .full_width {
      grid-column: span 2;

      @include mixins.phone {
        display: flex;
        flex-direction: column;
      }
    }
    
    .name {
      color: $color_primary;
      font-size: 1.25rem;
      font-weight: var(--fw-semi-bold);
    }

    a {
      color: $color_primary;
      text-decoration: underline;
    }

    .number {
      text-align: right;
    }
    
    .subtitle {
      font-size: 1.25rem;
      font-weight: var(--fw-semi-bold);
    }
  }
}