@use '../../styles/abstracts/mixins';

.imagLayout{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 0 1rem 0;
  img {
    width: 100%;

  }
}
.description {
  color: #333;
  line-height: 1.6;
  margin: 1rem 7rem 2rem 7rem;
  font-size: 2rem;
  @include mixins.phone{
    font-size: 1rem;
    margin: 1rem 1rem;
  }
}

.imagMap{
  margin: 1rem 7rem 2rem 7rem;
  @include mixins.phone{
    margin: 1rem 1rem;
  }
  img {
    position: relative;
    cursor: pointer;
    width: 100%;
    object-fit: cover;
  }
}
