@use '../../styles/abstracts/mixins';
.homeHeader{
  @include mixins.laptop {
    padding: 0 10rem;
  }
}
.home {
  font-size: 1.6rem;
  color: var(--text);
  background: var(--bg);
  padding: 0 2rem;
  @include mixins.laptop {
    padding: 0 10rem;
  }

  .addToolsLayout {
    width: 100%;
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #F4F4F4;
    padding: 3rem;
    margin-bottom: 2rem;
    @include mixins.phone {
      flex-direction: column;
      margin-bottom: 1rem;
      padding: 2rem;
    }

    img {
      width: 50%;
      @include mixins.phoneAndTablet {
        width: 80%;
      }
    }

    .left {
      width: 50%;
      padding: 0 2rem;
      @include mixins.phoneAndTablet {
        width: 100%;
        padding: 2rem;
      }
      @include mixins.phone {
        padding: 1rem;
      }

      h1 {
        font-weight: var(--fw-semi-bold);
        @include mixins.phone {
          font-size: 1rem;
        }
      }

      p {
        margin: 1rem 0;
        font-size: 1rem;
        @include mixins.phone {
          font-size: .8rem;
        }
      }

      .btn {
        margin-top: 1rem;

        .icon {
          font-size: 4rem;
          @include mixins.phone {
            font-size: 3rem;
          }
        }
      }
    }
  }

  .contactLayout {
    width: 100%;
    padding: 1rem 0 2rem 0;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;

    h1 {
      margin-bottom: 1rem;
      font-weight: var(--fw-semi-bold);
      text-align: center;
      @include mixins.phone {
        font-size: 1.2rem;
      }

    }

    .btnLink {
      margin: 0 auto;

      .btn {
        border-radius: 1.5rem;
        height: 3rem;
        @include mixins.phone {
          height: 2.5rem;
        }
      }
    }
  }
}

a, a:hover, a:focus, a:active {
  text-decoration: none;
  color: inherit;
}
