@use '../../../styles/abstracts/mixins';

.loading {
  display: flex;
  justify-content: center;
  margin-top: 4rem;
}

.carousel{
  padding: 0 10rem 0 10rem;
  @include mixins.phone{
    padding: 0;
  }
}

.layout {
  font-size: 1.6rem;
  color: var(--text);
  background: var(--bg);
  padding: 0 10rem 2rem 10rem;
  @include mixins.phone {
    padding: 0 1.5rem;
  }

  .Tree {
    margin-top: 0.5rem;
    @include mixins.phone{
      display: none;
    }
  }

  .title {
    padding: 2rem 0 1rem 0;
    font-size: 3rem;
    font-weight: var(--fw-semi-bold);
    line-height: 125%;
    text-align: center;
    align-self: center;
    text-transform: uppercase;
    @include mixins.phone{
      font-size: 1.875rem;
      padding: 1rem 0 .5rem 0;
    }
  }

  .subTitle {
    width: 100%;
    margin-bottom: 2rem;
    font-size: 2rem;
    line-height: 125%;
    text-align: center;
    align-self: center;
    @include mixins.phone{
      font-size: 1rem;
    }
  }

  .description {
    text-align: center;
    width: 100%;
    font-size: 2rem;
    line-height: 125%;
    @include mixins.phone{
      font-size: .875rem;
      text-align: justify;
      line-height:100%;
    }
  }

  .description + .description {
    margin: 2rem 0;
    @include mixins.phone{
      margin: 1rem 0 1.3rem 0;
    }
  }


  .center {
    width: 100%;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include mixins.phone{
      margin-bottom: 1rem;
    }

    a {
      @include mixins.phone{
        width: 100%;
      }
      .btn {
        border-radius: 1.5rem;
        height: 3rem;
        @include mixins.phone{
          font-size: 1.3rem;
          border-radius: .5rem;
          width: 100%;
        }
      }
    }

    h2 {
      margin-top: 4rem;
      font-size: 2.1rem;
      line-height: 125%;
      text-transform: uppercase;

    }

    hr {
      width: 8rem;
      background-color: var(--clr-grey-500);
      border: none;
      height: 0.1rem;
      margin: 1rem 0 2rem 0;
    }
  }

  .headerClass{
    @include mixins.phone{
      font-size: .875rem;
      h1{
        width: 95%;
      }
    }
  }

  .iconPlusMinus{
    @include mixins.phone{
      top: .3rem !important;
      right: .5rem !important;
    }
  }

  .accordionBody{
    @include mixins.phone{
      padding: 0;
    }
  }

  .events {
    display: inline-flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 1rem;
    @include mixins.phone{
      margin-bottom: 1.5rem;
    }

    .event {
      display: inline-flex;
      flex-direction: row;
      width: 100%;
      padding: 1rem;
      @include mixins.phone{
        flex-direction: column;
        padding: 0;
      }

      .details {
        width: 100%;
        padding: 1rem;
        border-bottom: 1px solid #333333;
        @include mixins.phone{
          padding: 0 1rem 1rem 2rem;
          border-bottom:none;
        }


        h2 {
          color: #007AFF;
          font-size: 1.8rem;
          font-weight: var(--fw-semi-bold);
          @include mixins.phone{
            font-size: 1rem;
          }
        }

        p {
          font-size: 1.5rem;
          @include mixins.phone{
            font-size: .875rem;
          }
        }
      }

      .register {
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid #333333;
        @include mixins.phone{
          padding-bottom: 1rem;
        }

        a {
          display: flex;
          align-items: stretch;

          button {
            flex: 1;
            @include mixins.phone{
              font-size: 1rem;
              width: 100%;
            }
          }
        }

      }
    }

  }

  .contactLayout {
    width: 100%;
    padding: 2rem 0;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;

    h1 {
      margin-bottom: 1rem;
      font-weight: var(--fw-semi-bold);
      text-align: center;
    }

    .btnLink {
      margin: 0 auto;

      .btn {
        border-radius: 1.5rem;
        height: 3rem;
      }
    }
  }

}
