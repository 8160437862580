@use '../../../styles/abstracts/mixins';

.segmentPickerTitle {
  width: 100%;
  font-size: 2.5rem;
  font-weight: var(--fw-semi-bold);
  line-height: 125%;
  text-align: center;
  align-self: center;
  padding: 1rem 0 1rem 0;
  @include mixins.tablet {
    font-size: 2rem;
  }
  @include mixins.phone {
    font-size: 1.25rem;
    padding: 1.5625rem 0 0 0;
  }
}


.segmentPicker {
  width: 100%;
  display: grid;
  grid-gap: 1%;
  grid-template-columns: repeat(3, 32.8%);
  padding: 1rem 0 2rem 0;
  justify-content: center;
  @include mixins.laptop {
    grid-template-columns: repeat(auto-fill, 32%);
  }
  @include mixins.tablet {
    grid-template-columns: repeat(auto-fill, 40%);
  }
  @include mixins.phone {
    grid-template-columns: repeat(auto-fill, 100%);
    padding: 0.625rem 0 2rem 0;
  }

  .segmentPickerCard {
    cursor: pointer;
    border-radius: 0.2rem;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    color: var(--text);
    display: inline-flex;
    flex-direction: column;

    p {
      margin: 0;
      text-align: center;
      padding: 1rem 1rem;
      font-weight: 600;
      font-size: 1.5rem;
      background-color: #F4F4F4;
      @include mixins.phone {
        font-size: 1.25rem;
      }
    }

    img {
      width: 100%;
      object-fit: cover;
      overflow: hidden;
      margin: auto 0 0 0;
      display: block;
      box-sizing: border-box;
    }

    transition: ease-in-out 0.1s;

    &:hover {
      transform: translateY(-0.188rem) scale(1.03);
      box-shadow: 0 0.313rem 0.938rem rgba(0, 0, 0, 0.35);
      border: 0.3rem solid;
      border-image-slice: 1;
      border-image-source: linear-gradient(#00b5ee, #0937ad);
    }
  }
}
