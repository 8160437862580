.accordionContainer {
  width: 100%;
  overflow: hidden;

  .accordionHeader {
    cursor: pointer;
    position: relative;
    padding: 0.6rem;
    margin: 0.6rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &.toggleHeader{
      border-bottom: 1px solid black;
      h1{
        margin-left: 1.5rem;
        font-weight: var(--fw-semi-bold);
        font-size: 1rem;
      }
      .toggle{
        margin-right: 2rem;
      }
      .iconPlus,
      .iconMinus {
        top: 0.5rem;
        left: 0;
      }
    }

    .iconPlus,
    .iconMinus {
      position: absolute;
      top: 1rem;
      right: 4rem;
      font-size: 2rem;
      visibility: hidden;
      opacity: 0;
      transition: ease-in-out 0.3s;

      &.active {
        rotate: (0deg);
        visibility: inherit;
        opacity: 1;
      }
    }

    .iconPlus {
      rotate: (-90deg);
      visibility: inherit;
      opacity: 1;
      transition: ease-in-out 0.3s;

      &.active {
        rotate: (0deg);
        visibility: hidden;
        opacity: 0;
      }
    }

    transition: ease-in-out 0.3s;

    &:hover {
      background-color: var(--clr-grey-100);
    }
  }

  .accordionHeader.dark {
    background-color: #333333;

    h1 {
      color: white;
      font-weight: var(--fw-semi-bold);
    }

    .iconPlus,
    .iconMinus {
      color: white;
    }

    &:hover {
      background-color: #4d4d4d;
    }

    &.active {
      background-color: #F4F4F4;

      h1 {
        color: #333333;
      }

      .iconPlus,
      .iconMinus {
        color: #333333;
      }
    }
  }

  .accordionBody {
    transition: height 0.5s ease-in-out;
    padding: 0 2rem;
  }
}
