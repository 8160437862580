@use '../../../../../../styles/abstracts/mixins';

.colorCardContainer {
  display: flex;
  flex-direction: column;


  img {
    width: 100%;
  }

  .colorCardTitle {
    padding: 1rem;
    font-size: 1.2rem;
    text-align: center;
    mix-blend-mode: difference;
  }
}
