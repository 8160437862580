@use '../../styles/abstracts/mixins';

.header {
  position: relative;
  width: 100%;

  .background {
    width: 100%;
  }

  .robot {
    position: absolute;
    width: 30%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 100;
  }
}

.layout {
  width: 100%;

  .body {
    padding: 2rem 10rem;
    @include mixins.phone {
      padding: 1rem 2rem;
    }

    .description {
      padding: 0 1rem;

      .texte {
        h1 {
          text-align: center;
          text-transform: uppercase;
          font-weight: var(--fw-semi-bold);
          font-size: 1.8rem;
          line-height: 2rem;
          @include mixins.phone{
            font-size: 1.2rem;
            line-height: 1.5rem;
          }
        }

        .longTexte {
          padding: 2rem 0;
          @include mixins.phone{
            padding: 1rem 0;
          }

          .seenMoreButton{
            color: #007AFF;
            text-decoration: underline;
          }

          p {
            text-align: center;
            font-size: 1.4rem;
            @include mixins.phone{
              font-size: .8rem;
              text-align: start;
            }
          }

          h2 {
            padding: 1rem 0;
            text-align: center;
            font-size: 1.6rem;
            font-weight: var(--fw-semi-bold);
          }

        }
      }

      .picker {
        width: 100%;
        display: grid;
        grid-gap: 1%;
        grid-template-columns: repeat(3, 32.8%);
        padding: 1rem 0 2rem 0;
        justify-content: center;
        @include mixins.phone{
          display: flex;
          flex-direction: column;
          padding: 0 0 1rem 0;
        }

        .element {
          background-color: #F4F4F4;

          h1 {
            padding: 1rem;
            font-size: 1.8rem;
            line-height: 1.8rem;
            font-weight: var(--fw-bold);
            color: #007AFF;
            text-transform: uppercase;
            @include mixins.phone{
              font-size: 1.2rem;
            }
          }

          h1:nth-child(1) {
            background-color: white;
            padding: 1rem 1rem 0.5rem 1rem;
            @include mixins.phone{
              padding: .5rem .5rem 0 .5rem;
            }
          }

          h1:nth-child(2) {
            padding: 0.5rem 1rem 1rem 1rem;
            @include mixins.phone{
              padding: 0 .5rem 0 .5rem;
            }
          }

          p {
            padding: 1rem;
            font-size: 1.4rem;
            @include mixins.phone{
              font-size: .8rem;
            }
          }

          p:nth-child(3) {
            padding: 1rem 1rem 0 1rem;
            @include mixins.phone{
              padding: .5rem .5rem .5rem .5rem;
            }
          }

          p:nth-child(4) {
            padding: 0 1rem 1rem 1rem;
            @include mixins.phone{
              padding: 0 .5rem .5rem .5rem;
            }
          }
        }
      }

      .video {
        padding: 1rem 0;

        h1 {
          text-align: center;
          text-transform: uppercase;
          font-weight: var(--fw-semi-bold);
          font-size: 1.8rem;
          line-height: 2rem;
          @include mixins.phone{
            font-size: 1.5rem;
          }
        }

        .container {
          margin-top: 1rem;
          display: flex;
          align-items: center;
          flex-direction: column;

          .seenMoreButton{
            color: #007AFF;
            text-decoration: underline;
          }

          p {
            font-size: 1.4rem;
            @include mixins.phone{}
            font-size: .8rem;
          }

          .imgContainer {
            margin-top: 1rem;
            position: inherit;
            width: 50%;
            height: 27rem;
            @include mixins.phone{
              height: 11rem;
              width: 100%;
            }

            img {
              position: relative;
              cursor: pointer;
              width: 100%;
              object-fit: cover;
              height: 23rem;
              @include mixins.phone{
                height: 11rem;
              }
            }

            .iconPlay {
              cursor: pointer;
              font-size: 6rem;
              color: white;
              position: relative;
              top: -15rem;
              left: 25rem;
              transform: translate(-50%, -50%);
              @include mixins.phone{
                font-size: 4rem;
                top: -5rem;
                left: 10rem;
              }
            }

          }
        }

      }
    }
  }

  .contact_layout {
    position: relative;
    padding-top: 2rem;

    .robot {
      position: absolute;
      height: 120%;
      top: -5rem;
      left: 0;
      z-index: 100;
      @include mixins.maxmq(xl) {
        height: 50%;
        width: 30%;
        top: 4rem;
        left: 0;
      }
    }

    .contact_body {
      background-color: #F4F4F4;
      padding: 2rem 0;
      margin-bottom: 2rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      @include mixins.phone{
        padding: 1rem 0 ;
      }

      h2 {
        padding: 1rem 0;
        text-align: center;
        font-size: 1.6rem;
        font-weight: var(--fw-semi-bold);
        @include mixins.phone{
          font-size: 1rem;
        }
      }

      p {
        text-align: center;
        padding: 0 15vw;
        font-size: 1.6rem;
        @include mixins.phone{
          font-size: 1rem;
          margin: 1rem 1rem 0 4rem;
          padding: 0;
        }
      }

      .btnLink {
        margin: 2rem 35vw;
        @include mixins.phone{
          margin: 2rem 6rem;
        }

        .btn {
          width: 100%;
          @include mixins.phone{
            font-size: .8rem;
          }
        }

      }
    }
  }
}