.emptyState {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 1.25rem;
    justify-content: center;

    .title {
        font-size: 1.25rem;
        font-weight: var(--fw-semi-bold);
    }

    p {
        font-size: 1rem;
    }
}