@use '../../../../../styles/abstracts/mixins';

.productCardContainer {
  cursor: pointer;
  position: relative;
  width: 100%;
  background: #fcfcfc;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.25);
  transition: all 0.5s ease-out;

  &:hover {
    color: #007AFF ;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.4);
  }

  .productCardFavoriteOutlined {
    position: absolute;
    z-index: 2;
    top: 0.5rem;
    right: 0.5rem;
    color: #d9d9d9;
    font-size: 2.625rem;
    transition: all 0.5s ease-out;
    opacity: 1;
  }

  .productCardFavoriteFilled {
    position: absolute;
    z-index: 2;
    top: 0.5rem;
    right: 0.5rem;
    font-size: 2.625rem;
    transition: all 0.5s ease-out;
    color: #c90909;
    opacity: 0;
    @include mixins.phone{
      transition: all 0s;
    }

    &:hover {
      opacity: 1;
      @include mixins.phone{
        opacity: 0;
      }
    }

    &.active {
      opacity: 1;
    }
  }

  .productCardImage {
    position: relative;
    width: 100%;
    height: auto;
  }

  .productCardDetails {
    align-self: end;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;

    p {
      line-height: 130%;
    }

    div {
      margin: 0 1.1rem 1.1rem 1.1rem;
    }

    .productCardFamily {
      font-size: 1.6rem;
      height: 3ch;

      p {
        span {
          font-size: 1.6rem;
          font-weight: var(--fw-semi-bold);
        }
      }

      .viewCertificat{
        display: flex;
        color: #0072de;
        cursor: pointer;
        text-decoration: underline;
        font-size: 1rem;
        margin: .5rem 0;
      }
    }

    .productPrice {
      font-size: 1.6rem;

      .productAmount {
        color: var(--clr-red-700);
      }
    }

    .productCardDescription {
      font-size: 1.2rem;
      min-height: 4ch;

      p {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .productCardAllDescription {
      font-size: 1.2rem;
      min-height: 4ch;

      p {
        display: block;
        overflow: visible;
        text-overflow: initial;
        white-space: normal;
      }
    }

    .productCardColor {
      font-size: 1.1rem;
    }

    .productCardFormat {
      font-size: 1.1rem;
      min-height: 2ch;

      p {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

