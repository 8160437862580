@use "../../../styles/abstracts";
@use "../../../styles/abstracts/variables" as *;

.sidebar {
    flex-basis: 1;
    display: flex;
    flex-direction: column;
    row-gap: 40px;

    ul li {
        min-width: 230px;
        height: 40px;
        padding: 10px 20px;
        cursor: pointer;

        &.active {
            background-color: $color_primary_lightest;
            border-right: 2px solid $color_primary_darker;
        }
    }

    .textlink {
        color: $color_primary;
    }

    @include abstracts.phone {
        row-gap: 0px;
    }
}