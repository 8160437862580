.layout {
  width: 100%;
  height: 100%;

  .iconPlus {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    color: #007AFF;
    background-color: white;
    cursor: pointer;

    &:hover {
      color: #238afc;
    }
  }

  .iconMinus {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    color: black;
    background-color: white;
    cursor: pointer;

    &:hover {
      color: #3a3939;
    }
  }

}