@use '../../styles/abstracts/mixins';

.NavBar {
  position: relative;
  z-index: 2000;
  box-shadow: 0 0.1rem 0.5rem rgba(0, 0, 0, 0.1);
  @include mixins.phoneAndTablet {
    min-width: 22.5rem;
  }

  nav {
    position: relative;
    background: #fff;
    z-index: 2000;

    .promo {
      background-color: #FFCA00;
      text-align: center;
      padding: 0.5rem 0;
      margin: auto 0;

      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 0.5rem;

      span {
        font-weight: var(--fw-semi-bold);
        font-size: 0.875rem;
        @include mixins.phone {
          white-space: break-spaces;
        }
      }

    }

    .firstHeader{
      padding: .5rem 2rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      background-color: #F4F4F4;
      @include mixins.phone{
        display: none;
      }
      .info{
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1rem;
        font-size: 1rem;
        .contactUs{
          color: #007AFF;
        }
      }
      .languages{
        background-color: #333333;
        color: white;
        padding: .5rem;

        .link{
          color: white !important;
        }
      }
    }

    .innerTop{
      padding: 1rem 4rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .inputContainer{
        width: 10%;
      }

      .right{
        float: left;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        height: 5rem;
        line-height: 1.33;

        .hide {
          display: none;
        }
        .rli {
          position: relative;
          vertical-align: middle;
          padding-top: 1rem;


          .icon {
            font-size: 24px;
            cursor: pointer;

          }

          p {
            display: inline-block;
            overflow: hidden;
            position: relative;
            padding: 0.5rem;
            border-radius: 1.5rem;
            color: #000;
            font-size: 14px;
            font-weight: var(--fw-semi-bold);
            line-height: 1.33;
            cursor: pointer;

            &.active {
              color: #fff;
              background-color: #000;
            }
          }
        }

        .rli + .rli {
          margin-left: 1.5rem;
        }
      }
    }


    .bar {
      height: 8rem;
      width: 100%;
      background: #fff;
      -webkit-box-sizing: content-box;
      box-sizing: content-box;

      @include mixins.tablet {
        height: 4.5rem;
      }
      @include mixins.phone {
        height: 7rem;
        display: flex;
        flex-direction: column;
        gap: .3rem;
      }

      .inner {
        height: 100%;
        font-size: 0;
        display: flex;
        flex-direction: row;
        @include mixins.phone{
          height: 50%;
          padding: .5rem 1.5rem;
          justify-content: space-between;
        }

        .mobile_menu {
          display: none;
          @include mixins.phoneAndTablet {
            display: flex;
            align-items: center;
            float: right;
            font-size: 0;
          }

          a, div {
            display: inline-block;
            position: relative;
            vertical-align: middle;
            margin-left: 0.5rem;

            .icon {
              margin-top: .4rem;
              font-size: 1.8rem;

            }
          }

          div {
            cursor: pointer;
          }

          div + a {
            margin-left: 1.5rem;
          }

          .burger {
            display: inline-block;
            position: relative;
            vertical-align: middle;
            margin-left: 1.5rem;
            cursor: pointer;

            .icon {
              font-size: 36px;

            }
          }
        }

        .left {
          float: left;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          position: relative;
          height: 5rem;
          width: 15%;

          @include mixins.phoneAndTablet {
            height: auto;
            margin-top: 4px;
          }
          @include mixins.phone{
            left: 1rem;
          }

          .logo_icon {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 3rem;
            margin-top: 1rem;
            @include mixins.phoneAndTablet{
              height: 2rem;
              margin: 0;
            }
          }
        }

        .center{
          width: 85%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          font-size: 0;
          @media only screen and (min-width: 1069px) and (max-width: 1106px) {
            margin-left: 1rem;
          }
          @include mixins.phone{
            display: none;
          }
          @include mixins.tablet{
            width: 65%;
          }
          .top{
            width: 85%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            ul {
              float: left;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              height: 3rem;
              line-height: 1.33;
              width: 100%;
              @include mixins.tablet{
                display: none;
              }
              @include mixins.phone{
                display: none;
              }

              form{
                display: flex;
                flex-direction: row-reverse;
                padding-right: 1rem;
                width: 100%;
              }

              .hide {
                display: none;
              }

              .inputContainer {
                padding: 1rem 1rem 0 1rem;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                flex-direction: row;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                height: 5rem;
                line-height: 1.33;
                transition: all ease-in-out 0.3s;

                .input {
                  margin: 0 0.5rem;
                }

                .icon {
                  font-size: 36px;
                  color: #2d2d2d;
                  cursor: pointer;
                }

              }

              .rli {
                position: relative;
                vertical-align: middle;
                padding-top: 1rem;

                .logInButton{
                  width: 14rem;
                  background-color: #007AFF;
                  color: white;
                  padding: .5rem;
                  font-size: 1rem;
                  cursor: pointer;
                  text-align: center;
                }

                .icon {
                  font-size: 24px;
                  cursor: pointer;

                }

                p {
                  display: inline-block;
                  overflow: hidden;
                  position: relative;
                  padding: 0.5rem;
                  border-radius: 1.5rem;
                  color: #000;
                  font-size: 14px;
                  font-weight: var(--fw-semi-bold);
                  line-height: 1.33;
                  cursor: pointer;

                  &.active {
                    color: #fff;
                    background-color: #000;
                  }
                }
              }

              .rli + .rli {
                margin-left: 1.5rem;
              }
            }


          }

          .bottom{
            width: 100%;
            @include mixins.tablet{
              display: none;
            }
            @include mixins.phone{
              display: none;
            }
            ul {
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              height: 3rem;
              line-height: 1.55;
              transition: all ease-in-out 0.3s;



              .hide {
                display: none;
              }

              .li {
                position: relative;
                vertical-align: middle;
                text-align: center;

                .link {
                  display: inline-block;
                  overflow: hidden;
                  position: relative;
                  padding: 0.5rem;
                  color: #000;
                  font-size: 14px;
                  font-weight: var(--fw-semi-bold);
                  line-height: 1.33;

                  &.active {
                    color: #fff;
                    background-color: #000;
                  }
                }

                .colorMatch{
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  border: 0.12rem solid black;
                  overflow: hidden;
                  position: relative;
                  padding: 0.2rem;
                  margin-left: 1rem;
                  color: #000;
                  font-size: 14px;
                  font-weight: var(--fw-semi-bold);
                  line-height: 1.33;
                  img{
                    width: 1.5rem;
                    height: 1.5rem;
                  }
                }

                p {
                  cursor: pointer;
                }

                span{

                  position: relative;
                  display: inline-block;
                  font-size: 1rem;
                  font-weight: var(--fw-semi-bold);
                  margin: .2rem 1rem 0 1rem;
                  @media only screen and (min-width: 1068px) and (max-width: 1316px)  {
                    display: none;
                  }
                }
              }

            }
          }
        }

        .right{
          float: right;
          font-size: 0;
          display: flex;
          justify-content: center;
          @include mixins.tablet{
            display: none;
          }
          @include mixins.phone{
            display: none;
          }


        }
      }

      .searchLayout {
        display: none;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 0 1.5rem;

        @include mixins.phone{
          display: flex;
        }


        .searchInput {
          width: 100%;
          margin: 0;
          height: 2.5rem;

          svg{
            font-size: 2rem;
            top: .3rem;
          }

          input{
            font-size: 1rem;
          }
        }

        .btn {
          width: 20%;
          top: 0;
        }
      }
    }
  }

  .iframeTitle {
    display: flex;
    flex-direction: row;
    align-items: center;

    p {
      width: 100%;
      font-weight: var(--fw-semi-bold);
      color: white;
      font-size: 2rem;
      text-align: center;

    }
  }

}