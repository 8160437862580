@use '../../../styles/abstracts/mixins';

.loading {
  display: flex;
  justify-content: center;
  margin-top: 4rem;
}

.header{
  padding: 0 10rem 2rem 10rem;
  @include mixins.phone{
    padding: 0;
  }
}

.layout {
  font-size: 1.6rem;
  color: var(--text);
  background: var(--bg);
  padding: 0 10rem 2rem 10rem;
  @include mixins.phone{
    padding: 0 1.5rem;
  }

  .Tree {
    margin-top: 0.5rem;
    @include mixins.phone{
      display: none;
    }
  }

  .title {
    padding: 2rem 0 1rem 0;
    font-size: 3rem;
    font-weight: var(--fw-semi-bold);
    line-height: 125%;
    text-align: center;
    align-self: center;
    text-transform: uppercase;
    @include mixins.phone{
      font-size: 1.25rem;
      padding: 1rem 0 .5rem 0;
    }
  }

  .description {
    margin-bottom: 2rem;
    text-align: center;
    width: 100%;
    font-size: 1.8rem;
    line-height: 125%;
    @include mixins.phone{
      font-size: .875rem;
      margin-bottom: .5rem;
    }
  }

  .bold {
    font-weight: var(--fw-semi-bold);
  }

  .center {
    width: 100%;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include mixins.phone{
      margin-bottom: 0;
    }

    a {
      .btn {
        border-radius: 1.5rem;
        height: 3rem;
      }
    }

    h2 {
      margin-top: 1rem;
      font-size: 2.1rem;
      line-height: 125%;
      text-transform: uppercase;
      @include mixins.phone{
        font-size: 1rem;
      }
    }

    hr {
      width: 8rem;
      background-color: var(--clr-grey-500);
      border: none;
      height: 0.1rem;
      margin: 1rem 0 2rem 0;
      @include mixins.phone{
        height: 0.08rem;
        margin: .8rem 0 1rem 0;
      }
    }
  }

  .headerClass{
    @include mixins.phone{
      font-size: .8em;
      margin: .3rem 0;
      h1{
        width: 95%;
      }
    }
  }

  .iconPlusMinus{
    @include mixins.phone{
      top: .8rem !important;
      right: .5rem !important;
    }
  }

  .accordionBody{
    @include mixins.phone{
      padding: 0;
    }
  }

  .events {
    display: inline-flex;
    flex-direction: column;
    width: 100%;

    .event {
      display: inline-flex;
      flex-direction: row;
      width: 100%;
      padding: 1rem;
      @include mixins.phone{
        flex-direction: column;
        padding: 0;
      }

      .date {
        width: 15rem;
        display: inline-flex;
        flex-direction: column;
        border: 1px solid #333333;
        @include mixins.phone{
          display: none;
        }

        p {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 50%;
          font-size: 2rem;
          font-weight: var(--fw-semi-bold);
        }

        .month {
          background-color: #007AFF;
          color: white;
        }
      }

      .details {
        width: 100%;
        padding: 1rem;
        border-bottom: 1px solid #333333;
        @include mixins.phone{
          padding: 0 0 1rem 0;
          margin-bottom: .5rem;
        }

        .addInfo {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          gap: 2rem;
          @include mixins.phone{
            flex-direction: column;
            gap: 1rem;
          }
          p{
            @include mixins.phone{
              padding: 0 0 0 2rem;
            }
          }
        }

        h2 {
          color: #007AFF;
          font-size: 1.8rem;
          font-weight: var(--fw-semi-bold);
          @include mixins.phone{
            font-size: 1rem;
            padding: 0 1rem;
          }

          span {
            font-weight: var(--fw-regular);
          }
        }

        .mobileDate{
          display: none;
          @include mixins.phone{
            display: flex;
            padding: 0 0 0 2rem;
            margin: .5rem 0;
          }
          p{
            padding: 0;
          }
          .value{
            @include mixins.phone{
              color: #007AFF !important;
            }
          }
        }

        p {
          font-size: 1.5rem;
          @include mixins.phone{
            font-size: .875rem;
            padding: 0 1rem;
          }
        }
      }

      .register {
        display: flex;
        justify-content: center;
        align-items: center;

        .content{
          @include mixins.phone{
            font-size: 1rem;
          }
        }

        a {
          display: flex;
          align-items: stretch;

          button {
            flex: 1;

          }
        }

      }
    }

  }

  .contactLayout {
    width: 100%;
    padding: 2rem 0;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;

    h1 {
      margin-bottom: 1rem;
      font-weight: var(--fw-semi-bold);
      text-align: center;
    }

    .btnLink {
      margin: 0 auto;

      .btn {
        border-radius: 1.5rem;
        height: 3rem;
      }
    }
  }

  .resouces{
    margin-top: 1rem;
  }

}
