@use "../../../../styles/abstracts";
@use "../../../../styles/abstracts/variables" as *;

.frameset {
    display: flex;
    flex-direction: column;
    row-gap: 1.25rem;

    .header {
        display: flex;
        justify-content: flex-end;
    }

    .content{
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(430px, 1fr));
        gap: 0.625rem;
        width: 100%;

        .nearExpiration{
            display: flex;
            align-items: center;
            color: $color_semantic_alert;
            column-gap: 5px;
        }
    }
}