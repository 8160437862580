@use '../../../styles/abstracts/mixins';

.layout {
  width: 100%;
  height: 100%;
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;

  .header {
    display: inline-flex;
    @include mixins.phone{
      display: flex;
      flex-direction: column;
    }


    .imgContainer {
      width: 65%;
      height: 100%;
      padding-right: 1rem;
      @include mixins.phone {
        width: 100%
      }

      .overViewImg {
        background-position: top;
        object-fit: contain;
      }
    }

    .productInfoSection {
      display: inline-flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0 1rem;
      width: 35%;
      border-left: 1px solid black;
      @include mixins.phone {
        width: 100%;
        border-left: 0;
        padding: 0;
        margin-top: 1.5rem;
      }

      .titleContainer {
        width: 100%;
        position: relative;
        border-bottom: 0.1rem solid black;
        padding-bottom: 1rem;
        @include mixins.phone{
          padding-bottom: .5rem;
        }

        h1 {
          font-size: 2.5rem;
          font-weight: var(--fw-semi-bold);
          margin-bottom: 1rem;
          line-height: 120%;
          @include mixins.phone{
            font-size: 1.5rem;
            margin-bottom: 0;
          }
        }

        h2 {
          margin: 0.5rem 0;
          font-size: 1rem;
          line-height: 120%;
          @include mixins.phone{
            font-size: .8rem;
            margin: 0.2rem 0;;
          }
        }

        p {
          margin: 1.2rem 0;
          font-size: 0.875rem;
          line-height: 120%;
        }
      }

      .productDisplay {
        width: 100%;
        display: inline-flex;
        flex-direction: column;
        padding: 1rem 0;

        .left {
          width: 100%;
          display: inline-flex;
          flex-direction: column;

          .overViewImg {
            background-position: top;
            width: 40%;
            object-fit: contain;
            @include mixins.phone{
              width: 100%;
            }
          }

          .ds {
            display: inline-flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 1rem;
            margin-top: 1rem;
            @include mixins.phone{
              gap: .7rem;
              margin-top: 0;
            }

            .ds_img {
              padding: 1rem;
              width: 5.5rem;
              height: 5.5rem;
            }
          }

          .positionBtn{
            @include mixins.phone{
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
            }
            .btn {
              width: 50%;
              margin-top: 1rem;

              .content {
                font-weight: 500;
                font-size: 1.2vw;
                @include mixins.phone{
                  font-size: .8rem;
                }
              }
            }
          }

          .productInfoContainer {
            width: 100%;
            padding-top: 1rem;
            @include mixins.phone{
              padding-top: 0;
            }

            .featuresContainer {

              h3 {
                font-size: 1rem;
                padding-bottom: 0.5rem;
                font-weight: var(--fw-semi-bold);
              }

              ul {
                display: inline-flex;
                flex-direction: column;
                padding: 0 0 0 0.5rem;
                list-style-type: disc;
              }

              li {
                font-size: 0.875rem;
                line-height: 150%;
                list-style: inside;
                border: none;
                padding: 0;
              }
            }
          }
        }


      }
    }
  }

  .carousel {
    display: inline-flex;
    width: 100%;

    .btn {
      margin: auto;
      padding: 0;
      border: 0;
      @include mixins.phone{
        display: none;
      }

      .icon {
        font-size: 3rem;
      }
    }

    button[disabled] {
      display: none;
    }

    .slider {
      width: 100%;
      margin: 0 auto;

      .slide {
        padding: 0.5rem;

        .slideImg {
          height: 10rem;
          object-fit: fill;
          margin: auto;
          box-sizing: border-box
        }
      }
    }
  }

  @include mixins.maxmq(lg) {
    width: 100%;
  }
}