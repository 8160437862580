.dropdown {
  --border-clr: var(--clr-grey-400);
  margin: 1rem 0;

  &.dark {
    --border-clr: var(--clr-grey-500);
    --bg: var(--clr-grey-900);
    --text: var(--clr-grey-100);
  }

  &.left {
    flex-direction: row;

    label {
      font-size: 1.4rem;
      align-self: center;
      padding-right: 1.6rem;
    }
  }

  &.shiny {
    --border-clr: rgba(255, 255, 255, 0.4);
    --text: var(--clr-grey-50);

    .wrapper {
      select {
        background: radial-gradient(
                        farthest-corner at 0px 0px,
                        rgba(255, 255, 255, 0.3),
                        rgba(255, 255, 255, 0.1)
        ),
        rgba(25, 118, 210, 0.55);
        overflow: hidden;

        &:hover {
          border-color: rgba(255, 255, 255, 0.4);
        }
      }
    }
  }

  color: var(--text);
  display: flex;
  flex-direction: column;

  label {
    margin-bottom: 0.5rem;
    padding-left: 0.5rem;
    color: var(--text);
    font-weight: var(--fw-medium);
    cursor: text;
    transition: top 200ms ease-in, left 200ms ease-in, font-size 200ms ease-in;

    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    .mandatory {
      color: var(--clr-red-600);
      font-size: .8rem;
    }
  }

  .wrapper {
    overflow: hidden;
    position: relative;

    select {
      cursor: pointer;
      color: var(--text);
      /* remove default caret */
      -webkit-appearance: none; /* webkit browsers */
      -moz-appearance: none; /* firefox */
      appearance: none; /* modern browsers */
      width: 100%;
      padding: 0 2rem 0 1rem;
      border: 0.1rem solid var(--border-clr);
      background-color: var(--bg);
      font-size: 1.4rem;
      height: 3.6rem;
      outline: none;

      option {
        padding: 1.2rem 0;
        color: var(--text);
      }
    }

    &:after {
      font-weight: var(--fw-semi-bold);
      content: "▼";
      pointer-events: none; /* ▼ click triggers dropdown */
      position: absolute;
      right: 0.8rem;
      top: 1.1rem;
      cursor: pointer;
    }
  }
}
