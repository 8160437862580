@use '../../../styles/abstracts/mixins';


.loading {
  display: flex;
  justify-content: center;
  margin-top: 4rem;
}

.headerCarousel{
  padding: 0 10rem;
  @include mixins.phone{
    padding: 0;
  }
}

.layout {
  font-size: 1.6rem;
  color: var(--text);
  background: var(--bg);
  padding: 0 10rem;
  @include mixins.phone {
    padding: 0 2rem;
  }

}
a, a:hover, a:focus, a:active {
  text-decoration: none;
  color: inherit;
}
