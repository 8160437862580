.layout {
  display: block;
  position: relative;
  z-index: 1;
  margin: 2rem 0 1.5rem 0;

  .dropdown {
    display: inline-flex;
    flex: 1 1 auto;
    flex-basis: 0;
    min-width: 15rem;
    max-width: 100%;

    position: relative;

    .label {
      position: absolute;
      top: -1px;
      left: auto;
      max-width: 100%;
      height: 2.7em;
      line-height: 1.33;
      color: #2d2d2d;
      font-size: 18px;
      cursor: text;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      -webkit-transition: all .2s;
      transition: all .2s;
      /* z-index: -1; */
      pointer-events: none;
      -webkit-font-smoothing: antialiased;
      -webkit-transform: translate3d(0, 3px, 0) scale(1);
      transform: translate3d(0, 3px, 0) scale(1);
      -webkit-transform-origin: left top;
      transform-origin: left top;

      &.focus {
        color: #007ede;
        top: -1rem;
        left: auto;
        font-size: 12px;
        line-height: 1.33;
      }

      &.active {
        top: -16px;
        left: auto;
        font-size: 12px;
        line-height: 1.33;
      }
    }

    .select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      padding: 0 30px 0 0;
      border-radius: 0;
      text-overflow: ellipsis;

      color: transparent;

      display: block;
      width: 100%;
      border: none;
      border-bottom: 1px solid #8c8c8c;
      background-color: transparent;
      box-sizing: border-box;
      border-radius: 0;

      height: 36px;
      line-height: 1.33;
      font-size: 18px;

      &.active {
        color: #2d2d2d;
      }

      select::-ms-expand {
        display: none;
      }

      &.focus {
        outline: 0;
        border-color: #0072de;
        border-width: 2px;
        color: #0072de;
      }

      option {
        padding: 1.2rem 0;
        color: var(--text);
      }
    }

    &:after {
      -webkit-transform: rotate(-180deg);
      content: "";
      position: absolute;
      right: 0;
      top: 12px;
      overflow: hidden;
      font-size: 12px;
      line-height: 1.2;
      z-index: -1;
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-bottom: 6px solid #8c8c8c;
    }

    &.focus:after {
      transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      border-bottom-color: #0074d4;
    }
  }

  .error {
    font-size: 12px;
    margin-top: 6px;
    color: #db332a;
    display: block;

    p {
      display: block;
    }
  }
}
