@use "../../../styles/abstracts";
@use "../../../styles/abstracts/mixins";
@use "../../../styles/abstracts/variables" as *;

.main {
    display: flex;
    column-gap: 1.87rem;

    .filters {
        display: flex;
        flex-direction: column;
        row-gap: 1.25rem;

        .accordion {

            .accordionHeader {
                position: relative;
                margin: 0;
                padding: 0;
                border-bottom: 1px $color_neutral_grey_dark solid;

                h1 {
                    font-size: 1.56rem;
                    font-weight: var(--fw-semi-bold);
                }

                .accordionIconClass {
                    top: 10%;
                    right: 0;
                }

                h1,
                .accordionIconClass {
                    color: $color_primary;
                }
            }

            .accordionContent {
                padding: 1.25rem 0;
                overflow: hidden;

                label {
                    font-weight: normal;
                }
            }

            .accordionHeaderActive {
                background-color: $color_neutral_light;
            }

            .accordionBody {
                padding: 0;
            }
        }

        @include mixins.tablet {
            display: none;
        }

        @include mixins.phone {
            display: none;
        }
    }

    .orders_list {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 1.25rem;

        .filters {
            display: none;
            width: 100%;
        }

        @include mixins.tablet {
            .filters {
                display: flex;
                flex-direction: row;
                column-gap: 1.25rem;
                align-items: flex-end;
            }
        }

        @include mixins.phone {
            .filters {
                display: flex;
                flex-direction: column;
                row-gap: 1.25rem;
            }
        }
    }
}