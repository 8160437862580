@use '../../../styles/abstracts/mixins';

.actionNavBar {
  position: relative;
  text-align: center;
  width: 100%;

  .titleBar {
    background-color: #007AFF;
    padding: 2.5rem 0;
    margin: 0 auto;
    width: 60%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    @include mixins.phone{
      width: 100%;
      margin: 0 auto;
      padding: 1.5rem 0;
    }

    h1 {
      color: #ffffff;
      margin: 0;
      font-size: 1.5rem;
      display: inline-block;
      position: relative;
      z-index: 2;
      font-weight: var(--fw-semi-bold);
      @include mixins.phone{
        font-size: 1rem;
      }
    }
  }

  .options {
    display: flex;
    justify-content: center;
    margin-top: -1.875rem;
    margin-bottom: 1.25rem;
    @include mixins.phone{
      flex-direction: column;
      gap: .6rem;
      margin-top: -1.3rem;
    }

    .option {
      background-color: #F4F4F4;
      color: #000000;
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 2.5rem 2.25rem;
      cursor: pointer;
      transition: all 0.3s ease;
      margin: 0 .5rem;
      position: relative;
      z-index: 1;
      @include mixins.phone{
        font-size: 0.8rem;
        padding: .8rem .4rem;
        margin: 0 .3rem;
        width: 40%;
        height: 5rem;
      }

      p{
        font-weight: var(--fw-semi-bold);
      }

    }
    .line{
      display: flex;
      flex-direction: row;
      @include mixins.phone{
        justify-content: center;
      }
    }
  }
}