@use '../../styles/abstracts/mixins';

.textContainer {
  padding: 1rem 5rem;
  @include mixins.maxmq(lg) {
    padding: 1rem;
  }

  .headerContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;

    .hLine {
      align-self: center;
      width: 3.2rem;
      border-bottom: solid #003e71;
      border-width: 0.063rem;
      line-height: 0.1em;
    }
  }

  h1 {
    color: #003e71;
    text-align: center;
    font-size: 2rem;
    margin: 2rem;
  }

  h2 {
    color: #003e71;
    font-size: 1.8rem;
    margin-bottom: 2rem;
    line-height: 2.4rem;
    padding: 0 1rem;
  }

  h3,
  p {
    font-size: 1.2rem;
    line-height: 2rem;
    margin-bottom: 2rem;
    padding: 0 1rem;
  }

  h3 {
    font-weight: bolder;
  }
}
