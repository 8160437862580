@use "../../styles/abstracts";
@use "../../styles/abstracts/variables" as *;

@mixin hide() {
	@include abstracts.phone {
		display: none;
	}

	@include abstracts.tablet {
		display: none;
	}
}

.bold {
	font-weight: var(--fw-semi-bold);
}

.breadcrumbs,
.search {
	@include hide();
}

.search {
	padding-left: 2rem;
}

.tab_bar {
	@include hide();
	display: none; // @TODO: remove this line when the tab bar needs to show up

	.breadcrumbs {
		@include hide();
	}

	.search {
		padding-left: 2rem;
	}

	.tab_bar {
		@include hide();
		display: none; // @TODO: remove this line when the tab bar needs to show up

		ul {
			align-items: flex-end;
			border-bottom: 1px $color_clear_text solid;
			column-gap: 2rem;
			display: flex;
			justify-content: space-between;

			li {
				position: relative;
				color: $color_text_dark;
				cursor: pointer;
				padding: 1rem 0;
				text-align: center;
				text-transform: uppercase;
				width: calc(100% - 20px);

				&.active::after {
					position: absolute;
					content: "";
					bottom: 0;
					left: 0;
					width: 100%;
					height: 0.5rem;
					background-color: $color_link_text;
				}
			}
		}
	}

	.title {
		@include hide();

		color: $color_link_text;
		font-size: 2.5rem;
		font-weight: var(--fw-bold);
		text-transform: uppercase;
	}

	.subtitle {
		align-items: center;
		background-color: $color_clear_text;
		color: $color_link_text;
		display: flex;
		font-size: clamp(20px, 4vw, 40px);
		justify-content: center;
		padding: 10px;
		text-transform: uppercase;
	}

	.questionSection {
		border-bottom: 1px $color_clear_text solid;
		column-gap: 2rem;
		display: flex;
		justify-content: space-between;

		li {
			position: relative;
			color: $color_text_dark;
			cursor: pointer;
			padding: 1rem 0;
			text-align: center;
			text-transform: uppercase;
			width: calc(100% - 20px);

			&.active::after {
				position: absolute;
				content: "";
				bottom: 0;
				left: 0;
				width: 100%;
				height: 0.5rem;
				background-color: $color_link_text;
			}
		}
	}
}

.title {
	@include hide();

	color: $color_link_text;
	font-size: 2.5rem;
	font-weight: var(--fw-bold);
	text-transform: uppercase;
}

.subtitle {
	align-items: center;
	background-color: $color_clear_text;
	color: $color_link_text;
	display: flex;
	font-size: clamp(20px, 4vw, 40px);
	justify-content: center;
	padding: 10px;
	text-transform: uppercase;
}

.questionSection {
	border-bottom: 1px $color_clear_text solid;

	.questionHeader {
		position: relative;
		margin: 0;

		h1 {
			font-weight: var(--fw-semi-bold);
			color: $color_link_text;
			padding-left: 5%;
			text-transform: uppercase;

			span {
				font-weight: var(--fw-semi-bold);
			}

			@include abstracts.phone {
				padding-left: 10%;
			}
		}

		.questionIconClass {
			top: 10%;
			left: 0;
			color: $color_link_text;
		}
	}

	.questionContent {
		padding: 1em 0;
		border-bottom: 1px $color_link_text solid;
		overflow-x: auto;

		table {
			thead {
				th {
					font-weight: var(--fw-semi-bold);
					text-align: center;
					vertical-align: middle;
					padding: 0 20px;
					background-color: $color_link_text;
					color: $color_clear_text;
				}
			}

			th, td {
				border: 1px solid $color_text_grey;
			}
		}

		ul,
		li,
		ol {
			list-style: auto;
			margin-left: 1rem;
		}

		a {
			color: $color_link_text;
			text-decoration: underline;
		}
	}

	.questionHeader:hover,
	.questionHeaderActive,
	.questionBody {
		background-color: $color_clear_text;
	}
}
