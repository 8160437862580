.TextInput {
  display: block;
  position: relative;
  z-index: 1;
  margin: 2.3125rem 0 1.5rem 0;

  .label {
    position: absolute;
    top: -2rem;
    left: auto;
    max-width: 100%;
    height: 2.7em;
    line-height: 1.33;
    color: #2d2d2d;
    font-size: 18px;
    cursor: text;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    -webkit-transition: all .2s;
    transition: all .2s; /* z-index: -1; */
    pointer-events: none;
    -webkit-font-smoothing: antialiased;
    -webkit-transform: translate3d(0, 3px, 0) scale(1);
    transform: translate3d(0, 3px, 0) scale(1);
    -webkit-transform-origin: left top;
    transform-origin: left top;
    padding: 0.5rem;

    &.focus {
      color: #007ede;
      top: -1.5rem;
      left: auto;
      font-size: 12px;
      line-height: 1.33;
      padding: 0;
    }

    &.active {
      top: -1rem;
      left: auto;
      font-size: 12px;
      line-height: 1.33;
      padding: 0;
    }
  }

  .search {
    position: absolute;
    max-width: 100%;
    line-height: 1.33;
    color: #2d2d2d;
    font-size: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    -webkit-transition: all .2s;
    transition: all .2s; /* z-index: -1; */
    -webkit-font-smoothing: antialiased;
    -webkit-transform: translate3d(0, 3px, 0) scale(1);
    transform: translate3d(0, 3px, 0) scale(1);
    -webkit-transform-origin: left top;
    transform-origin: left top;
    left: -2rem;
    top: 0;
    cursor: pointer;

    &.active, &.focus {
      left: -2rem;
      top: 0;
      color: #007ede;

    }
  }

  .close {
    position: absolute;
    max-width: 100%;
    line-height: 1.33;
    color: #2d2d2d;
    font-size: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    -webkit-transition: all .2s;
    transition: all .2s; /* z-index: -1; */
    -webkit-font-smoothing: antialiased;
    -webkit-transform: translate3d(0, 3px, 0) scale(1);
    transform: translate3d(0, 3px, 0) scale(1);
    -webkit-transform-origin: left top;
    transform-origin: left top;
    right: -2rem;
    top: 0;
    cursor: pointer;
  }

  .input {
    background-color: #F4F4F4;
    padding: 0.5rem;
    display: block;
    width: 100%;
    color: #2d2d2d;
    border: 0.5px solid #8c8c8c;
    box-sizing: border-box;
    border-radius: 1rem;

    height: 10rem;
    line-height: 1.33;
    font-size: 18px;
    resize: none;

    &.focus {
      outline: 0;
      border-color: #0072de;
      border-width: 1px;
      color: #0072de;
    }
  }

  .error {
    font-size: 12px;
    color: #db332a;
    margin-top: 6px;
    display: block;

    p {
      display: block;
    }
  }
}