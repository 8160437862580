.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 20;
  background-color: rgba(182, 181, 181, 0.3);
}

.bodyLayout {
  position: fixed;
  z-index: 25;
  top: 30%;
  left: 20%;
  width: 60%;
  background-color: #333333;
  color: white;
  display: inline-flex;
  border: 0.1rem solid #333333;

  .input {
    width: 60%;
    display: inline-flex;
    flex-direction: column;
    background-color: white;
    color: #1e1e1e;

    label {
      font-size: 1.5rem;
      font-weight: var(--fw-semi-bold);
      margin-top: auto;
      margin-bottom: auto;
    }

    .title {
      padding: 1rem;
      display: inline-flex;

      .icon {
        font-size: 2rem;
        margin-right: 2rem;
        cursor: pointer;
      }
    }

    .body {
      padding: 3rem 4rem;

      .dimensions {
        select {
          border: 0.1rem solid black;
        }

        .inputJoint {
          select {
            background-color: #f5f5f5;
          }
        }
      }

      .numbers {
        .inputCoverage {
          grid-area: input-coverage;
          width: 100%;
          place-self: center;
        }

        .inputClassName {
          border: 0.1rem solid black;
          background-color: #f5f5f5;
          font-weight: var(--fw-medium);
          font-size: 1.5em;
          text-indent: 1rem;
          margin: auto;

          &::placeholder {
            padding-left: 0.1rem;
            color: black;
            font-weight: var(--fw-regular);
          }
        }
      }
    }
  }

  .output {
    width: 40%;
    text-align: center;

    .title {
      padding: 1rem;

      label {
        font-size: 1.5rem;
        font-weight: var(--fw-semi-bold);
        margin-top: auto;
        margin-bottom: auto;
      }
    }

    p {
      font-size: 1rem;
      font-weight: var(--fw-semi-bold);
      margin-top: auto;
      margin-bottom: auto;
    }

    h1 {
      font-size: 2.25rem;
      font-weight: 700;
      margin-top: auto;
      margin-bottom: auto;
      padding: 0.5rem;
    }

    img {
      width: 100%;
      height: 3rem;
      margin: 1rem 0;
    }

    .body {
      padding: 1rem 3rem;
    }

  }
}

