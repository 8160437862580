@use '../../../styles/abstracts/mixins';

.manufacturingHeader{
  @include mixins.laptop {
    padding: 0 10rem;
  }
}
.layout {
  padding: 0 10rem;
  @include mixins.phone {
    padding: 0 2rem;
  }

  .banner {
    width: 100%;
    height: 35rem;
  }

  .caption {
    font-weight: var(--fw-semi-bold);
  }

  .filter {
    padding: 1rem;
    text-align: center;
    @include mixins.phone{
      padding: 1rem 0 1rem 0;
    }

    .title {
      font-size: 3.5rem;
      font-weight: var(--fw-semi-bold);
      margin-bottom: 1.5rem;
      @include mixins.phone{
        font-size: 1.5rem;
      }
    }

    .description {
      font-size: 1.5rem;
      font-weight: var(--fw-medium);
      margin-bottom: 1.5rem;
      padding: 0 4rem;
      @include mixins.phone{
        font-size: 1rem;
        padding: 0;
      }
    }

    .input {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0 2rem;
      width: 70%;
      @include mixins.phone{
        width: 100%;
        flex-direction: column;
        padding: 0;
      }

      .search {
        width: 45%;
        margin: 1rem 0;
        @include mixins.phone{
          width: 100%;
          margin: 0;
          height: 2.5rem;
        }

        input{
          @include mixins.phone{
            font-size: 1rem;
          }
        }
      }
      .dropdown{
        width: 50%;
        @include mixins.phone{
          width: 100%;
          display: block;
        }

        label{
          @include mixins.phone{
            display: none;
          }
        }

        select{
          @include mixins.phone{
            font-size: 1rem;
            height: 2.5rem;
          }
        }
      }

      .wrapperClassName{
        @include mixins.phone{
          &:after {
            font-weight: var(--fw-semi-bold);
            content: "▼";
            pointer-events: none; /* ▼ click triggers dropdown */
            position: absolute;
            right: 0.8rem;
            top: .5rem;
            cursor: pointer;
          }
        }
      }

    }
  }

  .body {
    min-height: 20rem;
    padding: 1rem 2rem;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(285px, 1fr));
    column-gap: 2rem;
    row-gap: 2rem;
    @include mixins.phone{
      padding: 0;
      row-gap: 1rem;
    }

    .subSegmentItem {
      background-size: cover;
      min-height: 20rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background: #F4F4F4;

      img {
        height: 60%;
      }

      .noImage {
        margin: auto;
        width: 12rem;
        height: 12rem;
      }

      .details {
        height: 40%;
        padding: 0.5rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        h1 {
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: var(--fs-500);
          font-weight: var(--fw-semi-bold);
        }


        h2 {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: var(--fs-400);
          line-height: 140%;
        }

        p {
          font-size: var(--fs-100);
          text-align: right;
          color: var(--link);
          text-decoration: underline;
        }

      }

      &:hover {
        transform: translateY(-0.188rem) scale(1.03);
        box-shadow: 0 0.313rem 0.938rem rgba(0, 0, 0, 0.35);
        //border: 0.1rem solid;
        border-image-slice: 1;
        border-image-source: linear-gradient(#00b5ee, #0937ad);

        h1, h2, p {
          color: #1976d2;
        }

        p {
          text-decoration: underline;
        }
      }
    }
  }
  .pagination {
    grid-column: 2;
    grid-row: 2;
    @include mixins.phone{
      margin: 1rem 0 2rem 0;
    }
  }
  .adRobotLayout {
    display: flex;
    flex-direction: column;
    justify-content: start;
    gap: 1rem;
    margin-bottom: 2rem;

    h1 {
      font-size: 2.5rem;
      font-weight: var(--fw-semi-bold);
      text-align: left;
      @include mixins.phone{
        font-size: 1.5rem;
        text-align: center;
      }
    }

    p {
      font-size: 1.25rem;
      text-align: left;
      @include mixins.phone{
        font-size: .8rem;
        text-align: center;
      }
    }

    .bDetails {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      background-color: #F4F4F4;
      @include mixins.phone{
        flex-direction: column;
      }

      img{
        width: 50%;
        @include mixins.phone{
          width: 100%;
        }
      }
      .bRight {
        padding: 3rem 2rem;
        display: flex;
        flex-direction: column;
        justify-content: start;
        gap: 1rem;
        @include mixins.phone{
          padding: 1rem;
          align-items: center;
        }

        p {
          @include mixins.phone{
            font-size: .8rem;
          }
        }

        .imgContainer {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #F4F4F4;

          img {
            width: 50%;
            object-fit: cover;
            overflow: hidden;
          }
        }

        .iconContainer {

          .btnLink {
            margin: 0 auto;

            .btn {
              background: black;
              border-color: black;
              border-radius: 1rem;
              height: 3rem;
              width: 6rem;

              .icon {

              }
            }
          }
        }
      }
    }
  }

  .contactLayout {
    width: 100%;
    padding: 1rem 0 2rem 0;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;

    h1 {
      margin-bottom: 1rem;
      font-size: 1.5rem;
      font-weight: var(--fw-semi-bold);
      text-align: center;
      @include mixins.phone{
        font-size: 1rem;
      }
    }

    .btnLink {
      margin: 0 auto;

      .btn {
        border-radius: 1.5rem;
        height: 3rem;
      }
    }
  }
}