@use '../../../styles/abstracts/mixins';

.carousel {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  width: 100%;

  .slider {
    position: relative;
    width: 100%;
    margin: 0 auto;

    .slide {
      position: relative;

      h1 {
        position: absolute;
        z-index: 1;
        top: 25em;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
      }

      .slideImg {
        margin: auto;
        display: block;
        width: 100%;
        object-fit: cover;
        box-sizing: border-box
      }
    }
  }

  .btnLayout {
    position: absolute;
    display: inline-flex;
    justify-content: space-between;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    padding: 0 1rem;
    @include  mixins.tablet{
      display: none;
    }
    @include  mixins.phone{
      display: none;
    }

    .btn {
      z-index: 2;
      width: 3rem;
      height: 3rem;
      margin: 0;
      padding: 0;
      border: 0;
      border-radius: 15%;

      .icon {
        font-size: 3rem;
        color: var(--clr-grey-400);
      }
    }

    .btnBack {
      left: 0;
    }

    .btnNext {
      left: 100%;
    }

    button {
      background-color: var(--clr-grey-800);
    }

    button[disabled] {
      opacity: 0;
    }
  }

  .pickerDots {
    padding-top: 0.5rem;
    display: inline-flex;
    justify-content: center;
    gap: 0.5rem;
    @include  mixins.tablet{
      display: none;
    }
    @include  mixins.phone{
      display: none;
    }


    .icon {
      width: 1rem;
      height: 1rem;
      cursor: pointer;
      border-radius: 50%;
      border-width: 0;
    }

    button {
      background-color: var(--clr-grey-200);
    }

    button[disabled] {
      opacity: 1;
      background-color: black;
    }
  }
}