@use '../../../styles/abstracts/mixins';

.container {
  padding: 0 5rem;
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  margin-bottom: 1rem;
  @include mixins.maxmq(xl) {
    padding: 0 2rem;
  }

  .header {
    margin-top: 1rem;
    text-align: center;
    font-size: 1.8rem;

    @include mixins.phone{
      font-size: .75rem;
    }

    .link {
      text-decoration: underline;
      color: #007AFF;
    }
  }

  .btn {
    display: none;
    width: 100%;
    height: 3rem;
    font-size: 1rem;
    margin-bottom: .5625rem;
    margin-top: 1.5625rem;
    background: var(--clr-grey-100);
    border: none;
    color: black;
    box-shadow: none;
    @include mixins.phoneAndTablet{
      display: block;
    }
  }

  .body {
    display: inline-flex;
    margin-top: 1rem;
  }
}
