@use '../../styles/abstracts/mixins';

.layout {
  .headerLayout {
    padding: 4rem 10rem;
    @include mixins.maxmq(xl) {
      padding: 1rem 2rem;
    }

    .title {
      font-weight: var(--fw-semi-bold);
      font-size: 3rem;
      line-height: 4rem;
      text-transform: uppercase;
      color: #007AFF;
    }

    form {
      margin-top: 2rem;
      display: flex;
      flex-direction: column;
      gap: 2rem;

      .searchLayout {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        width: 100%;
        @include mixins.maxmq(xl) {
          flex-direction: column;
        }

        .searchInput {
          width: 100%;
          margin: 0;
          @include mixins.maxmq(xl) {
            width: 100%;
          }
        }

        .btn {
          width: 20%;
          @include mixins.maxmq(xl) {
            width: 100%;
          }
        }
      }

      .label {
        font-size: 1.5rem;
      }

      .filter {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        margin-top: 1rem;
        @include mixins.maxmq(xl) {
          flex-direction: column;
        }

        .dropDown {
          width: 100%;
        }

      }

      .selectedFiltersLayout {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 1rem;

        p {
          white-space: nowrap;
        }

        .selectedFilters {
          display: flex;
          flex-direction: row;
          gap: 1rem;
          overflow-x: auto;

          .element {
            display: flex;
            flex-direction: row;
            gap: 0.5rem;
            border: 1px solid #B4B4B4;
            border-radius: 3px;
            padding: 0.5rem;
            font-size: 1.2rem;
            line-height: 1.5rem;

            p {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;

            }

            .icon {
              font-size: 1.2rem;
              margin: auto;
              cursor: pointer;
            }

          }

        }

        .clear {
          color: #007AFF;
          font-size: 1.5rem;
          line-height: 1.75rem;
          text-align: right;
          text-decoration-line: underline;
          cursor: pointer;
        }

      }

      .applyBtn {
        width: 12rem;
        height: 4rem;
      }

    }
  }

  .resultLabel {
    padding: 2rem 10rem;
    background: #F4F4F4;
    @include mixins.maxmq(xl) {
      padding: 1rem 2rem;
    }

    h1 {
      color: #007AFF;
      width: 100%;
      text-transform: uppercase;
      font-size: 2rem;
    }

    .resultTabMenu {
      margin-top: 1rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .tab {
        cursor: pointer;

        p {
          font-weight: var(--fw-semi-bold);
          font-size: 1.5rem;
          color: black;
        }
      }
    }

  }

  .searchResult {
    padding: 4rem 10rem;
    @include mixins.maxmq(xl) {
      padding: 1rem 2rem;
    }
    min-height: 20rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .productContainer {
      display: flex;
      flex-direction: row;
      border: 1px solid #F4F4F4;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);

      @include mixins.maxmq(xl) {
        flex-direction: column;
      }

      &:hover {
        border: 1px solid #007AFF;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
      }


      .img {
        height: 15rem;
        background: #B4B4B4;
        @include mixins.maxmq(xl) {
          width: 100%;
          height: 100%;
        }
      }

      .detailsContainer {
        padding: 1rem;
        width: 80%;
        display: flex;
        flex-direction: column;
        @include mixins.maxmq(xl) {
          width: 100%;
        }

        .title {
          position: relative;

          h1 {
            font-size: 1.8rem;
            font-weight: var(--fw-semi-bold);
          }

          h2 {
            margin: 0.5rem 0;
            font-size: 1rem;
          }

          p {
            margin: 1.2rem 0;
            font-size: 0.875rem;
          }

        }

        .features {

          ul {
            margin-bottom: 0.5rem;
            padding-left: 1.6rem;
            list-style-type: disc;
          }

          li {
            font-size: 0.875rem;
            line-height: 130%;
            list-style: unset;
          }
        }
      }
    }

    .document {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border: 1px solid #F4F4F4;
      padding: 1rem;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);

      @include mixins.maxmq(xl) {
        flex-direction: column;
      }

      &:hover {
        border: 1px solid #007AFF;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
      }

      h1 {
        font-size: 1.8rem;
        font-weight: var(--fw-semi-bold);
      }

      p {
        margin: 1.2rem 0;
        font-size: 1.4rem;
      }

      .icon {
        font-size: 3.5rem;

        &:hover {
          color: #007AFF;
          font-size: 3.55rem;
        }
      }
    }

    .linkContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      border: 1px solid #F4F4F4;
      padding: 1rem;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);

      @include mixins.maxmq(xl) {
        flex-direction: column;
      }

      &:hover {
        border: 1px solid #007AFF;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
      }

      .img_overview {
        width: 20%;
        height: 15rem;
        object-fit: contain;
        @include mixins.maxmq(xl) {
          width: 100%;
          height: 100%;
        }
      }

      .details {
        padding: 1rem;
        width: 80%;
        display: flex;
        flex-direction: column;

        h1 {
          font-size: 1.8rem;
          font-weight: var(--fw-semi-bold);
        }

        p {
          margin: 1.2rem 0;
          font-size: 0.875rem;
          color: #4F4F4F;
        }

      }
    }

    .loadBtn {
      width: 12rem;
      height: 4rem;
      margin: 2rem auto;
    }

  }
}