@use '../../../styles/abstracts/mixins';

.loading {
  display: flex;
  justify-content: center;
  margin-top: 4rem;
}
.carousel{
  padding: 0 10rem 0 10rem;
  @include mixins.phone{
    padding: 0;
  }
}
.layout {
  font-size: 1.6rem;
  color: var(--text);
  background: var(--bg);
  padding: 0 10rem 2rem 10rem;
  @include mixins.maxmq(xl) {
    padding: 0 1.5rem;
  }

  .Tree {
    margin-top: 0.5rem;
    @include mixins.phone{
      display: none;
    }
  }

  .title {
    padding: 2rem 0 1rem 0;
    font-size: 3rem;
    font-weight: var(--fw-semi-bold);
    line-height: 125%;
    text-align: center;
    align-self: center;
    text-transform: uppercase;
    @include mixins.phone{
      font-size: 1.875rem;
      padding: 1rem 0 .5rem 0;
    }
  }

  .description {
    text-align: center;
    width: 100%;
    margin-bottom: 2rem;
    font-size: 2rem;
    line-height: 125%;
    @include mixins.phone{
      font-size: .875rem;
      text-align: justify;
    }

    .seenMoreButton{
      color: #007AFF;
      text-decoration: underline;
    }
  }

  .center {
    width: 100%;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include mixins.phone{
      margin-bottom: 0;
    }

    a {
      @include mixins.phone{
        width: 100%;
      }
      .btn {
        border-radius: 1.5rem;
        height: 3rem;
        @include mixins.phone{
          font-size: 1.3rem;
          border-radius: .5rem;
          width: 100%;
        }
      }
    }

    h2 {
      margin-top: 4rem;
      font-size: 2.1rem;
      line-height: 125%;
      text-transform: uppercase;
      @include mixins.phone{
        font-size: 1rem;
        text-align: center;
        margin-top: 1rem;
      }

    }

    hr {
      width: 8rem;
      background-color: var(--clr-grey-500);
      border: none;
      height: 0.1rem;
      margin: 1rem 0 2rem 0;
      @include mixins.phone{
        width: 4rem;
        height: 0.05rem;
        margin: .5rem 0 1rem 0;
      }
    }

    .inputFile {
      width: 100%;
      display: flex;
      gap: 2rem;
      justify-content: center;
      align-items: center;
      @include mixins.phone{
        flex-direction: column;
        gap: 0;
      }

      .inputOverlay{
        width: 50%;
        @include mixins.phone{
          width: 100%;
          margin: 1.5rem 0 .8rem 0;
        }
        input {
          background-color: transparent;
          border-radius: .2rem;
          font-size: 1.5rem;
          @include mixins.phone{
            font-size: 1rem;
          }
        }
      }

      .labelclass{
        font-size: 1.5rem;
        top: -2rem;
        @include mixins.phone{
          font-size: .875rem;
          top: -1rem;
        }

      }

      .sds {
        position: absolute;
        right: 2.5rem;
        margin-top: 1rem;
        font-size: 20px;
        color: #555;
        z-index: -1;
      }

      .btn{
        width: 12%;
        @include mixins.phone{
          width: 100%;
        }
      }
    }
    .libraryDocuments{
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 1rem;
      @include mixins.phone{
        margin-top: 1rem;
      }
      .document {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border: 1px solid #F4F4F4;
        padding: 1rem;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);

        @include mixins.phone{
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          padding: .5rem;
        }

        &:hover {
          border: 1px solid #007AFF;
          box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
          border-radius: 5px;
          @include mixins.phone{
            border: none;
            box-shadow: none;
            border-radius: 0;
          }
        }

        h1 {
          font-size: 1.8rem;
          font-weight: var(--fw-semi-bold);
          @include mixins.phone{
            font-size: .75rem;
          }
        }

        p {
          margin: 1.2rem 0;
          font-size: 1.4rem;
          @include mixins.phone{
            margin: 0;
          }
        }

        a{
          @include mixins.phone{
            width: fit-content;
          }
        }
        .icon {
          font-size: 3.5rem;
          @include mixins.phone{
            font-size: 2rem;
            color: #007AFF;
          }

          &:hover {
            color: #007AFF;
            font-size: 3.55rem;
            @include mixins.phone{
              font-size: 2rem;
            }
          }
        }
      }
    }

    .noResult{
      font-size: 1.5rem;
      @include mixins.phone{
        font-size: 1rem;
        margin-top: 1rem;
      }
    }

    .loadBtn {
      width: 12rem;
      height: 4rem;
      margin: 2rem auto;
      p{
        font-weight: var(--fw-semi-bold);
      }
      @include mixins.phone{
        width: 100%;
        height: fit-content;
        padding: .5rem 0;
        margin: 1rem auto;
      };
    }
  }
  .accordionLayout{
    @include mixins.phone{
      margin: 0 0 1rem 0;
    }

    .library {
      padding: 1rem 1rem 1rem 2rem;
      @include mixins.phone{
        padding: 0;
      }

      .content {
        display: flex;
        justify-content: space-between;
        white-space: nowrap;
        .text {
          @include mixins.phone{
            width: 90%;
            overflow: hidden;
          }
          h3 {
            color: #007AFF;
            font-size: 2rem;
            font-weight: var(--fw-semi-bold);
            white-space: normal;
            @media only screen and (max-width: 1346px)  {
              font-size: 1.7rem;
            }
            @include mixins.phone{
              font-size: 1rem;
            }
          }

          p {
            font-size: 1.5rem;
            white-space: normal;
            @media only screen and (max-width: 1346px)  {
              font-size: 1.2rem;
            }
            @include mixins.phone{
              font-size: .75rem;
            }
          }
        }

        .icon {
          font-size: 4rem;
          color: #007AFF;
          @include mixins.phone{
            font-size: 2rem;
          }
        }
      }

      hr {
        margin: 0.5rem 0;
      }

    }
    .iconPlusMinus{
      @include mixins.phone{
        top: .5rem !important;
        right: .5rem !important;
      }
    }
    .accordion{
      h1{
        @include mixins.phone{
          font-size: 1rem;
        }
      }
    }
    .headerClass{
      @include mixins.phone{
        margin: 0.3rem;
      }
    }
  }

  .contactLayout {
    width: 100%;
    padding: 2rem 0;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    @include mixins.phone{
      display: none;
    }

    h1 {
      margin-bottom: 1rem;
      font-weight: var(--fw-semi-bold);
      text-align: center;
    }

    .btnLink {
      margin: 0 auto;

      .btn {
        border-radius: 1.5rem;
        height: 3rem;
      }
    }
  }

}
