@use '../../../../styles/abstracts/mixins';

.sidebarContainer {
  width: 20rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @include mixins.maxmq(lg) {
    display: none;
  }

  .btn {
    width: 100%;
    margin-bottom: 2rem;
  }
}
