@use '../../../styles/abstracts/mixins';

.header{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
  @include mixins.phone{
    margin-top: 1rem;
  }

  .title{
    font-size: 2.5rem;
    font-weight: var(--fw-semi-bold);
    color: #007AFF ;
    width: 100%;
    text-align: center;
    @include mixins.phone{
      font-size: 1.5rem;
    }
  }

  .description{
    font-size:1.8rem ;
  }

  .subTitle{
    font-size: 1.75rem;
    margin: 1rem 0;
    width: 100%;
    @include mixins.phone{
      font-size: 1rem;
      margin: 0 0 1rem 0;
    }
  }
  form{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 2rem;
    margin: 2rem 0;
    @include mixins.phone{
      flex-direction: column;
      align-items: flex-start;
      margin: 1rem 0;
      gap: 1rem;
    }

    .searchLayout {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      width: 50%;
      @include mixins.phone{
        width: 100%;
      }

      .searchInput {
        width: 100%;
        margin: 0;
        @include mixins.phone{
          width: 100%;
          height: 2.6rem;
        }
      }
      .inputclass{
        font-size: 1.4rem;
        @include mixins.phone{
          font-size: 1rem;
        }
      }
      .searchClass{
        @include mixins.phone{
          font-size: 2rem;
          top: .3rem;
        }
      }

      .btn {
        width: 20%;
        @include mixins.phone{
          width: 50%;
        }
      }
    }
    .buttons{
      width:15% ;
      @include mixins.phone{
        display: inline-flex;
        justify-content: space-between;
      }

      @include mixins.phone{
        width: 100%;
      }

      .applyBtn{
        width:100% ;
        @include mixins.phone{
          width: 49%;
        }
      }
      .clearBtn{
        display: none;
        @include mixins.phone{
          display: block;
          width: 39%;
          background: #F4F4F4;
          border-color: #F4F4F4;
          color: black;
        }
      }
    }
    .viewButtons{
      @include mixins.phone{
        display: flex;
        justify-content: center;
        column-gap: 3rem;
        width: 100%;
        border-bottom: 1px solid black;
        p{
          font-weight: var(--fw-semi-bold);
          font-size: 1.1rem;
        }
      }
      .active{
        color: #007AFF;

      }
    }
  }
}

.container {
  width: 100%;
  height: 40rem;
  display: inline-flex;
  flex-direction: row-reverse;
  @include mixins.phone {
    flex-direction: column;
    height: 60rem;
    gap: 1rem;
  }
  .reducedContainer{
      height: 40rem !important;
  }
  .expandSubContainer{
      height: 100% !important;
  }

  .map {
    width: 59%;
    height: 100%;
    @include mixins.phone {
      width: 100%;
      height: 40%;
    }

    .icon {
      font-size: 1.4rem;
    }

    .infoWindow{
      display: flex;
      flex-direction: column;
      width: 20rem;
      gap: .5rem;
      @include mixins.phone{
        width: 15rem;
      }
      .title{
        font-weight: var(--fw-semi-bold);
        font-size: 1rem;
        @include mixins.phone{
          font-size: .8rem;
        }
      }
      p{
        font-size: .8rem;
        @include mixins.phone{
          font-size: .6rem;
        }
      }
      .links{
        color: #0072de;
        cursor: pointer;
        text-decoration: underline;
      }
      .Website{
        background: #F4F4F4;
        color: black;
        width: 55%;
        height: 2rem;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        border: .1rem solid black;
        font-size: .8rem;
        @include mixins.phone{
          width: 65%;
          font-size: .6rem;
        }
      }
    }
  }

  .subContainer{
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 2rem 0 2rem;
    background: #F4F4F4;
    gap: 1rem;
    @include mixins.phone{
      width: 100%;
      height: 60%;
      padding: .1rem 0;
    }

    > div:nth-child(-n+2) {
      display: flex;
      justify-content: flex-start;
    }

    > div:last-child {
      margin-top: auto;
    }

    .searchContainer{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center !important;
      background: white;
      padding: 0.5rem 0;
      width: 100%;
      @include mixins.phone{
        width: 90%;
        align-items: center;
        justify-content: center;
        margin: .5rem;
      }

      .search{
        width:95% ;
        @include mixins.phone{
          margin-bottom: 1rem;
        }
        .searchInputName {
          width: 100%;
          margin: 0;
          font-size: 1.4rem;
          background-color: #FFFFFF;
          @include mixins.phone{
            width: 100%;
            height: 2.6rem;
            font-size: 1rem;
          }
        }

      }
    }
    .noResult{
    margin: 1rem 0;
    font-size: 1.3rem;
    text-align: center;
    font-weight: var(--fw-semi-bold);
    @include mixins.phone{
      font-size: 1rem;
    }
  }
    .list {
    overflow: scroll;
    overflow-x: hidden;
    display: inline-flex;
    flex-direction: column;
    width: 100% !important;
    gap: .4rem;
    @include mixins.phone{
      gap: .1rem;
    }

    .address {
      display: inline-flex;
      flex-direction: column;

      .continue {
        width: 100%;
        padding: 0.3rem 2rem;
        display: flex;
        flex-direction: column;
        cursor: pointer;
        background: #ffffff;
        z-index: 2;
        gap: .1rem;
        @include mixins.phone{
          padding: 0.2rem .5rem 1rem .5rem;
        }

        .title{
          display: inline-flex;
          justify-content: space-between;

          .description {
            width: 80%;
            font-size: 1.6rem;
            font-weight: var(--fw-semi-bold);
            padding-right: 1rem;
            @include mixins.phone{
              font-size: 1rem;
            }
          }

          .distance{
            display: inline-flex;
            text-align: center;

            .icon {
              font-size: 24px;
              margin-top: .5rem;
            }
            p{
              font-size: .85rem;
              margin-left: .2rem;
              display: flex;
              align-items: center;
              font-weight: var(--fw-medium);
            }
          }
        }
        .value {
          font-weight: var(--fw-semi-bold);
          @include mixins.phone{
            font-size: 0.875rem;
          }
        }
        .links{
          color: #0072de;
          cursor: pointer;
          text-decoration: underline;
        }
        .Website{
          background: #F4F4F4;
          color: black;
          width: 12rem;
          height: 2rem;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          border: .1rem solid black;
          font-size: 1rem;
          @include mixins.phone{
            width: 65%;
          }
        }
      }

      .selected{
        border: .3rem solid #007AFF;
      }
    }
  }
    .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 1rem;

    button {
      background-color: #007AFF;
      color: white;
      border: none;
      height: 2rem;
      margin: 0 0.5rem;
      cursor: pointer;
      transition: background-color 0.3s ease;
      font-size: 1rem;

      &:disabled {
        background-color: #b0b0b0;
        cursor: not-allowed;
      }

      &:not(:disabled):hover {
        background-color: #005bb5;
      }
    }

    span {
      font-size: .8rem;
      font-weight: var(--fw-semi-bold);
      color: #333;
    }
  }
}
}