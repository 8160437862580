@use "./media-query" as *;

@mixin minmq($key) {
	$size: map-get($breakpoints, $key);
	@media only screen and (min-width: $size) {
		@content;
	}
}
@mixin maxmq($key) {
	$size: map-get($breakpoints, $key);
	@media only screen and (max-width: $size) {
		@content;
	}
}
@mixin laptop {
	@media only screen and (min-width: 1069px) {
		@content;
	}
}

@mixin tablet {
	@media only screen and (min-width: 834px) and (max-width: 1068px) {
		@content;
	}
}

@mixin phone {
	@media only screen and (max-width: 833px)  {
		@content;
	}
}

@mixin phoneAndTablet {
	@media only screen and (max-width: 1068px) {
		@content;
	}
}