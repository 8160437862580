@use '../../../styles/abstracts/mixins';
@use "../../../styles/abstracts/media-query" as *;


.wrapper {
  &:before {
    background-color: #f4f4f4;

  }

  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
  min-width: 319px;
  flex-wrap: nowrap;

  header {
    display: block;
    background-color: #fff;

    .innerHeader {
      width: 100%;
      margin: 0 auto;
      box-sizing: border-box;

      .logo {
        background-color: #ffffff;
        margin: 1rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        a {
          margin: 0;
          padding: 0;
          font-size: inherit;
          line-height: inherit;

          img {
            height: 2rem;
          }
        }

        .rli {
          position: relative;
          vertical-align: middle;
          padding-top: 1rem;

          .icon {
            font-size: 24px;
            cursor: pointer;

          }

          p {
            display: inline-block;
            overflow: hidden;
            position: relative;
            padding: 0.5rem;
            border-radius: 1.5rem;
            color: #000;
            font-size: 14px;
            font-weight: var(--fw-semi-bold);
            line-height: 1.33;
            cursor: pointer;

            &.active {
              color: #fff;
              background-color: #000;
            }
          }
        }
      }
    }
  }

  main {
    flex-grow: 0;
    margin-bottom: -1px;
    padding: 1.5rem 0;

    .innerMain {
      max-width: 40rem;
      margin: 0 auto;
      @include mixins.minmq(lg) {
        background-color: #fcfcfc;
        border-radius: 1.625rem;
        padding: 3.75rem 4.5rem;
      }

      .container {
        border: none;
        background-color: transparent;
        margin-bottom: 0;
        @include mixins.minmq(lg) {
          padding: 0;
        }

        .title {
          h1 {
            margin: 0.75rem auto 3.4375rem;
            font-size: 1.6875rem;
            font-weight: 500;
            line-height: normal;
            text-align: center;
          }
        }

        .content {
          margin: 0 auto;

          .formWrapper {
            display: block;

            form {
              display: block;

              .emailConfirmation {
                max-width: 22.5rem;
                margin: 2.25rem auto 1.25rem;

                .emailConfirmation_btn {
                  text-align: center;
                  padding: 0;
                  margin: 0 3rem;

                  button {
                    width: 100%;
                    text-align: center;
                    vertical-align: middle;
                    font-size: 17px;
                    min-height: 2.75rem;
                    border-radius: 1.5rem;
                    margin: 0 auto;
                    line-height: 1.27;
                    letter-spacing: 0.01em;
                    word-wrap: break-word;
                    word-break: break-word;
                    position: relative;
                    display: block;
                    cursor: pointer;
                    font-style: inherit;
                    font-variant: inherit;
                    font-family: inherit;
                    text-decoration: none;
                    box-sizing: border-box;
                    -webkit-box-align: center;
                    -webkit-align-items: center;
                    align-items: center;
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;

                    background-color: #0381fe;
                    border: 1px solid #0381fe;
                    color: #fff;
                    @include mixins.minmq(lg) {
                      min-width: 14.75rem;
                    }

                    &.disable {
                      opacity: 0.4;
                    }
                  }

                }
              }
            }
          }
        }
      }
    }
  }

  footer {
    flex-shrink: 0;
    padding: 0.5em;
    margin-top: auto;
    text-align: inherit;
    background-color: #fff;

    .innerFooter {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin: 0 auto;
      font-size: 12px;
      max-width: 1116px;
      font-weight: 300;
      opacity: 0.8;

      .logoFooter {
        order: 1;
        width: 100%;
        flex: none;
        text-align: right;
        margin-top: 23px;
        margin-bottom: 6px;
        opacity: 1;

        img {
          width: 201px;
          height: auto;
          opacity: 1;
        }
      }

      .rightFooter {
        order: 3;
        width: auto;
        font-size: 12px;
        color: #888888;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        opacity: 1;
      }

      .linksFooter {
        order: 2;
        max-width: 650px;
        display: inline-block;
        margin-left: 0;
        margin-right: inherit;

        a {
          position: relative;
          text-decoration: none;
          display: inline-block;
          vertical-align: middle;
          font-size: 12px;
          font-weight: 500;
          color: #252525;
          padding: 2px 15px;
          opacity: 1;
        }
      }
    }
  }
}
