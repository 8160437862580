@use '../../../../../styles/abstracts/mixins';

.variantSelectorBody {
  max-width: 100%;
  margin-top: 1rem;
  padding: 0.5rem 0;
  display: flex;
  justify-content: flex-start;
  @include mixins.maxmq(lg) {
    padding: 0.5rem 0 0 0;
    .header {
      display: flex;
    }
  }

  h1 {
    font-size: 1.25rem;
    font-weight: var(--fw-semi-bold);
  }

  .itemOverView {
    width: 25%;
    @include mixins.maxmq(lg) {
      width: 30%;
      padding: 0.5rem 0;
    }
  }

  .detailSelector {
    width: 50%;
    height: 100%;
    transition: all 0.3s ease;
    overflow: auto;
    padding: 1rem;
    @include mixins.maxmq(lg) {
      padding: 0.5rem;
      width: 70%;
    }

    .priceLayout {
      display: grid;
      justify-content: flex-start;
      grid-template-columns: repeat(auto-fill, 15rem);
    }

    .formatButtons {
      display: grid;
      justify-content: flex-start;
      grid-template-columns: repeat(auto-fill, 10.5rem);

      .buttons {
        width: 10rem;
        margin: 1rem 1rem 0 0;
        font-size: 1.25rem;
      }
    }

    .minimumOrder {
      margin-top: 1rem;
      font-size: 1.25rem;
      display: inline-flex;
      color: var(--clr-red-600);
      box-shadow: 0 0 0 transparent;
      transition: box-shadow 0.5s;


      &.shakeError {
        padding: 0.5rem;
        animation: shake 0.2s ease-in-out 0s 2;
        box-shadow: 0 0 0.5em red;
      }
    }

    .qty {
      opacity: 0.3;
      pointer-events: none;
      margin-top: 1rem;

      h1 {
        font-size: 1.25rem;
        margin-top: auto;
        margin-bottom: auto;
      }

      &.active {
        opacity: 1;
        pointer-events: auto;
      }

      .qtyButtons {
        margin-top: 1rem;
        display: flex;
        justify-content: flex-start;

        .buttons {
          margin: auto 1rem auto 0;
          width: 2.5rem;
        }

        .textInput {
          font-size: 1.25rem;
          margin: auto 1rem auto 0;
          width: 3.5rem;
          height: 3.5rem;
        }

        .input {
          text-align: center;
        }
      }
    }

    .addCartButton {
      margin-top: 1rem;
    }

    .details {
      display: flex;
      flex-direction: column;
      margin-top: 1rem;
      font-size: 1.25rem;
      font-weight: var(--fw-regular);

      .disable {
        text-decoration: line-through;
        opacity: 0.7;
      }

      .link {
        cursor: pointer;
        color: var(--link);
        text-decoration: underline;
      }

      p {
        line-height: 130%;
        font-size: 1rem;
      }

      .price {
        font-weight: var(--fw-semi-bold);
        font-size: 1rem;
      }

      .customPrice {
        font-weight: var(--fw-semi-bold);
        color: var(--clr-red-700);
        font-size: 1.25rem;
      }

      .notAvailable {
        color: var(--clr-red-600);
        font-size: 1.25rem;
      }
    }

    .onhand {
      color: #28a745;
      font-size: 1.25rem;
      font-weight: var(--fw-regular);
      margin-top: 1.5rem;
    }
  }

  .calculatorContainer {
    padding: 2rem;
    margin-right: 2rem;
    width: 25%;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    align-items: center;
    text-align: center;
    @include mixins.maxmq(lg) {
      display: none;
    }

    color: white;
    background-color: #333333;

    .icon {
      font-size: 4rem;
    }

    h1 {
      font-size: 2vw;
      font-weight: var(--fw-semi-bold);
    }

    h3 {
      font-size: 1.25vw;
      font-weight: var(--fw-medium);
    }

    .buttons {
      background-color: white;
      font-size: 1.5vw;
    }

  }
}

@keyframes shake {
  0% {
    margin-left: 0;
  }
  25% {
    margin-left: 0.5rem;
  }
  75% {
    margin-left: -0.5rem;
  }
  100% {
    margin-left: 0;
  }
}

