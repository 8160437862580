@use "../../../styles/abstracts/variables" as *;

.layout {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.625rem 9.37rem;
    background-color: $color_primary;
    color: $color_neutral_light;

    @media only screen and (max-width: 1024px) {
        padding: 0.625rem 2.43rem;
    }

    @media only screen and (max-width: 425px) {
        padding: 0.625rem 0.93rem;
    }

    &.hidden{
        display: none;
    }

    .icon {
        cursor: pointer;
    }

    .message {
        display: flex;
        flex-direction: column;
        row-gap: 0.625rem;

        .buttons {
            display: flex;
            align-items: center;
            column-gap: 0.625rem;

            .link {
                color: $color_neutral_light;
                text-decoration: underline;
            }
        }
    }
}