@use '../../styles/abstracts/mixins';

.layout{
  margin: 2rem 6rem;
  display: flex;
  flex-direction: column;
  @include mixins.phone{
    margin: 1rem 1rem;
  }

  .title{
    font-size: 3.75rem;
    font-weight: var(--fw-semi-bold);
    color: #007AFF !important;
    margin-bottom: 2rem;
    @include mixins.phone{
      font-size: 2rem;
      margin: 0;
    }
  }

  .description{
    font-size:1.8rem ;
    margin-bottom: 2rem;
    @include mixins.phone{
      font-size: 1rem;
      margin: 0;
    }
    span{
      color: #007AFF ;
      cursor: pointer;
    }
  }

}