@use '../../../styles/abstracts/mixins';

.backdrop {
  @include mixins.phoneAndTablet {
    display: block;
    visibility: hidden;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 2000;
    background: #000;
    opacity: 0;

    &.open {
      visibility: visible;
      opacity: 0.68;
      transition: opacity 0.3s cubic-bezier(0.6, 0, 0.4, 1);
    }
  }
}

.wrapper {
  display: none;
  @include mixins.phoneAndTablet {
    display: flex;
    overflow: hidden;
    position: fixed;
    right: 0;
    transform: translateX(360px);
    top: 0;
    bottom: 0;
    width: 22.5rem;
    height: 100%;
    z-index: 2200;
    transition: transform 0.3s cubic-bezier(0.6, 0, 0.4, 1);
  }

  &.open {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    @include  mixins.phone{
      width: 100%;
    }
  }

  .main {
    @include mixins.phoneAndTablet {
      left: 0;
      transition: left 0.5s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.5s cubic-bezier(0.4, 0, 0.2, 1) 0.2s;
      opacity: 1;
      position: relative;
      overflow-x: hidden;
      overflow-y: auto;
      width: 100%;
      padding: 3.375rem 0 10rem;
      z-index: 2300;
      background-color: #F4F4F4;
    }

    .sub {
      float: none;
      font-size: 0;
      display: flex;
      flex-direction: column;

      ul {
        &:before {
          content: "";
          display: block;
          height: 1px;
          margin: 1rem 0;
          background: #ddd;
          opacity: 1;
          transform: translateX(0);
          transition: opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0.2s;
        }

        @include mixins.maxmq(xl) {
          display: block;
          visibility: hidden;
          width: 100%;
          height: auto;
          opacity: 0;
          transition: opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1);
          &.open {
            visibility: visible;
            opacity: 1;
            transition: opacity 0.5s cubic-bezier(0.4, 0, 0.2, 1) 0.2s;
          }
          .secHeader{
            background-color: #333333;
            span{
              color: white;
            }
          }
          .ContactUs{
            background-color: #007AFF;
            text-align: center;
            span{
              color: white;
              font-weight: var(--fw-semi-bold);
            }
          }

          li {
            border-bottom: .5px solid #B4B4B4;
            padding: 10px 1.5rem 10px 1.5rem;

            @include mixins.maxmq(xl) {
              display: table;
              width: 100%;
              height: auto;

              div {
                cursor: pointer;
              }

              .links {
                cursor: pointer;
                @include mixins.phoneAndTablet {
                  display: table-cell;
                  left: 0;
                  width: 100%;
                  height: 3.5rem;
                  padding: 0;
                  border-radius: 0;
                  color: #000;
                  font-weight: normal;
                  font-size: 25px;
                  vertical-align: middle;
                  opacity: 1;
                  transform: translateX(0);
                  transition: transform 0.5s cubic-bezier(0.6, 0, 0.4, 1), opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0.2s;

                  &:hover {
                    span {
                      font-weight: var(--fw-semi-bold);
                    }

                    text-decoration: underline;
                    text-underline-offset: 0.5rem;

                  }
                }

                .arrow {
                  display: block;
                  position: absolute;
                  right: 0;
                  top: 0;
                  margin: 1.25rem 0;
                  font-size: 24px;
                }
              }

              .account {
                &:before {
                  content: "";
                  display: block;
                  height: 1px;
                  margin: 1rem 0;
                  background: #ddd;
                  opacity: 1;
                  transform: translateX(0);
                  transition: transform 0.5s cubic-bezier(0.6, 0, 0.4, 1), opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0.2s;
                }

                .arrow {
                  display: block;
                  position: absolute;
                  right: 0;
                  top: 0;
                  margin: 2rem 0;
                  font-size: 24px;
                }
              }
            }
          }

        }
      }

      .title_wrapper {
        @include mixins.phoneAndTablet {
          visibility: visible;
          width: 21.25rem;
          padding: 0;
          display: table;
        }

        .title {
          @include mixins.phoneAndTablet {
            height: 3.375rem;
            padding: 0 0.5rem 0 1.5rem;
            font-size: 24px;
            display: table-cell;
            color: #000;
            line-height: 1.33;
            vertical-align: middle;
            .link {
              @include mixins.phoneAndTablet {
                display: inline-block;
                position: relative;
                color: #000;
                background-color: transparent;
                box-sizing: border-box;
                text-decoration: underline;
                text-underline-offset: 0.75rem;
                span {
                  font-weight: var(--fw-semi-bold);
                }
              }

            }
          }

        }
      }
    }
  }

  .iconClose {
    @include mixins.phoneAndTablet {
      &.open {
        display: block;
        opacity: 1;
      }

      position: absolute;
      cursor: pointer;
      top: 0.5rem;
      right: 1rem;
      font-size: 36px;
      z-index: 2300;
      opacity: 0;
      -webkit-transition: opacity .2s cubic-bezier(0.4, 0, 0.2, 1);
      transition: opacity .2s cubic-bezier(0.4, 0, 0.2, 1);
    }
  }

  .iconBack {
    @include mixins.phoneAndTablet {
      &.open {
        display: block;
        opacity: 1;
      }

      position: absolute;
      cursor: pointer;
      left: 1rem;
      top: 0.5rem;
      font-size: 36px;
      z-index: 2300;
      opacity: 0;
      -webkit-transition: opacity .2s cubic-bezier(0.4, 0, 0.2, 1);
      transition: opacity .2s cubic-bezier(0.4, 0, 0.2, 1);
    }
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #fff;
  }
}