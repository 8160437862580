.bold {
    font-weight: var(--fw-semi-bold);
}

.frameset{
    display: flex;
    flex-direction: column;
    row-gap: 1.25rem;

    .section {
        display: flex;
        flex-direction: column;
        row-gap: 1.25rem;

        .article {
            display: flex;
            column-gap: 1.25rem;
            
            .item { flex-grow: 1; }
        }
    }

    .footer {
        display: flex;
        flex-direction: row-reverse;
        column-gap: 1.25rem;
    }
}