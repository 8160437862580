.industriesContainer {
  width: 100%;
  font-size: 1.4rem;

  .accordionLayout {
    .header {
      margin: 0;
      padding: 1rem 0;
      border-top: 1px solid black;
      border-bottom: 1px solid black;

      h1 {
        padding-left: 1rem;
        font-size: 1.5rem;
      }

      .icon {
        top: 1rem;
        color: black;
      }
    }

    .list {
      color: black;

      .layout {
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        margin: 0;
        list-style: none;

        .element {
          display: flex;
          flex-direction: row;
          align-items: center;
          cursor: auto;
          width: 100%;

          input {
            margin: 0;
            appearance: auto;
            opacity: 1;
            width: 1rem;
            height: 1rem;
            background: #F4F4F4;
            border: 2px solid #007AFF;
            border-radius: 3px;
            cursor: pointer;
            transition: background-color 0.2s ease-in-out;
          }

          label {
            padding: 0;
            margin-left: 0.5rem;
            font-size: 1.2rem;
            line-height: 200%;

            //point when overflow and force only one line
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: black;
          }
        }

        .element.checked {
          label {
            color: #007AFF;
          }
        }
      }
    }
  }
}
