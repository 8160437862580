@use '../../../styles/abstracts/mixins';

.bannerContainer {
  position: relative;
  --text: var(--clr-grey-50);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 5rem;
  background-size: cover;
  @include mixins.maxmq(lg) {
    height: auto;
    padding: 15rem 6.25rem 10rem 6.25rem;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
  }

  .bannerLogo {
    position: relative;
    height: 20rem;
  }

  .bannerIntroduction {
    position: relative;
    font-size: 2rem;
    line-height: 3.5rem;
    text-align: center;
    color: var(--text);
  }

  .bannerCaption {
    position: relative;
    font-size: 3rem;
    margin: 2rem 0 1rem 0;
    line-height: 3.5rem;
    text-align: center;
    color: var(--text);
  }

  .bannerDescription {
    position: relative;
    font-size: 2rem;
    margin: 1rem 0;
    line-height: 3.5rem;
    text-align: center;
    color: var(--text);
  }
}
