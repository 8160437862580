.body {
  display: flex;
  list-style-type: none;
  justify-content: center;
  width: 100%;
  margin: 3rem 0 2rem 0;

  .icon {
    margin: auto 0;
    font-size: 2rem;
    cursor: pointer;
    color: #333333;
  }

  .icon.disabled {
    cursor: unset;
    color: #B4B4B4;
  }

  .item {
    width: 43px;
    text-align: center;
    margin: auto 0.375rem;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    align-items: center;
    line-height: 30px;
    font-size: 25px;
    font-weight: var(--fw-semi-bold);
    color: #B4B4B4;

    &.dots:hover {
      cursor: default;
    }

    &:hover {
      background-color: var(--clr-grey-150);
      cursor: pointer;
    }

    &.selected {
      color: #333333;
      border-bottom: 5px solid #007AFF;;
    }

    @media only screen and (max-width: 425px) {
      width: 30px;
      font-size: 20px;
    }
  }
}
