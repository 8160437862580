@use '../../../../../../styles/abstracts/mixins';

.colorCardContainer {
  width: 160px;
  margin: 25px 25px 0 0;
  cursor: pointer;
  position: relative;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  transition: all 0.2s ease-out;

  &.selected {
    margin: 5px 5px 0 0;
    width: 180px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  }

  &:hover {
    color: #007AFF;
    background: #fcfcfc;
    /*Elevation variables did not match with those set for the mockup*/
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
  }

  .favoriteOutlined,
  .favoriteFilled {
    position: absolute;
    z-index: 2;
    top: 8px;
    right: 8px;
    color: #d9d9d9;
    font-size: 25px;
    transition: all 0.5s ease-out;
  }

  .favoriteOutlined {
    opacity: 1;
  }

  .favoriteFilled {
    color: #c90909;
    opacity: 0;

    &:hover {
      opacity: 1;
    }

    &.active {
      opacity: 1;
    }
  }

  .colorCardImageImg {
    max-width: 120px;
    max-height: 120px;
    position: relative;

    &.selected {
      height: 100px;
    }
  }

  .colorCardImageDiv {
    width: 100%;
    height: 115.75px;
    position: relative;

    &.selected {
      height: 100px;
    }
  }

  .colorCardDetails {
    width: 100%;
    height: 45px;

    align-self: end;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem 1rem 2rem 1rem;
    &.selected {
      height: 100px;
    }

    p {
      font-size: 1rem;
      @include  mixins.phone{
        font-size: .7rem;
      }
      text-align: center;
    }
  }
}
