@use '../../styles/abstracts/mixins';

.imagLayout{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 10rem;
  @include  mixins.phone{
    padding: 0 ;
  }
  img {
    width: 100%;

  }
}
.title {
  padding: 2rem 0;
  font-weight: var(--fw-bold);
  font-size: 4.3rem;
  @include  mixins.tablet{
    font-size: 3.3rem;
  }
  @include  mixins.phone{
    font-size: 1.25rem;
    padding: 1.5625rem 0 0.625rem 0;

  }
  white-space: nowrap;
  text-align: center;
}
.layout {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 10rem;
  @include mixins.maxmq(xl) {
    padding: 0 2rem;
  }

  .description {
    padding-bottom: 4rem;
    font-size: 1.5rem;
    @include  mixins.tablet{
      font-size: 1.2rem;
    }
    @include  mixins.phone{
      font-size: .9rem;
      padding-bottom: 1.5625rem;
    }
    text-align: center;

    .more{
      color: #007AFF;
      text-decoration: underline;
    }
  }

  .produit {
    width: 100%;
    height: 100%;
    li {
      list-style: inherit ;
    }
    .loading {
      display: flex;
      justify-content: center;
      margin-top: 4rem;
      object {
        width: 15%;
        height: 15%;
      }
    }
  }
  .customPadding {
    padding: 0;
  }

  hr {
    margin: 0 0 1rem 0;
    color: var(--clr-grey-500);
    background-color: var(--clr-grey-500);
    border: none;
    height: 0.1rem;
  }
  .sectionColorTitle{
    margin-top: .5rem;
    font-size: 1rem;
    @include mixins.phone{
      font-size: .8rem;
    }
  }

  .CardContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(15.625rem, 1fr));
    grid-gap: 10px;
    width: 100%;
    height: 100%;
    @include  mixins.phone{
      grid-template-columns: repeat(auto-fill, minmax(8rem, 1fr));
      grid-gap: 10px ;
    }
  }

  .orderColorLayout {
    width: 100%;
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #F4F4F4;
    padding: 1rem;
    margin-bottom: 3rem;
    margin-top: 3rem;
    @include mixins.phoneAndTablet {
      padding: .5rem;
    }
    @include mixins.phone {
      padding: 0;
      margin-bottom: 1.5625rem;
      margin-top: 1.5625rem;
    }

    img {
      margin-left: 3.5rem;
      @include mixins.phoneAndTablet {
        margin: 0;
        width: 40%;

      }
    }

    .left {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 60%;
      padding: 2rem 4rem;
      @include mixins.phoneAndTablet {
        width: 100%;
        padding: .5rem;
      }

      p {
        width: 80%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin: 1rem 0;
        font-size: 1.8rem;
        @include mixins.tablet{
          font-size: 1.4rem;
        }
        @include mixins.phone{
          font-size: .625rem;
        }
      }

      .documentsButton {
        width: 100%;
        height: 5.7rem;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding: 0.5rem;
        border: 0.1rem solid;
        border-radius: 0.3rem;
        transition: ease-in-out 0.3s;
        text-align: center;
        &:hover {
          background-color: var(--clr-grey-100);
        }
        @include mixins.phone{
          width: 80%;
          height: auto;
          background: white;
        }
        p {
          width: 100%;
          font-size: 1.5rem;
          font-weight: var(--fw-medium);
          @include mixins.phone{
            margin: 0;
            font-size: 1rem;
          }
        }
      }
    }
  }

  .digitalColorLayout {
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #F4F4F4;
    padding: 1rem;
    margin-bottom: 3rem;
    @include mixins.phoneAndTablet {
      padding: .5rem;
    }
    @include mixins.phone {
      padding: 0;
      margin-bottom: 1.5625rem;
    }

    img {
      margin-left: 3.5rem;
      @include mixins.phone {
        margin: 0;
        width: 40%;
      }
    }

    .left {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 60%;
      padding: 2rem 4rem;
      @include mixins.phoneAndTablet {
        width: 100%;
        padding: .5rem;
      }
      p {
        width: 80%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin: 1rem 0;
        font-size: .625rem;
      }

      .documentsButton {
        display: flex;
        align-items: center;
        justify-content: center;
        width:100%;
        height: 9rem;
        cursor: pointer;
        flex-direction: row;
        padding: 0.5rem;
        border: 0.1rem solid;
        border-radius: 0.3rem;
        transition: ease-in-out 0.3s;
        text-align: center;
        @include mixins.phone{
          height: 8.5rem;
        }
        &:hover {
          background-color: var(--clr-grey-100);
        }
        @include mixins.phone{
          width: 80%;
          height: auto;
          background: white;
        }
        p {
          width: 100%;
          font-size: 1.3rem;
          font-weight: var(--fw-medium);
          @include mixins.phone{
            margin: 0;
            font-size: 1rem;
          }
        }
        .iconDownload {
          color: #007AFF;
          font-size: 3rem;
          margin-left: 3rem;
        }
      }
    }
  }

  .addToolsLayout {
    width: 100%;
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #F4F4F4;
    padding: 3rem;
    margin-bottom: 2rem;
    @include mixins.phone {
      flex-direction: column;
      margin-bottom: 1rem;
      padding: 2rem;
    }

    img {
      width: 50%;
      @include mixins.phoneAndTablet {
        width: 80%;
      }
    }

    .left {
      width: 50%;
      padding: 0 2rem;
      @include mixins.phoneAndTablet {
        width: 100%;
        padding: 2rem;
      }
      @include mixins.phone {
        padding: 1rem;
      }

      h1 {
        font-weight: var(--fw-semi-bold);
        @include mixins.phone {
          font-size: 1rem;
        }
      }

      p {
        margin: 1rem 0;
        font-size: 1rem;
        @include mixins.phone {
          font-size: .8rem;
        }
      }

      .btn {
        margin-top: 1rem;

        .icon {
          font-size: 4rem;
          @include mixins.phone {
            font-size: 3rem;
          }
        }
      }
    }
  }
  .links {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    @include mixins.phone{
      flex-direction: column;
      gap: 0;
    }

    .firstLine{
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .element {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      background: #F4F4F4;
      padding: 0.5rem;
      margin-bottom: 1rem;
      height: 15rem;
      width: 48%;
      object-fit: cover;
        @include mixins.phone{
          height: 10rem;
        }

      img{
        box-sizing: border-box;
        object-fit: cover;
        overflow: hidden;
        width: 7rem;
        @include mixins.phone{
          width: 5rem;
        }
      }


      .text {
        display: flex;
        align-items: center;
        width: 80%;
        p {
          text-transform: uppercase;
          font-size: 2rem;
          font-weight: var(--fw-semi-bold);
          @include mixins.phone{
            font-size: .8rem;
          }
        }
      }
    }
    }
    .secondLine{
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .element {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      background: #F4F4F4;
      padding: 0.5rem;
      margin-bottom: 1rem;
      height: 15rem;
      width: 48%;
      object-fit: cover;
        @include mixins.phone{
          height: 10rem;
        }

      img{
        box-sizing: border-box;
        object-fit: cover;
        overflow: hidden;
        width: 7rem;
        @include mixins.phone{
          width: 5rem;
        }
      }
        .imgSelector{
          margin-top: 1rem;
          min-height: 5rem;
          min-width: 12rem;
          @include mixins.phone{
            margin-top: 2rem;
            min-height: fit-content;
            min-width: fit-content;
          }
        }


      .text {
        display: flex;
        align-items: center;
        width: 80%;
        p {
          text-transform: uppercase;
          font-size: 2rem;
          font-weight: var(--fw-semi-bold);
          @include mixins.phone{
            font-size: .8rem;
          }
        }
      }
    }
    }
  }
  .titleColor{
    padding: 3.5rem 0 0 0;
    font-weight: var(--fw-semi-bold);
    font-size: 2.5rem;
    @include  mixins.tablet{
      font-size: 1.5rem;
      padding: 2.5rem 0 0 0;
    }
    @include  mixins.phone{
      font-size: 1rem;
      padding: 0;
    }
    text-align: center;
    color: #007AFF;
  }

  .descriptionColor {
    padding: 3.5rem 0 0 0;
    font-size: 1.5rem;
    text-align: left;
    @include  mixins.tablet{
      font-size: 1rem;
      padding: 2.5rem 0 0 0;
    }
    @include  mixins.phone{
      font-size: .8rem;
      padding: 1.5625rem 0 0 0;
    }
  }
}
