@use '../../../styles/abstracts/mixins';

.layout {
  width: 100%;

  .header {
    width: 100%;
    display: grid;
    grid-gap: 1%;
    grid-template-columns: repeat(4, 24.25%);
    justify-content: center;
    border-bottom: 2px solid black;
    border-top: 2px solid black;
    @include mixins.phone{
      display: none;
    }

    h1 {
      padding: 1rem 0;
      font-size: 1.5vw;
      font-weight: var(--fw-semi-bold);
      cursor: pointer;

      &:hover {
        background-color: #dfe9f1;
      }
    }
  }

  .body {
    width: 100%;
    padding: 2rem 0;
    display: grid;
    grid-column-gap: 10%;
    grid-row-gap: 2rem;
    grid-template-columns: repeat(2, 45%);
    justify-content: center;
    @include mixins.phone{
      padding: 1rem 0;
      display: flex;
      flex-direction: column;
      gap: 0;
    }

    .segment {

      h1, h2 {
        font-size: 1.4rem;
        font-weight: var(--fw-semi-bold);
        text-transform: uppercase;
        @include mixins.phone{
          font-size: 1rem;
        }
      }

      h1 {
        padding: 1rem;
        background-color: #F4F4F4;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        cursor: pointer;

        &:hover {
          background-color: #dfe9f1;
        }
      }

      p {
        font-size: 1.1rem;
        @include mixins.phone{
          font-size: .8rem;
        }
      }

      .content {
        padding: 1rem;

        .advElement {
          margin-top: 1rem;

          h2 {
            color: #007AFF;
          }
        }

        .advElement:nth-child(1) {
          margin-top: 0;
        }

        .element {
          padding: 0.3rem;
          display: flex;
          flex-direction: row;
          @include mixins.maxmq(lg) {
            flex-direction: column;
          }

          p {
            font-size: 1rem;
            @include mixins.phone{
              font-size: .8rem;
            }
          }

          p:nth-child(1) {
            width: 60%;
            @include mixins.maxmq(lg) {
              width: 100%;
              font-weight: var(--fw-semi-bold);
            }
          }

          p:nth-child(2) {
            width: 40%;
            @include mixins.maxmq(lg) {
              width: 100%;
            }
          }

          a {
            text-decoration-line: underline;
            color: #007AFF;
            @include mixins.phone{
              font-size: .8rem;
            }
          }
        }

        .element:nth-child(odd) {
          background-color: #F4F4F4;
        }

        ul {
          padding-bottom: 1rem;
          @include mixins.phone{
            padding-bottom: .5rem;
          }

          li {
            list-style: inside;
            line-height: 150%;
            font-size: 1.1rem;
            @include mixins.phone{
              font-size: .8rem;
            }
          }
        }

        .application {
          columns: 2;
          @include mixins.maxmq(xl) {
            columns: 1;
          }

          li {
            text-transform: uppercase;
            line-height: 150%;
            font-size: 1.1rem;
            @include mixins.phone{
              font-size: .8rem;
            }
          }
        }

        .include {
          padding: 1rem 0;
          text-transform: uppercase;
          @include mixins.phone{
            padding: .5rem 0;
          }
        }
      }
    }
  }
}