@use '../../styles/abstracts/mixins';
@use "../../styles/abstracts/media-query" as *;

.layout {
  margin: 2rem 9rem;
  display: flex;
  flex-direction: column;
  @include mixins.phone {
    margin: 1rem 1rem;
  }

  .Tree {
    font-size: 1.6rem;
    @include mixins.phone {
      display: flex;
      font-size: 1rem;
    }
  }

  .title {
    font-size: 2.5rem;
    font-weight: 800;
    color: #007AFF !important;
    margin-bottom: 2rem;
    margin-top: 4rem;
    @include mixins.tablet {
      font-size: 3rem;
    }
    @include mixins.phone {
      font-size: 1.5rem;
      margin: 1rem 0 0 0;
    }
  }

  .description{
    margin: 1rem 0;
    p{
      font-size: 1.5rem;
      @include mixins.phone{
        font-size: 1rem;
      }
    }
  }

  .dimensions {
    h2 {
      font-weight: var(--fw-semi-bold);
      font-size: 1.5rem;
      margin: 1rem 0;
      @include mixins.phone{
        font-size: 1.2rem;
        margin: .8rem 0;
      }
    }

    select {
      border: 0.1rem solid #007AFF;
    }

    .inputJoint {
      width: 50%;
      @include mixins.phone{
        width: 100%;
      }
      select {
        background-color: #f5f5f5;
        @include mixins.phone{
          font-size: 1.2rem;
        }
      }
      option{
        @include mixins.phone{
          font-size: 1.2rem;
        }
      }
    }

    .after {
      &:after {
        top: 1rem;
      }
    }

    .segment{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 1.5rem;
      @include mixins.phone{
        display: none;
      }

      .selectedCard{
        transform: translateY(-0.188rem) scale(1.03);
        box-shadow: 0 0.313rem 0.938rem rgba(0, 0, 0, 0.35);
        border: 0.3rem solid;
        border-image-slice: 1;
        border-image-source: linear-gradient(#00b5ee, #0937ad);
      }

      .segmentPicker {
        width: 23rem;
        height: 27rem;
        display: flex;
        flex-direction: row;


        .segmentPickerCard {
          cursor: pointer;
          width: 100%;
          height: 100%;
          overflow: hidden;
          position: relative;
          color: var(--text);
          display: inline-flex;
          flex-direction: column;


          .segmentPickerCardTitle{
            box-shadow: inset 0px 0px 6px 2px rgb(116 115 115 / 20%);
            p {
              margin: 0;
              height: 4.5rem;
              text-align: center;
              padding: 1rem 0.1rem;
              font-weight: 600;
              font-size: 1rem;
              @include mixins.phone {
                font-size: 1.25rem;
              }
            }
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            overflow: hidden;
            margin: auto 0 0 0;
            display: block;
            box-sizing: border-box;
          }

          transition: ease-in-out 0.1s;

          &:hover {
            transform: translateY(-0.188rem) scale(1.03);
            box-shadow: 0 0.313rem 0.938rem rgba(0, 0, 0, 0.35);
            border: 0.3rem solid;
            border-image-slice: 1;
            border-image-source: linear-gradient(#00b5ee, #0937ad);
          }
        }
      }
    }
  }

  .productDetail{
    width: 100%;
    h2 {
      font-weight: var(--fw-semi-bold);
      font-size: 1.5rem;
      margin: 1rem 0;
      @include mixins.phone{
        font-size: 1.2rem;
        margin: .8rem 0;
      }
    }
  }

  .loading {
    display: flex;
    justify-content: center;
    margin-top: 4rem;
    object {
      width: 15%;
      height: 15%;
    }
  }
}