@use '../../styles/abstracts/mixins';
@use "../../styles/abstracts/media-query" as *;

.banner {
  margin: 0 10rem;
  height: 30rem;
  background-size: cover; // Couvre la zone de contenu, mais conserve les proportions
  background-position: center; // Centre l'image horizontalement et verticalement
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  @include mixins.phone {
    margin: 0;
    background-size: 100% 100%;
    height: 7rem;
  }
}
.wrapper {
  padding: 0 10rem;
  @include mixins.phone {
    padding: 0 2rem;
  }

  display: flex;
  flex-direction: column;
  min-width: 319px;
  flex-wrap: nowrap;

  header {
    display: block;

    h1 {
      margin: 1rem auto;
      font-size: 1.6875rem;
      font-weight: 500;
      line-height: normal;
      text-align: center;
    }

  }

  main {
    flex-grow: 0;
    margin-bottom: -1px;

    .innerMain {
      max-width: 70rem;
      margin: 0 auto;
      @include mixins.minmq(lg) {
        padding: 1rem 3rem 3.75rem 3rem;
      }

      .container {
        border: none;
        background-color: transparent;
        margin-bottom: 0;
        @include mixins.minmq(lg) {
          padding: 0;
        }

        .title {
          img {
            height: 35rem;
          }

          h1 {
            margin: 1rem auto;
            padding: 1rem 0;
            font-size: 1.6875rem;
            font-weight: 600;
            line-height: normal;
            @include mixins.phone{
              font-size: 1.2rem;
              padding: 0;
            }

          }

          h2 {
            margin: 1rem auto;
            padding-top: 1rem;
            font-size: 1.6875rem;
            font-weight: 500;
            line-height: normal;
            text-align: center;
            @include mixins.phone{
              font-size: 1.2rem;
              padding-top: 0;
            }
          }

          p {
            margin: 1rem auto;
            @include mixins.phone{
              font-size: .8rem;
              margin: 0 0 1rem 0;
            }
          }
        }

        .content {
          margin: 0 auto;

          .formWrapper {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;

            form {
              display: block;

              fieldset {
                margin-inline-start: 2px;
                margin-inline-end: 2px;
                padding-block-start: 0.35em;
                padding-inline-start: 0.75em;
                padding-inline-end: 0.75em;
                padding-block-end: 0.625em;
                min-inline-size: min-content;

                display: grid;
                grid-template-columns: repeat(2, minmax(20rem, 1fr));
                column-gap: 1rem;
                row-gap: 1rem;
                @include mixins.maxmq(lg) {
                  grid-template-columns: repeat(1, minmax(17rem, 1fr));
                }

                .dropdown{
                  @include mixins.phone{
                    min-width: 100% !important;
                    font-size: 1rem;
                  }
                }


                .inputclass{
                  @include mixins.phone{
                    font-size: 1rem;
                  }
                }

                .inputLayout {
                  display: block;
                  position: relative;
                  z-index: 1;
                  margin: 2.3125rem 0 1.5rem 0;
                  height: 2.5rem;
                  @include mixins.phone{
                    margin: 1rem 0 0 0;
                    width: 100%;
                  }
                  input{
                    @include mixins.phone{
                      font-size: 1rem;
                    }
                  }
                }


              }
              .labelclass{
                top: -1.5rem ;
              }

              .text {
                padding-block-start: 0.35em;
                padding-inline-start: 0.75em;
                padding-inline-end: 0.75em;
                padding-block-end: 0.625em;
                @include mixins.phone{
                  margin: 1.5rem 0 1rem 0;
                }

                input{
                  @include mixins.phone{
                    font-size: 1rem;
                  }
                }
              }

              .captcha{
                @include mixins.phone{
                  display:flex;
                  justify-content: center;
                  align-items: center;
                }
              }

              .btn {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                margin-top: 2rem;
                .button{
                  width: 90%;
                  border-radius: 2rem;
                  font-size: 1rem;
                }
              }
            }
          }
        }
      }
    }

    .mapLayout {
      margin-bottom: 2rem;

      h1 {
        margin-bottom: 2rem;
        font-size: 2.5rem;
        font-weight: 600;
        text-align: center;
      }

      .title{
        @include mixins.phone{
          font-size: 1.2rem;
          margin: .5rem 0 .5rem 0;

        }
      }
    }
  }

  .success{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 80%;
    margin: 10rem auto;
    background-color: #F4F4F4;
    padding: 5rem 0;
    position: relative;
    @include mixins.phone{
      width: 100%;
      margin: 2rem auto;
      padding: 2rem 1rem;
    }

    .closeButton {
      position: absolute;
      top: 2rem;
      right: 4rem;
      background: none;
      font-size: 1.5rem;
      cursor: pointer;
      border-radius: 1rem;
      width: 1.8rem;
      border: .1rem solid black;
      @include mixins.phone{
        top: 1rem;
        right: 1rem;
      }
    }

    .successIcon {
      display: inline-block;
      width: 8.1rem;
      height: 8.1rem;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1024 1024'%3E%3Cpath fill='%2317a41d' d='M512 64a448 448 0 1 1 0 896a448 448 0 0 1 0-896m-55.808 536.384l-99.52-99.584a38.4 38.4 0 1 0-54.336 54.336l126.72 126.72a38.272 38.272 0 0 0 54.336 0l262.4-262.464a38.4 38.4 0 1 0-54.272-54.336z'/%3E%3C/svg%3E");
    }
    .successTitle{
      h1{
        margin-top: 5rem;
        font-size: 1.5rem;
        font-weight: 600;
        line-height: normal;
        text-align: center;
        color: #17a41d;
        @include  mixins.phone{
          font-size: 1rem;
        }
      }
    }
    .successSubTitle{
      text-align: center;
      margin-top: 2rem;
      color: black;
      font-size: 1.25rem;
      @include  mixins.phone{
        font-size: .9rem;
      }
    }
  }
}

