@use "../../styles/abstracts";
@use "../../styles/abstracts/variables" as *;

.main {
  display: flex;

  .content {
    flex-grow: 2;
    padding-left: 20px;
    border-left: 1px solid $color_neutral_grey_light;
  }

  @include abstracts.phone {
    flex-direction: column;

    .content {
      flex-grow: 1;
      padding-left: 0px;
      border-left: none;
    }
  }
}