@use "../../styles/abstracts/mixins" as mixins;
@use "../../styles/abstracts/variables" as *;

.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 2001;
    background-color: rgba(0, 0, 0, 0.5);
}

.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2002;
    box-shadow: 2px 2px 50px hsla(0, 0%, 0%, 30%);
    min-width: 840px;

    @include mixins.phone{
        min-width: auto;
    }

    & header {
        background-color: $color_neutral_grey_dark;
        color: $color_neutral_light;
        font-weight: var(--fw-bold);
        padding: 0.625rem 1.25rem;
        display: flex;
        column-gap: 1.25rem;

        & div:first-child {
            flex-grow: 1;
        }

        .icon {
            cursor: pointer;
        }
    }

    & main {
        background-color: $color_neutral_light;
        padding: 0.625rem 1.25rem;
    }
}