.TextInput {
  --border-clr: var(--clr-grey-400);
  --label-clr: var(--clr-grey-400);
  --border-clr-hover: var(--primary);
  margin: 1rem 0;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  position: relative;

  &.invalid input {
    border-color: red;
    background: #fbdada;
  }

  input {
    font-size: 1.4rem;
    width: 100%;
    border: 0.125rem solid var(--border-clr);
    height: 3.6rem;
    outline: none;
    box-shadow: 0 0 0 transparent;
    transition: box-shadow 0.5s;
    padding-left: 0.5rem;

    &:hover {
      border-color: var(--border-clr-hover);
    }

    &:focus {
      border-color: var(--border-clr-hover);
    }

    &.shakeError {
      animation: shake 0.2s ease-in-out 0s 2;
      box-shadow: 0 0 0.5em red;
    }
  }

  .label {
    margin-bottom: 0.5rem;
    padding-left: 0.5rem;

    label {
      color: var(--text);
      font-weight: var(--fw-medium);
      text-align: center;
      cursor: text;
      transition: top 200ms ease-in, left 200ms ease-in, font-size 200ms ease-in;

      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .mandatory {
      color: var(--clr-red-600);
      font-size: .8rem;
    }
  }

  .warning {
    margin-top: 1.1rem;
    color: var(--clr-red-600);
    display: flex;
    justify-content: flex-start;

    p {
      padding-left: 1rem;
      margin-bottom: 1rem;
    }
  }

  .shiny {
    color: var(--clr-grey-50);
    background: radial-gradient(farthest-corner at 0 0, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.1)),
    rgba(25, 118, 210, 0.8);
    overflow: hidden;
    border: 0.2rem solid rgba(255, 255, 255, 0.4);

    &:hover {
      overflow: hidden;
      border: 0.125rem solid rgba(255, 255, 255, 0.4);
    }

    &:focus {
      overflow: hidden;
      border: 0.125rem solid rgba(255, 255, 255, 0.4);
      background: radial-gradient(farthest-corner at 0 0, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.1)),
      rgba(25, 118, 210, 0.8);
    }
  }

  .search {
    font-size: 3rem;
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    z-index: 1;
    cursor: pointer;
    color: var(--clr-grey-500);
    transition: color 0.2s ease-in-out;

    &:hover {
      color: var(--clr-grey-700);
    }
  }

  .error {
    font-size: 12px;
    color: #db332a;
    margin-top: 6px;
    display: block;

    p {
      display: block;
    }
  }
}

@keyframes shake {
  0% {
    margin-left: 0;
  }
  25% {
    margin-left: 0.5rem;
  }
  75% {
    margin-left: -0.5rem;
  }
  100% {
    margin-left: 0;
  }
}
