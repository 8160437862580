@use '../../styles/abstracts/mixins';

.layout {
  font-size: 1.6rem;
  color: var(--text);
  background: var(--bg);
  padding: 2rem 10rem;
  @include mixins.maxmq(xl) {
    padding: 0 2rem;
  }

  .Tree{
    @include mixins.phone{
      display: flex;
      font-size: 1rem;
    }
  }

  .title {
    margin-top: 4rem;
    font-weight: 800;
    font-size: 2.5rem;
    line-height: 120%;
    letter-spacing: 0.05em;
    color: #007AFF;
    @include mixins.phone{
      font-size: 2rem;
      font-weight: var(--fw-semi-bold);
    }
  }

  .description {
    margin-top: 2rem;
    font-size: 1.5rem;
    @include mixins.phone{
      margin-top: 1.5rem;
      font-size: 1rem;
    }
  }

  .body {
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    @include mixins.maxmq(xl) {
      display: inline;
    }

    .calculator {
      padding-right: 10rem;
      @include mixins.maxmq(xl) {
        padding-right: 0;
      }

      .dimensions {
        h2 {
          font-weight: var(--fw-semi-bold);
          font-size: 1.5rem;
          @include mixins.phone{
            font-size: 1.2rem;
          }
        }

        select {
          border: 0.1rem solid #007AFF;
        }

        .inputJoint {
          select {
            background-color: #f5f5f5;
          }
        }

        .after {
          &:after {
            top: 0.8rem;
          }
        }
      }

      .numbers {
        h2 {
          font-weight: var(--fw-semi-bold);
          font-size: 1.5rem;
          @include mixins.phone{
            font-size: 1.2rem;
          }
        }

        .inputCoverage {
          grid-area: input-coverage;
          width: 100%;
          place-self: center;
        }

        .inputClassName {
          border: 0.1rem solid #007AFF;
          background-color: #f5f5f5;
          text-indent: 1rem;
          margin: auto;

          &::placeholder {
            padding-left: 0.1rem;
            color: black;
          }
        }
      }

      .btnContainer {
        display: flex;
        justify-content: center;

        .btn {
          margin: 1rem 0 2rem 0;
        }

        .btnContent {
          font-weight: var(--fw-semi-bold);
        }

      }

      .results {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .tube {
          width: 45%;
          display: flex;
          flex-direction: column;
          align-items: center;

          img {
            width: 80%;
            height: 3rem;
            object-fit: fill;
          }

          p {
            width: 80%;
            height: 4rem;
            margin: 1rem 0;
            font-weight: var(--fw-semi-bold);
            font-size: 2.5rem;
            text-align: center;
            color: #F4F4F4;
            background-color: #333333;
          }

          h3 {
            width: 100%;
            text-align: center;
            font-style: italic;
            font-size: 1.2rem;
          }
        }

        .or {
          width: 10%;
          text-align: center;
          font-weight: var(--fw-semi-bold);
          font-size: 1.5rem;
        }

        .saussage {
          width: 45%;
          display: flex;
          flex-direction: column;
          align-items: center;

          img {
            width: 80%;
            height: 3rem;
            object-fit: fill;
          }

          p {
            width: 80%;
            height: 4rem;
            margin: 1rem 0;
            font-weight: var(--fw-semi-bold);
            font-size: 2.5rem;
            text-align: center;
            color: #F4F4F4;
            background-color: #333333;
          }

          h3 {
            width: 100%;
            text-align: center;
            font-style: italic;
            font-size: 1.2rem;
          }
        }

      }

      .reset {
        margin: 1rem 0;
        display: flex;
        flex-direction: column;
        align-items: center;

        p {
          text-decoration: underline;
          cursor: pointer;
        }
      }

      .legal {
        font-style: italic;
        font-size: 1.5rem;
        @include mixins.phone{
          font-size: 1.2rem;
        }
      }
    }

    .linksContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      @include mixins.maxmq(xl) {
        flex-direction: column;
      }

      .links {
        display: flex;
        flex-direction: column;
        @include mixins.phone{
          align-items: center
        }

        img {
          margin-bottom: 1rem;
          @include mixins.phone{
            margin: 1rem;
            width: 80%;
          }
        }

        .element {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          background: #F4F4F4;
          padding: 0.5rem;
          margin-bottom: 1rem;
          height: 5rem;
          @include mixins.phone{
            width: 100%;
          }

          .text {
            display: flex;
            align-items: center;

            p {
              text-transform: uppercase;
              font-size: 1.2rem;
              @include mixins.phone{
                font-size: 1rem;
              }
            }
          }
        }
      }
    }
  }

  .relatedProduct {
    display: flex;
    flex-direction: column;

    .title {
      padding: 1rem;
      font-size: 1.5rem;
      line-height: 2rem;
      font-weight: 400;
      text-transform: uppercase;
      background-color: #F4F4F4;
      color: #007AFF;
    }

    .element {
      display: flex;
      flex-direction: row;
      margin-top: 2rem;
      @include mixins.maxmq(xl) {
        display: none;
      }

      .imgs {
        display: flex;
        flex-direction: row;
        width: 40%;
        padding-right: 2rem;

        img {
          width: 100%;
          object-fit: fill;
        }

        .second {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 30%;

          img {
            cursor: pointer;
          }
        }

        .main {
          margin-left: 1rem;
          width: 70%;

          img {
            height: 100%
          }
        }
      }

      .detail {
        width: 60%;

        h1 {
          padding-bottom: 0.3rem;
          font-weight: var(--fw-semi-bold);
          border-bottom: 0.1rem solid black;
        }

        p {
          padding-top: 0.5rem;
          line-height: 2rem;
        }

        .link {
          color: #007AFF;
          text-decoration-line: underline;
        }
      }
    }

    .melements {
      display: none;
      @include mixins.maxmq(xl) {
        display: flex;
        flex-direction: row;
        gap: 2rem;
        margin-top: 2rem;
      }
      @include mixins.phone{
        gap: 1rem;
      }

      .melement {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        width: 50%;
        max-width: 20rem;
        cursor: pointer;

        img {
          width: 100%;
          height: 100%;
          object-fit: fill;
        }

        .btn {
          width: 100%;
          height: 7.2rem;
        }

        .content {
          font-weight: var(--fw-semi-bold);

          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          @include mixins.phone{
            font-weight: var(--fw-regular);
            font-size: .8rem;
          }
        }
      }
    }
  }

  .contactLayout {
    width: 100%;
    padding: 2rem 0;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;

    h1 {
      margin-bottom: 1rem;
      font-weight: var(--fw-semi-bold);
      text-align: center;
    }

    .btnLink {
      margin: 0 auto;

      .btn {
        border-radius: 1.5rem;
        height: 3rem;
      }
    }
  }

}