.checkbox {
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  margin-bottom: 1rem;

  label {
    width: auto;
  }
}
