@use "../../styles/abstracts";
@use '../../styles/abstracts/mixins';
@use "../../styles/abstracts/variables" as *;

.textlink {
    cursor: pointer;
    color: $color_primary;
    text-decoration: underline;
}

.red {
    color: $color_semantic_alert;
}

.green {
    color: $color_semantic_success;
}

.align_right {
    display: flex;
    justify-content: flex-end;
    column-gap: 0.625rem;
}

.align_center {
    display: flex;
    justify-content: center;
    column-gap: 0.625rem;
}

.bold {
    font-weight: var(--fw-semi-bold);
}

.checkout {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    column-gap: 1.25rem;
    padding: 1.25rem 0;

    @include abstracts.phone {
        flex-direction: column;
        row-gap: 1.25rem;
    }

    .cards {
        display: flex;
        flex-direction: column;
        row-gap: 1.25rem;
        
        &:first-child {
            flex-grow: 2;
            min-width: 400px;
        }
        
        &:last-child {
            flex-grow: 1;
        }

        .checkout_card {
            & header {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                .label {
                    font-weight: var(--fw-semi-bold);
                }
            }

            & main,
            & main > form {
                display: flex;
                flex-direction: column;
                row-gap: 1.25rem;

                .checkout_list_item {
                    display: flex;
                    column-gap: 0.625rem;

                    img {
                        width: 150px;
                        height: 150px;
                    }

                    .content {
                        flex-grow: 1;
                        display: grid;
                        grid-template-columns: 1fr;
                        grid-template-rows: repeat(4, auto);

                        .full_width {
                            grid-column: span 2;
                        }

                        .controls {
                            display: flex;
                            flex-direction: row;

                            .controlInput {
                                width: 80px;

                                input {
                                    text-align: center;
                                    padding-left: 1em;
                                }
                            }
                        }

                        .removeIcon {
                            display: none;

                            @include abstracts.phone {
                                display: inline;
                            }
                        }
                        
                        .removeString {
                            display: inline;
                            
                            @include abstracts.phone {
                                display: none;
                            }
                        }
                    }
                }
            }
        }

        .checkout_detail {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            row-gap: 0.625rem;
            padding-bottom: 0.625rem;
            border-bottom: 3px solid $color_neutral_grey;
        }

    }
}