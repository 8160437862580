@use '../../styles/abstracts/mixins';


.tree{
  padding: 1rem 5rem;
  width: 100%;
  @include  mixins.phone{
    padding: 1rem 1rem;
  }

}
.imagLayout{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 5rem;
  @include  mixins.phone{
    padding: 0 1rem;
  }
  img {
    width: 100%;

  }
}
.layout {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 2rem 5rem;
  @include mixins.maxmq(xl) {
    padding: 0 2rem;
  }
  .descriptionSec{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @include mixins.phone{
      flex-direction: column;
      align-items: center;
    }
    .imageLogo{
      display: flex;
      flex-direction: column;
      width: 20%;
      @include mixins.phone{
        display: none;
      }
      img{
        box-sizing: border-box;
        object-fit: cover;
        overflow: hidden;
        width: 100%;
      }

    }
    .content {
      line-height: 1.6;
      color: #333;
      width: 75%;
      padding: 1.25rem;
      @include mixins.phone{
        width: 100%;
        padding: 0;
        margin-top: 1rem;
      }

    }
  }
  .productContent{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @include mixins.phone{
      flex-direction: column;
      align-items: center;
    }
    .filterSection{
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 25%;
      @include mixins.phone{
        width: 100%;
        margin: 1rem 0;
      }
      .sidebarContainer {
        width: 80%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .textStyle{
          white-space: normal;
          @include mixins.phone{
            font-size: 1rem;
          }
        }
        .btn {
          width: 100%;
          margin-bottom: 2rem;
          font-size: 1.2rem;
        }
      }
    }
    .productSection{
        width: 75%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      @include mixins.phone{
        width: 100%;
        margin-bottom: 1.5rem;
      }
      .sectionContainer {
        grid-area: main;
        padding: 0 1.6rem;
        width: 100%;
        @include mixins.phone{
          padding: 0;
        }

        .gridContainer {
          display: grid;
          grid-gap: 1rem;
          grid-template-columns: repeat(auto-fill, 23%);
          min-height: 25rem;

          @include mixins.maxmq(hug){
            grid-template-columns: repeat(auto-fill, 32%);
          }
          @include mixins.maxmq(xxl){
            grid-template-columns: repeat(auto-fill, 48%);
          }

          @include mixins.tablet{
            grid-template-columns: repeat(auto-fill, 48%);
          }
          @include mixins.phone{
            grid-template-columns: repeat(auto-fill, 100%);
          }
        }
      }

      @include mixins.maxmq(lg) {
        .sectionContainer {
          width: 100%;
        }
      }
    }
  }
}