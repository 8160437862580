@use "../../styles/abstracts/variables" as *;

.card_wrapper {
    border: none !important;
    border-top: 3px $color_primary_dark solid !important;
    width: 100% !important;

    & header {
        padding: 0.625rem !important;
        background-color: $color_neutral_grey_light !important;
    }

    & main {
        padding: 0.625rem !important;
        border: 1px $color_neutral_grey_light solid !important;
    }
}