@use '../../../styles/abstracts/mixins';

.constructionHeader{
  @include mixins.laptop {
    padding: 0 10rem;
  }
}
.loading {
  display: flex;
  justify-content: center;
  margin-top: 4rem;
}

.layout {
  min-height: 40rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 10rem;
  @include mixins.phone {
    padding: 0 2rem;
  }

  ul {
    width: 100%;
    padding: 1rem 0;
    display: inline-flex;
    justify-content: center;
    @include mixins.phone{
      display: none;
    }

    li {
      padding: 1rem 2rem;
      font-size: 1.8vw;

      a:hover {
        font-weight: var(--fw-semi-bold);
        text-decoration: underline;
      }
    }

    li + li {
      border-left: 0.1rem solid var(--clr-grey-500);
    }

  }

  .title {
    margin: 1rem 0;
    font-size: 3rem;
    font-weight: var(--fw-semi-bold);
    text-align: center;
    @include mixins.phone{
      font-size: 1.5rem;
      margin: 1rem 0 .5rem 0;
    }
  }

  .title2 {
    margin: 1rem 0;
    font-size: 2rem;
    font-weight: var(--fw-semi-bold);
    text-align: center;
    @include mixins.phone{
      font-size: 1.5rem;
      margin: .5rem 0;
      line-height: 2rem;
    }
  }

  .description {
    margin-bottom: 1rem;
    font-size: 1.8rem;
    text-align: center;
    @include mixins.phone{
      font-size: .8rem;
    }
  }

  .discoverLayout {
    margin: 1rem 0;
    padding: 0 15rem;
    display: inline-flex;
    flex-direction: column;
    justify-content: start;
    width: 100%;
    gap: 1rem;
    @include mixins.phone{
      padding: 0;
      margin: 0 0 1.56rem 0;
    }

    .card {
      height: 25rem;
      display: inline-flex;
      flex-direction: row;
      justify-content: space-between;
      @include mixins.phone{
        height: 14.5rem;
        flex-direction: column;
      }

      .left {
        width: 49.5%;
        background-color: #F4F4F4;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        @include mixins.phone{
          width: 100%;
          height: 100%;
          background-size: contain;
          border: 1px solid #ffffff;
        }
      }

      .right {
        width: 49.5%;
        border-radius: 0.2rem;
        overflow: hidden;
        position: relative;
        color: var(--text);
        display: inline-flex;
        flex-direction: column;
        background-color: #F4F4F4;
        @include mixins.phone{
          width: 100%;
          justify-content: center;
          padding: 1rem 0;
        }

        .details {
          width: 100%;
          margin: auto;
          box-sizing: border-box;
          @include mixins.phone{
            display: flex;
            justify-content: center;
          }


          h1 {
            padding: 0 2rem;
            margin-bottom: 0.5rem;
            font-weight: var(--fw-semi-bold);
            font-size: 1.4rem;

            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            @include mixins.phone{
              font-weight: var(--fw-semi-bold);
              font-size: 1.3rem;
              margin-bottom: 0;
            }
          }

          p {
            padding: 0 2rem;
            font-weight: var(--fw-regular);
            font-size: 1rem;

            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 5;
            @include mixins.phone{
              display: none;
            }
          }
        }

        .icon {
          height: 50%;
          margin: 1rem;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center center;
        }

      }

      transition: ease-in-out 0.1s;

      &:hover {
        transform: translateY(-0.188rem) scale(1.03);
        box-shadow: 0 0.313rem 0.938rem rgba(0, 0, 0, 0.35);
        border: 0.3rem solid;
        border-image-slice: 1;
        border-image-source: linear-gradient(#00b5ee, #0937ad);
      }
    }
  }

  .caption {
    font-weight: var(--fw-semi-bold);
  }

  .subSegmentLayout {
    min-height: 40rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .container + .container {
      padding-top: 2rem;
      @include mixins.phone{
        padding-top: 0;
      }
    }

    .container {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      gap: 2rem;
      @include mixins.maxmq(lg) {
        gap: 0;
      }

      .left {
        width: 100%;
      }

      .right {
        width: 100%;

        .title {
          text-align: start;
          font-weight: var(--fw-semi-bold);
          font-size: 1.5rem;
          text-transform: uppercase;
          @include mixins.phone{
            font-size: 1rem;
            margin: .5rem 0 .25rem 0;
            color: #007AFF ;
          }
        }
        .description{
          text-align: start;
          font-weight: var(--fw-semi-bold);
          font-size: 1.5rem;
          text-transform: uppercase;
          @include mixins.phone{
            font-size: 1rem;
            margin: 0;
          }
        }

        .details {
          text-align: start;
          font-size: 1rem;
          @include mixins.phone{
            font-size: .8rem;
          }
        }

        hr {
          margin: 0.25rem 0 1rem 0;
          color: var(--clr-grey-500);
          background-color: var(--clr-grey-500);
          border: none;
          height: 0.1rem;
          @include mixins.phone{
            margin: 0 0 1rem 0;
            background-color: #007AFF ;
          }

        }

        .sheet {
          h1 {
            text-align: start;
            font-weight: var(--fw-semi-bold);
            font-size: 1.5rem;
            padding: 1rem 0;
            @include mixins.phone{
              font-size: 1rem;
              padding: .5rem 0 0 0;
            }
          }

          p {
            color: #007AFF;
            text-decoration: underline;
            cursor: pointer;
          }

          .icon {
            font-size: 4rem;
            cursor: pointer;
            @include mixins.phone{
              font-size: 3rem;
            }
          }
        }

        .productTitle {
          text-align: start;
          font-weight: var(--fw-semi-bold);
          font-size: 1.5rem;
          padding: 1rem 0;
        }

        .carouselDesktop {
          .btns {
            margin-top: 1rem;
            display: flex;
            justify-content: center;
            gap: 0.5rem;

            .btn {
              padding: 0;
              border: 0;

              &[disabled] {
                display: none;
              }
            }

            .icon {
              font-size: 2.5rem;
            }

          }

          .slider {
            width: 100%;
            height: 25rem;
          }
        }
      }

    }

  }

  .contactLayout {
    width: 100%;
    padding: 2rem 0;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;

    h1 {
      margin-bottom: 1rem;
      font-weight: var(--fw-semi-bold);
      text-align: center;
    }

    .btnLink {
      margin: 0 auto;

      .btn {
        border-radius: 1.5rem;
        height: 3rem;
      }
    }
  }

}