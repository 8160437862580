@use '../../../styles/abstracts/mixins';

.button {
  // VARIABLES
  // Baseline
  --btn-bg: #007AFF;
  --btn-text: var(--clr-grey-50);
  --btn-border: #007AFF;
  // Hover
  --btn-bg-hover: #005cbf;
  --btn-text-hover: var(--clr-grey-50);
  --btn-border-hover: #005cbf;
  // Disabled
  --btn-dis-bg: var(--clr-grey-150);
  --btn-dis-text: var(--clr-grey-400);
  // BASIC BUTTON PROPERTIES
  font-weight: var(--fw-semi-bold);
  font-size: 1.4rem;
  color: var(--btn-text);
  font-family: var(--font-family-sans-serif);
  border: 2pt solid var(--btn-border);
  padding: 0 1rem;
  height: 3.6rem;
  cursor: pointer;
  transition: all 0.5s ease-out;
  background: linear-gradient(270deg, var(--btn-bg-hover), var(--btn-bg-hover), var(--btn-bg), var(--btn-bg)) 1% 50%;
  background-size: 300% 300%;
  box-shadow: var(--shadow-el-2) hsla(0, 0%, 0%, 0.25);
  position: relative;

  svg {
    width: 2.5rem;
    height: 2.5rem;
  }

  .content {
    overflow: hidden;

    &.bold {
      font-weight: var(--fw-semi-bold);
    }

    align-items: center;
    gap: 0.8rem;
    white-space: nowrap;
  }

  &:hover,
  &:active {
    box-shadow: var(--shadow-el-13) hsla(0, 0%, 0%, 0.25);
    color: var(--btn-text-hover);
    border-color: var(--btn-border-hover);
    background-position: 98% 50%;
  }

  &:focus {
    outline: none;
  }

  // DISABLED
  &:disabled,
  &:focus:disabled,
  &:hover:disabled,
  &:active:disabled {
    box-shadow: none;
    animation: none;
    background: var(--btn-dis-bg);
    border-color: var(--btn-dis-bg);
    color: var(--btn-dis-text);
    cursor: not-allowed;
  }

  // COLOR VARIATION
  &.outlineBlack {
    // Baseline
    --btn-bg: var(--clr-alpha-0);
    --btn-text: var(--clr-grey-900);
    --btn-border: var(--clr-grey-900);
    // Hover
    --btn-text-hover: var(--clr-grey-50);
    --btn-bg-hover: var(--clr-grey-900);
    --btn-border-hover: var(--clr-grey-900);
  }

  &.outlineBlue {
    // Baseline
    --btn-bg: var(--clr-alpha-0);
    --btn-text: var(--clr-grey-900);
    --btn-border: #007AFF;
    // Hover
    --btn-text-hover: var(--clr-grey-50);
    --btn-bg-hover: #007AFF;
    --btn-border-hover: #007AFF;
  }

  &.outlineWhite {
    // Baseline
    --btn-bg: var(--clr-alpha-0);
    --btn-text: var(--clr-grey-50);
    --btn-border: var(--clr-grey-50);
    // Hover
    --btn-text-hover: var(--clr-grey-1000);
    --btn-bg-hover: var(--clr-grey-50);
    --btn-border-hover: var(--clr-grey-50);
  }

  &.outlineWhiteToPrimary {
    // Baseline
    --btn-bg: var(--clr-alpha-0);
    --btn-text: var(--clr-grey-50);
    --btn-border: var(--clr-grey-50);
    // Hover
    --btn-text-hover: var(--clr-grey-50);
    --btn-bg-hover: var(--primary);
    --btn-border-hover: var(--primary);
  }

  &.calculatorButton {
    // Baseline
    --btn-bg: var(--primary);
    --btn-text: var(--clr-grey-50);
    --btn-border: var(--clr-grey-50);
    // Hover
    --btn-text-hover: var(--primary);
    --btn-bg-hover: var(--clr-grey-50);
    --btn-border-hover: var(--primary);
  }

  &.red {
    // Baseline
    --btn-bg: var(--delete);
    --btn-text: var(--clr-grey-50);
    --btn-border: var(--delete);
    // Hover
    --btn-text-hover: var(--clr-grey-50);
    --btn-bg-hover: var(--delete-hover);
    --btn-border-hover: var(--delete-hover);
  }

  &.grey {
    // Baseline
    --btn-bg: var(--clr-grey-600);
    --btn-text: var(--clr-grey-50);
    --btn-border: var(--clr-grey-600);
    // Hover
    --btn-text-hover: var(--clr-grey-50);
    --btn-bg-hover: var(--clr-grey-400);
    --btn-border-hover: var(--clr-grey-400);
  }

  &.rainbow {
    // Baseline
    --btn-bg: var(--clr-grey-900);
    --btn-text: var(--clr-grey-50);
    border: none;
  }

  &.rainbow:hover {
    background: linear-gradient(
                    90deg,
                    #9400d3 0%,
                    #4b0082 17%,
                    #0000ff 34%,
                    #00ff00 51%,
                    #ffff00 68%,
                    #ff7f00 85%,
                    #ff0000 100%
    );
    animation: slidebg 5s linear infinite;
  }

  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  // SIZE VARIATION
  &.big {
    height: 3rem;
    font-size: 1.4rem;

    svg {
      width: 3rem;
      height: 3rem;
    }
  }

  &.small {
    height: 2.5rem;
    padding: 0 0.6rem;
    font-size: 1.2rem;
  }
}
