@use '../../styles/abstracts/mixins';


.layout {

  .title {
    padding: 0 5rem;
    margin-top: 2rem;
    font-size: 3rem;
    font-weight: var(--fw-bold);
    text-transform: uppercase;
    line-height: 120%;
    color: #007AFF;
    @include mixins.phone {
      padding: 0 2rem;
      font-size: 2rem;
      text-align: center;
      margin-top: 1rem;
    }

  }

  .intro {
    padding: 0 5rem;
    width: 100%;
    font-size: 2rem;
    margin: 4rem 0;
    @include mixins.phone {
      padding: 0 2rem;
      margin: 1rem 0;
      font-size: 1rem;
    }
  }

  .carousel {
    padding: 0 7rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: 4rem 0;
    @include mixins.phone {
      padding: 0 2rem;
      margin: 0 0 1rem 0;
    }

    .btn {
      z-index: 2;
      width: 3rem;
      height: 3rem;
      margin: auto 2rem;
      padding: 0;
      border: 0;
      border-radius: 15%;
      @include mixins.phone{
        display: none;
      }

      .icon {
        font-size: 3rem;
        color: black;

      }
    }

    .slider {
      width: 100%;
      height: 6rem;

      .slide {
        width: 15rem;
        height: 6rem;

        .slideImg {
          width: 15rem;
          height: 6rem;
          object-fit: contain;
          box-sizing: border-box;
          @include mixins.phone{
            width: 8rem;
            height: 6rem;
          }
        }
      }
    }
  }

  .ceoIntro {
    padding: 0 5rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 4rem 0;
    @include mixins.phone {
      padding: 0 2rem;
      flex-direction: column;
      margin: 0;
    }

    .img {
      margin: auto;
      position: relative;
      width: 100%;
      @include mixins.phone{
        display: flex;
        flex-direction: column;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        box-sizing: border-box;
        border-radius: 10px;
        @include mixins.phone {
          border-radius: 0;
        }
      }

      .slogans {
        position: absolute;
        bottom: 0;
        padding: 1rem;
        margin: 2rem;
        width: 40%;
        min-width: 40rem;
        @include mixins.phone {
          position: relative;
          width: 100%;
          min-width: 0;
          margin: 0;
        }

        background: rgba(0, 0, 0, 0.73);

        h2 {
          font-size: 1.875rem;
          text-transform: uppercase;
          line-height: 120%;
          color: white;
          @include mixins.phone {
            font-size: .7rem;
          }
        }

        h1 {
          font-size: 3rem;
          font-weight: var(--fw-semi-bold);
          text-transform: uppercase;
          line-height: 120%;
          color: white;
          @include mixins.phone {
            font-size: .8rem;
          }
        }

      }
    }

    .text {
      padding: 2rem 0;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      width: 100%;
      @include mixins.phone{
        padding: 1rem 0;
        gap: .5rem;
      }

      p {
        font-size: 1.25rem;
        line-height: 120%;
        @include mixins.phone{
          font-size: .75rem;
        }

        span {
          font-weight: var(--fw-semi-bold);
          text-transform: uppercase;
        }
      }

    }

    .seenMoreButton{
      color: #007AFF;
      text-decoration: underline;
      font-size: .75rem;
    }

  }

  .valuesTitle {
    padding: 0 7rem;
    font-weight: var(--fw-semi-bold);
    font-size: 3.75rem;
    line-height: 4.5rem;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 4rem;
    color: #007AFF;
    @include mixins.phone {
      padding: 0 2rem;
      font-size: 1.5rem;
      margin-bottom: 0;
    }
  }

  .values {
    padding: 0 7rem;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    @include mixins.phone {
      padding: 0 2rem;
      gap: 1rem;
    }

    .value {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;
      @include mixins.phone{
        gap: .5rem;
      }

      h1 {
        font-weight: var(--fw-bold);
        font-size: 2.875rem;
        line-height: 3.25rem;
        text-align: center;
        text-transform: uppercase;
        @include mixins.phone {
          font-size: 1.5rem;
        }
      }

      h2 {
        font-weight: var(--fw-semi-bold);
        font-size: 1.5rem;
        line-height: 1.8rem;
        text-align: center;
        text-transform: uppercase;
        @include mixins.phone {
          font-size: 1rem;
        }
      }

      p {
        width: 22.5rem;
        height: 18.5rem;
        padding: 1rem;
        font-size: 1.25rem;
        line-height: 1.5rem;
        background: #333333;
        box-shadow: 0 0 2rem rgba(0, 0, 0, 0.2);
        border-radius: 0.25rem;
        color: #F4F4F4;
        @include mixins.phone {
          width: 100%;
          height: 100%;
          font-size: .8rem;
          line-height: 1rem;
        }
      }

      img {
        width: 4.8rem;
        height: 4.8rem;
      }
    }
  }

  .cultureTitle {
    padding: 0 7rem;
    font-weight: var(--fw-semi-bold);
    font-size: 3.75rem;
    line-height: 4.5rem;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 4rem;
    margin-top: 4rem;
    color: #007AFF;
    @include mixins.phone {
      padding: 0 2rem;
      font-size: 1.5rem;
      margin-bottom: 1rem;
      margin-top: 1rem;
      line-height: 1.5rem;
    }
  }

  .cultureDescription {
    padding: 0 7rem;
    font-size: 1.875rem;
    line-height: 2rem;
    @include mixins.maxmq(xl) {
      line-height: 1.2rem;
      padding: 0 2rem;
      font-size: .8rem;
    }
  }

  .cultures {
    margin-top: 4rem;
    @include mixins.phone{
      margin-top: 2rem;
    }

    .culture {
      display: flex;
      flex-direction: column;
      width: 100%;

      .img {
        width: 100%;
        padding: 2rem 7rem;
        background-color: #F4F4F4;
        display: flex;
        flex-direction: column;
        gap: 2rem;
        @include mixins.phone {
          padding: 1rem 2rem;
          gap: .5rem;
        }

        img {
          display: block;
          margin-left: auto;
          margin-right: auto;
          width: 100%;
          height: 100%;
          object-fit: contain;
          box-sizing: border-box;
          border-radius: 10px;
        }

        h1 {
          font-weight: 600;
          font-size: 3rem;
          line-height: 3.5rem;
          text-transform: uppercase;
          @include mixins.phone{
            font-size: 1.3rem;
            line-height: 0;
          }
        }

        h2 {
          font-weight: 400;
          font-size: 1.875rem;
          line-height: 2.25rem;
          text-transform: uppercase;
          @include mixins.phone{
            font-size: .9rem;
            line-height: 2.25rem;
          }
        }
      }

      .details {
        padding: 2rem 7rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: 100%;
        @include mixins.phone {
          padding: 1rem 2rem 0 2rem;
          gap: .5rem;
        }

        h3 {
          font-weight: 600;
          font-size: 1.25rem;
          line-height: 1.5rem;
          @include mixins.phone{
            font-size: 1.2rem;
            text-align: center;
          }
        }

        p {
          font-weight: 400;
          font-size: 1.25rem;
          line-height: 1.5rem;
          @include mixins.phone {
            line-height: 1rem;
            font-size: .8rem;
          }
        }

        ul {
          margin-left: 1rem;

          li {
            list-style: outside;
            p{
              @include mixins.phone{
                font-size: .8rem;
              }
            }
          }

        }

        .description{
          @include mixins.phone{
            margin-bottom: 1rem;
          }
        }

        .statistic {
          display: flex;
          justify-content: space-between;
          flex-direction: row;
          flex-wrap: wrap;
          @include mixins.phone{
            margin-bottom: 1rem;
          }
          div {
            width: 9rem;
            display: flex;
            flex-direction: column;
            justify-content: center;

            img {
              width: 9.25rem;
              height: 11.25rem;
              @include mixins.phone{
                width: 8rem;
                height: 10.5rem;
              }
            }

            p {
              font-weight: 600;
              font-size: 1.25rem;
              line-height: 1.5rem;
              text-align: center;
              text-transform: capitalize;
              @include mixins.phone{
                font-size: 1rem;
                line-height: 1rem;
              }
            }
          }
        }

        .noStatistic {
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 1.25rem;
          font-size: 1.25rem;
          line-height: 1.5rem;

          ul {
            margin-left: 0;
          }
        }

        .colaboration {
          li {
            font-size: 1.25rem;
          }
        }
      }
    }
  }

  .spotlightCarousel {
    padding: 0 7rem;
    @include mixins.maxmq(xl) {
      padding: 0 2rem;
    }
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .buttons {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      @include mixins.phone{
        display: none;
      }

      .btn {
        z-index: 2;
        width: 3rem;
        height: 3rem;
        padding: 0;
        border: 0;
        border-radius: 15%;

        .icon {
          font-size: 3rem;
          color: black;

        }
      }
    }

    .slider {
      width: 100%;
      height: 35rem;
      @include mixins.phone{
        height: 25rem;
      }

      .slide {
        .spotlight {
          display: flex;
          flex-direction: column;
          justify-content: start;
          width: 22.5rem;
          height: 35rem;
          background: #F4F4F4;
          border-radius: 4px;
          @include mixins.phone{
            width: 15rem;
            height: 25rem;
          }

          &:hover {
            box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2);
          }

          .imgContainer {
            height: 15rem;
            width: 22.5rem;
            @include mixins.phone{
              width: 100%;
              max-height: 20rem;
            }

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              overflow: hidden;
              box-sizing: border-box;
            }
          }

          .textContainer {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            padding: 1rem;

            h2 {
              height: 3.2rem;
              font-weight: var(--fw-semi-bold);
              font-size: 1.25rem;
              line-height: 1.5rem;
              @include mixins.phone{
                height: 100%;
                font-size: .8rem;
                line-height: 1rem;
              }
            }

            p {
              font-size: 1.25rem;
              line-height: 1.5rem;
              @include mixins.phone{
                font-size: .7rem;
                line-height: .8rem;
              }
            }
          }
        }
      }
    }
  }
}