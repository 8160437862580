@use "./fonts" as *;
// This is a style reset, its purpose is to remove any default style a particular browser could have on by default
// so that the look and feel of our website is consistant accross the board
*,
*:before,
*:after {
	box-sizing: border-box;
}

html,
body,
div,
span,
object,
iframe,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
code,
em,
img,
small,
strike,
strong,
sub,
sup,
tt,
b,
u,
i,
ol,
ul,
li,
fieldset,
form,
label,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
main,
canvas,
embed,
footer,
header,
nav,
section,
video {
	margin: 0;
	padding: 0;
	border: 0;
	font-style: inherit;
	font-variant-ligatures: inherit;
	font-variant-caps: inherit;
	font-variant-numeric: inherit;
	font-variant-east-asian: inherit;
	font-weight: inherit;
	font-stretch: inherit;
	vertical-align: baseline;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	font-family: inherit;
	text-size-adjust: none;
}

footer,
header,
nav,
section,
main {
	display: block;
}

body {
	line-height: 1;
}

ol,
ul {
	list-style: none;
}

blockquote,
q {
	quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
	content: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

input {
	border-radius: 0;
}
