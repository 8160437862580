@use '../../styles/abstracts/mixins';

.SideCart {
  --bg: var(--clr-grey-100);
  --text: var(--clr-grey-1000);
  font-weight: var(--fw-medium);
  font-size: 1.6rem;
  height: 100%;
  width: 40%;
  transform: translateX(100%);
  transition: all 0.5s ease;
  opacity: 0;
  position: fixed;
  top: 0;
  right: 0;
  box-shadow: 2px 2px 50px hsla(0, 0%, 0%, 30%);
  background: #fff;
  overflow: scroll;
  z-index: 2500;

  .topContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--clr-grey-800);
    height: 5rem;
    padding: 2rem;
    @include mixins.maxmq(xl) {
      height: 3.5rem;
      padding: 0 2rem;
    }

    .close {
      cursor: pointer;
    }

    svg {
      filter: invert(100%);

      .xClose {
        fill: var(--text);
        stroke: var(--text);
      }
    }

    h2 {
      font-size: 1.4rem;
      width: 100%;
      text-align: center;
      filter: invert(100%);
    }
  }

  .content {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 0 1.25rem 3rem 1.25rem;

    .details {
      background: var(--clr-grey-50);
      border-bottom: groove var(--clr-grey-500);
      border-bottom-width: 0.1rem;
      width: 100%;
      @include mixins.phone{
        font-size: 1rem;
      }

      .confirmation {
        display: flex;
        align-items: flex-start;
        padding: 1rem;

        .success {
          color: #28a745;
          font-size: 2rem;
          margin-right: 1rem;
          @include mixins.phone{
            font-size: 1.5rem;
          }
        }
      }

      hr {
        width: 100%;
        color: var(--clr-grey-500);
        background-color: var(--clr-grey-500);
        border: none;
        height: 0.1rem;
      }
    }

    .resume {
      padding: 1rem;
      font-weight: var(--fw-semi-bold);
      font-size: 1.4rem;
      border-bottom: groove var(--clr-grey-500);
      border-bottom-width: 0.1rem;
      width: 100%;
      @include mixins.phone{
        font-size: 1.2rem;
      }
    }

    .subTotal {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 1rem;
      @include mixins.phone{
        display: none;
      }

      p {
        font-weight: var(--fw-semi-bold);
        font-size: 1.4rem;
      }
    }
    .subTotalMobile {
      display: none;
      justify-content: space-between;
      width: 100%;
      padding: 0.625rem 1rem 0 1rem;
      @include mixins.phone{
        display: flex;
      }

      p {
        font-weight: var(--fw-semi-bold);
        font-size: 1.4rem;
      }
    }

    .button {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      margin-top: .9rem;

      .btn {
        margin: 1rem;
        @include mixins.phone{
          font-size: 1.2rem;
          margin: 0 1rem .9rem 1rem;
        }
      }
    }
  }

  @include mixins.maxmq(lg) {
    z-index: 2500;
    width: 100%;
    .topContainer {
      svg {
        order: 2;
      }

      h2 {
        order: 1;
      }
    }
  }
}

.SideCart[data-is-active="true"] {
  opacity: 1;
  transform: translateX(0%);
  @include mixins.maxmq(lg) {
  }
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.5);
  @include mixins.maxmq(lg) {
    display: none;
  }
}
