@use "../../styles/abstracts";
@use "../../styles/abstracts/mixins";
@use "../../styles/abstracts/variables" as *;

.header {
    display: flex;
    flex-direction: column;
    row-gap: 1.25rem;

    .title {
        color: $color_primary;
        font-size: 2.438rem;
        font-weight: var(--fw-bold);
        text-transform: uppercase;
    }
}