@use '../../styles/abstracts/mixins';

.footerContainer {
  --bg: var(--clr-grey-900);
  color: var(--text);
  background: #F4F4F4;
  padding: 3rem 6rem;
  @include  mixins.phone{
    padding: .8rem 0 0 0;
  }

  hr {
    border-top: 0.063rem solid var(--text-dim);
  }

  .footerNavContainer {
    padding-bottom: 1.6rem;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
    @include mixins.phone{
      display: none;
    }
    column-gap: 1rem;
    row-gap: 1rem;

    .footerNavSection {
      display: flex;
      flex-direction: column;
      line-height: 1.5rem;

      .footerNavHeader {
        font-weight: var(--fw-semi-bold);
        font-size: 1.2rem;
        @include  mixins.phone{
          font-size: 1.1rem;
        }
      }

      .footerNavElement {
        display: flex;
        flex-direction: row;
        margin-top: 0.5rem;
        font-size: var(--fs-100);
        transition: ease-in-out 0.3s;
        text-align: left;
        @include  mixins.phone{
          font-size: .9rem;
        }

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .footerNavContainerMobile{
    display: none;
    @include mixins.phone{
      width: 100%;
      height: 100%;
      display: inline-flex;
      flex-direction: column;
    }
    .address {
      display: inline-flex;
      flex-direction: column;

      .title {
        width: 100%;
        padding: 0.5rem 2rem;
        display: inline-flex;
        justify-content: space-between;
        border-bottom: 2px solid #dadada;
        cursor: pointer;
        z-index: 2;

        &:hover {
          background: #F4F4F4;
        }

        .description {
          width: 80%;
          font-size: .875rem;
          font-weight: var(--fw-semi-bold);
          padding-right: 1rem;
        }

        .arrow {
          cursor: pointer;
          font-size: 2rem;
          opacity: 1;
          transition: ease-in-out 0.3s;

        }

        &.active {
          background: #F4F4F4;
        }

      }

      .details {
        width: 100%;
        display: inline-flex;
        padding: 0 1rem;
        gap: 1rem;
        transition: ease 0.5s;
        opacity: 0;
        flex-direction: column;

        &.active {
          padding: 0 1rem 0.5rem 3rem;
          background: #F4F4F4;
          opacity: 1;
        }

        .footerNavElement {
          display: flex;
          flex-direction: row;
          margin-top: 1rem;
          font-size: .9rem;
          transition: ease-in-out 0.3s;
          text-align: left;


          &:hover {
            text-decoration: underline;
          }
        }
      }

    }
  }

  .footerMediaContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 1.5rem;
    @include mixins.phone{
      margin:1.5rem 1rem 0 1rem ;
    }

    .footerSocialContainer {
      .footerSocialIcon {
        font-size: 1.6rem;
        cursor: pointer;
        margin: 0.5rem auto 0 auto;
        transition: ease-in-out 0.3s;
        @include  mixins.phone{
          font-size: 1.3rem;
        }
      }
    }
  }

  .footerLogoContainer {
    .footerLogoSvg {
      height: 2.5rem;
    }

    &.desktopHidden {
      justify-content: flex-end;
    }
  }

  .footerBottomContainer {
    display: flex;
    flex-direction: row;
    @include mixins.phone{
      justify-content: center;
    }

    .footerCopyrightContainer {
      font-size: var(--fs-100);
      @include  mixins.phone{
        font-size: .9rem;
      }
    }
  }

  .footerLanguageContainer {
    display: flex;
    flex-direction: row;
    font-size: var(--fs-100);
    @include  mixins.phone{
      font-size: .9rem;
    }

    &.desktopHidden {
      align-items: center;
    }
  }

  .desktopHidden {
    display: none;
    @include mixins.maxmq(sm) {
      display: flex;
    }
  }

  .mobileHidden {
    @include mixins.maxmq(sm) {
      display: none;
    }
  }

  .footerClickable {
    cursor: pointer;
    transition: ease-in-out 0.3s;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .footerVerticalBar {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  .footerAlignLeft {
    display: flex;
    flex-direction: row;
    font-size: var(--fs-100);
    margin-top: 0.5rem;
    width: fit-content;
    @include  mixins.phone{
      font-size: .9rem;
      width: 100%;
      justify-content: center;
      padding-bottom: 6rem;
    }
  }
}
