@use '../../../styles/abstracts/mixins';

.Tree {
  padding: 1rem 4rem 1rem 3rem;
  @include mixins.maxmq(lg) {
    padding: 0 4rem 1rem 1.5rem;
  }
  @include mixins.phone{
    display: none;
  }
}

.body {
  width: 100%;
  margin-bottom: 2.4rem;
  display: inline-flex;
}
