@use '../../../styles/abstracts/mixins';

.productOverView {
  margin-right: 0;
  display: flex;
  flex-direction: column;

  .header {
    margin-top: 1rem;
    text-align: center;
    font-size: .75rem;
    padding: 0 1.5rem;
    display: none;

    @include mixins.phoneAndTablet{
      display: block;
    }
    @include mixins.tablet{
      font-size: 1.3rem;
    }

    .link {
      text-decoration: underline;
      color: #007AFF;
    }
  }

  li {
    list-style: inside;
  }

  .Tree {
    padding: 1rem 4rem;
    @include mixins.maxmq(lg) {
      padding: 1rem 4rem 1rem 1.5rem;
    }
    @include mixins.phoneAndTablet{
      display: none;
    }
  }

  .h1 {
    font-size: 2rem;
    padding: 1.2rem;
    font-weight: var(--fw-semi-bold);
    display: flex;
    justify-content: center;
  }

  .hrTop {
    margin: 1rem 3rem 0 3rem;
  }

  .hrBot {
    margin: 0 3rem 1rem 3rem;
  }
}
