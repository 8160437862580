@use '../../../../styles/abstracts/mixins';

.layout {
  font-size: var(--fs-100);
  color: var(--text);
  background: var(--bg);

  .padding {
    padding: 1rem 0 1rem 0;
  }

  .margin {
    margin-top: 5rem;
  }

  .button {
    margin-bottom: 1rem;
  }

  .formLayout {
    padding: 2rem 4rem;
    @include mixins.maxmq(lg) {
      padding: 5rem;
    }

    h2 {
      font-size: 32px;
      font-weight: var(--fw-semi-bold);
      margin: 2rem 0 1rem 0;
    }
  }

  .inputLayout {
    display: grid;
    grid-gap: 0.5rem;
    grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));

    .input {
      width: 100%;
    }
  }

  .instaLayout {
    display: grid;
    grid-gap: 0;
    grid-template-columns: repeat(auto-fill, minmax(25rem, 1fr));

    img {
      max-width: 100%;
    }
  }

  .link {
    color: var(--link);
  }

  .warning {
    color: var(--clr-red-600);
    display: flex;
    justify-content: flex-start;
    margin-top: 2rem;

    p {
      padding-left: 1rem;
      margin-bottom: 1rem;
    }
  }
}
