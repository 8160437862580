@use "../../styles/abstracts";
@use '../../styles/abstracts/mixins';
@use "../../styles/abstracts/variables" as *;

.textlink {
    cursor: pointer;
    color: $color_primary;
    text-decoration: underline;

}

.red {
    color: $color_semantic_alert;
}

.green {
    color: $color_semantic_success;
}

.align_right {
    display: flex;
    justify-content: flex-end;
    column-gap: 0.625rem;
}

.align_center {
    display: flex;
    justify-content: center;
    column-gap: 0.625rem;
}

.bold {
    font-weight: var(--fw-semi-bold);
}

.purchaseSuccess {
    flex-direction: column;
    align-items: center;
    row-gap: 1.25rem;
    text-align: center;

    .icon {
        color: $color_semantic_success;
        font-size: 5rem;
    }
}

.checkout {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    column-gap: 1.25rem;
    padding: 1.25rem 0;

    @include abstracts.phone {
        flex-direction: column;
        row-gap: 1.25rem;
    }

    .cards {
        display: flex;
        flex-direction: column;
        row-gap: 1.25rem;
        
        &:first-child {
            flex-grow: 2;
        }
        
        &:last-child {
            flex-grow: 1;
            min-width: 400px;
        }

        .checkout_card {
            & header {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                .label {
                    font-weight: var(--fw-semi-bold);
                }
            }

            & main,
            & main > form {
                display: flex;
                flex-direction: column;
                row-gap: 1.25rem;

                .checkout_list_item {
                    display: flex;
                    column-gap: 0.625rem;
                    
                    img {
                        max-width: 100px;
                        max-height: 100px;
                    }

                    .content {
                        flex-grow: 1;
                        display: grid;
                        grid-template-columns: 1fr;
                        grid-template-rows: repeat(4, auto);

                        .full_width {
                            grid-column: span 2;
                        }
                    }
                }
            }
        }

        .checkout_detail {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            // grid-template-rows: repeat(4, auto);
            row-gap: 0.625rem;
            
            .checkout_alert {
                grid-column: span 2;
            }

            .checkout_total {
                font-size: 1.25rem;
                border-top: 1px solid $color_neutral_grey_dark;
                padding-top: 0.625rem;
            }
        }

    }
}