.SearchBar {
  --border-clr: var(--clr-grey-400);
  --label-clr: var(--clr-grey-400);
  --border-clr-hover: var(--primary);
  --icon-fill: var(--clr-grey-600);
  height: 3.6rem;
  position: relative;
  display: flex;
  justify-content: space-between;
  border-radius: 0.2rem;
  border: 0.1rem solid var(--border-clr);

  &:hover {
    border-color: var(--border-clr-hover);
  }

  &:focus-within {
    border-color: var(--border-clr-hover);
  }

  &.invalid {
    border-color: red;
    background: #fbdada;
  }

  .closeContainer {
    width: 2.4rem;
    display: flex;
    align-items: center;
  }

  svg {
    transition: fill 300ms ease;
    height: 2.4rem;
    width: 2.4rem;
    align-self: center;
    fill: var(--icon-fill);

    &:hover {
      fill: var(--primary);
    }
  }

  .iconSearch {
    margin-left: 0.8rem;
    color: var(--icon-fill);
    cursor: pointer;
  }

  .close {
    margin-right: 0.8rem;
    justify-content: flex-end;
    cursor: pointer;
    stroke: var(--icon-fill);

    &:hover {
      stroke: var(--primary);
    }
  }

  input {
    color: var(--text);
    outline: none;
    border: none;
    background: none;
    font-size: 1.4rem;
    width: 100%;
    height: 100%;

    &:focus {
      border-color: var(--border-clr-hover);
      background: none;
    }
  }

  &.navBar {
    --border-clr: var(--clr-grey-400);
    --label-clr: var(--clr-grey-200);
    --border-clr-hover: var(--primary);
    --text: var(--clr-grey-50);
    --icon-fill: var(--clr-grey-50);
    background: var(--clr-grey-800);
    border: none;
    height: 4.4rem;
  }

  &[data-is-mobile="true"] {
    height: 3.6rem;
  }
}
