@use '../../../../../styles/abstracts/mixins';

.searchBarContainer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 3rem;
  padding: 2rem 0 2rem 24rem;
  @include mixins.phone {
    display: var(--displayMobile);
    gap: 1rem;
    flex-direction: row;
    padding: 1.5625rem 0 1.5625rem 0;
    justify-content: center;
  }

  .searchBar {
    margin: 0;
    @include mixins.phone {
      display: none;
    }
  }

  .onlyCoted {
    label {
      color: #007AFF;
      @include mixins.phone {
        width: 15rem;
      }
    }
  }

  .showFavorite {
    label {
      @include mixins.phone {
        width: 15rem;
      }
    }
  }
}
