@use '../../../styles/abstracts/mixins';
@use "../../../styles/abstracts/variables" as *;

.bold {
    font-weight: var(--fw-semi-bold);
}

.frameset {
    display: flex;
    flex-direction: column;
    row-gap: 1.25rem;
}