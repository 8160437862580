@use '../../styles/abstracts/mixins';
@use "../../styles/abstracts/media-query" as *;

.layout{
  margin: 2rem 9rem;
  display: flex;
  flex-direction: column;
  @include mixins.phone{
    margin: 1rem 1rem;
  }

  .Tree{
    font-size: 1.6rem;
    @include mixins.phone{
      display: flex;
      font-size: 1rem;
    }
  }

  .title{
    font-size: 2.5rem;
    font-weight: 800;
    color: #007AFF !important;
    margin-bottom: 2rem;
    margin-top: 4rem;
    @include mixins.tablet{
      font-size: 3rem;
    }
    @include mixins.phone{
      font-size: 2rem;
      margin: 0;
    }
  }

  .subLayout1{
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    .subTitle{
      font-size: 2rem;
      font-weight: var(--fw-semi-bold);
      @include mixins.tablet{
        font-size: 1.5rem;
      }
      @include mixins.phone{
        font-size: 1rem;
      }
    }
    .tabMobile{
      display: none;
      @include mixins.phone{
        display: flex;
        margin-left: 1rem;
      }
    }
    .tabWeb{
      display: flex;
      width: 100%;
      align-items: center;
      flex-direction: column;
      margin-top: 1rem;
      @include mixins.phone{
        display: none;
      }
      .findSection{
        display: flex;
        width: 60%;
        align-items: center;
        text-align: center;
        @include mixins.tablet{
          width: 100%;
        }
        .default{
          background: #F4F4F4;
          border: 1px solid #999999;
          color: black;
        }
        button{
          width: 45%;
          border-radius: 0;
          font-size: 1rem;
        }
        .btnContent{
          white-space: normal;
        }
        p{
          width: 10%;
          font-size: 1rem;
          color: gray;
          font-weight: var(--fw-medium);
        }
      }
      .resetFilter{
        display: flex;
        width: 100%;
        justify-content: flex-end;
        margin: 1rem 0;
        cursor: pointer;
        text-decoration: underline;
        font-size: 1.2rem;
        color: #0072de;
      }
      .headerSearch{
        display: flex;
        justify-content: space-between;
        width: 100%;
        gap: 2rem;
        align-items: center;
        text-align: center;

        .typeContent{
          display: flex;
          justify-content: space-between;
          width: 100%;

          .envelop{
            width: 100%;
            background: #F4F4F4;
            padding: 0 .5rem .5rem .5rem;
            border: 1px solid #007AFF;
            &:hover, &:active{
            border-color: #0D509B;
          }
            .Dropdown{
            }
          }
          .TextField{
            width: 100% !important;
          }
          .rootClass{
            width: 90%;
          }
          .btnPadding{
            padding: .95rem;
            width: 100%;
            .btnText{
              font-size: 1rem;
              font-weight: var(--fw-semi-bold);
            }
          }
        }
      }
      .searchHeader{
        display: flex;
        width: 100%;
        align-items: center;
        text-align: center;
        gap: 2rem;
        flex-grow: 2;
        .manufacturer{
          display: flex;
          flex-grow: 1;
        }
        .background{
          background: #F4F4F4;
          border: 1px solid #999999;
          color: black;
          padding: .3rem;
        }
        button{
          flex-grow: 1;
        }
        .stest{
          width: 100% !important;
          flex-grow: 1;
        }
      }
    }
    .subLayout2{
      display: none;
      @include mixins.phoneAndTablet{
        display: block;
      }
      .inputManufacturer{
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: center;
        justify-content: center;
        margin: 2rem 0;
      }
      .subTitle{
        margin: 1rem 0;
        h1{
          font-weight: var(--fw-semi-bold);
          font-size: 2.5rem;
          @include mixins.tablet{
            font-size: 2rem;
          }
          @include mixins.phone{
            font-size: 1.5rem;
          }
        }
      }
    }
    .inputRoot{
      font-family: 'Montserrat', sans-serif !important;
      width: 35% !important;
      @include mixins.tablet{
        width: 50% !important;
      }
      @include mixins.phone{
        width: 100% !important;
      }
    }
    .labelTxt{
      label{
        font-size: 1rem;
        @include mixins.phone{
          font-size: .8rem;
        }
      }
    }
    .MuiFormLabel{
      font-size: 1rem;
    }
    .rootSearch {
      display: none;
      flex-direction: column;
      align-items: center;
      margin: 2rem 0;
      gap: 16px;
      @include mixins.phoneAndTablet{
        display: flex;

      }
    }
    .searchField {
      width: 40% !important;
      @include mixins.tablet{
        width: 50% !important;
        font-size: 1rem;
      }
      @include mixins.phone{
        width: 90% !important;
      }
    }

    .MuiFormLabel {
      font-family: 'Montserrat', sans-serif;
    }

    .applyButton {
      width: 15%;
      border-radius: 0;
      @include mixins.tablet{
        width: 20%;
        font-size: 1rem;
      }
      @include mixins.phone{
        width: 40%;
        font-size: 1rem;
      }
    }

    .applyButton:hover {
      background-color: #0056b3;
    }


    .productTab{
      display: flex;
      width: 100%;
      position: relative;
      .productTabLayout {
        display: flex;
        flex-direction: row;
        justify-content: center;
        cursor: pointer;
        width: 100%;
        gap: 1rem;
        margin: 2rem 0;
        @include mixins.phone{
          overflow: auto;
          flex-wrap: nowrap;
          justify-content: flex-start;
          padding: 0;
        }

        .card {
          position: relative;
          min-width: 17%;
          width: 17%;
          background: #fcfcfc;
          display: flex;
          flex-direction: column;
          justify-content: center;
          @include mixins.phone{
            min-width: 40%;
            width: 40%;
          }
          &:hover {
            color: #007AFF ;
            box-shadow: 0 0 1rem rgba(0, 0, 0, 0.4);
          }

          .cardImage {
            width: 100%;
            height: 82%;
          }

          .cardDetail {
            width: 100%;
            height: 18%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            color: #ffffff;
            font-size: 2rem;
            font-weight: var(--fw-semi-bold);
            @include mixins.tablet{
              font-size: 1.5rem;
            }
            @include mixins.phone{
              font-size: 1.2rem;
            }
          }

          .radioButton {
            position: absolute;
            top: 0;
            right: -1rem;
            @include mixins.phone{
              top: 0;
              right: 0;
            }
          }
        }
      }

    }


    .wrapperbtn{
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 4rem 0 2rem 0;
      .dropdown{
        width: 40%;
        cursor: pointer;
        position: relative;


        .selectBtn{
          height: 4rem;
          font-size: 1.4rem;
          display: flex;
          padding: 0 1.25rem;
          background: #F4F4F4;
          align-items: center;
          justify-content: space-between;
          &:hover,
          &:active {
            box-shadow: var(--shadow-el-13) hsla(0, 0%, 0%, 0.25);
            background: #007AFFFF;
            color: #ffffff;
          }
          .icon{
            font-size: 2rem;
          }
        }
        .content{
          padding: 1.25rem;
          margin-top: 1rem;
          background: #F4F4F4;
          position: absolute;
          z-index: 10;
          transition: none 0s ease 0s;
          width: 100%;

          .search{
            position: relative;
            .icon{
              left: 1rem;
              top: 1rem;
              color: #999;
              line-height: 3.3rem;
              position: absolute;
              font-size: 1.25rem;
            }
            input{
              height: 3.3rem;
              width: 100%;
              outline: none;
              font-size: 1rem;
              padding: 0 1rem 0 2.7rem;
              border: 1px solid #b3b3b3;
            }
          }
          .options{
            margin-top: 1.25rem;
            max-height: 16rem;
            overflow: auto;
            li{
              display: flex;
              cursor: pointer;
              align-items: center;
              height: 3.1rem;
              padding: 0 .8rem;
              font-size: 1rem;
            }
            li:hover{
              background: #f9f9f9;
            }

            .options::-webkit-scrollbar{
              width: .5rem;
            }
            .options::-webkit-scrollbar-track{
              background: #f1f1f1;
            }
            .options::-webkit-scrollbar{
              background: #ccc;
            }
          }
        }
      }
      .btn{
        width: 40%;
        height: 4rem;
        font-size: 1.4rem;
        display: flex;
        padding: 0 1.25rem;
        background: #F4F4F4;
        align-items: center;
        justify-content: center;
        border: none;
        cursor: pointer;

        &:hover,
        &:active {
          box-shadow: var(--shadow-el-13) hsla(0, 0%, 0%, 0.25);
          background: #007AFFFF;
          color: #ffffff;
        }

      }
    }
    .searchColor{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 0 0 2rem 0;
      .content{
        width: 40%;
        .inputColor {
          width: 100%;
          height: 100%;
          cursor: pointer;
          background: #F4F4F4;
          color: black;
          text-align: center;
          border: 2px solid transparent;
          outline: none;
          font-size: 1.5rem;
          transition: border-color 0.3s ease;

          &:focus {
            border-color: #007AFF;
            outline: none;
          }
        }

        .styleBtnApply{
          width: 30%;
          border-radius: 0;
        }
      }
    }
    .restLink{
      display: flex;
      color: #0072de;
      cursor: pointer;
      text-decoration: underline;
      justify-content: flex-end;
      font-size: 1.5rem;
      margin: 0 0 2rem 0;
    }

  }
  .subLayout2{
    display: flex;
    flex-direction: row;
    justify-content: center;
    cursor: pointer;
    width: 100%;
    gap: 1rem;
    margin-bottom: 2rem;

    .card{
      width: 19%;
      position: relative;
      background: #fcfcfc;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      &:hover {
        color: #007AFF ;
        box-shadow: 0 0 1rem rgba(0, 0, 0, 0.4);
      }

      .cardImage{
        position: relative;
        width: 100%;
        height: 82%;
      }
      .cardDetail{
        width: 100%;
        height: 18%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: #ffffff;
        font-size: 2rem;
        font-weight: var(--fw-semi-bold);
      }
    }
  }
  .subLayout3{
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    .subTitle{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      .icon{
        font-weight: var(--fw-semi-bold);
        font-size: 2rem;
        @include mixins.tablet{
          font-size: 1.5rem;
        }
        @include mixins.phone{
          font-size: 1.5rem;
        }
      }
      h1{
        font-weight: var(--fw-semi-bold);
        font-size: 2rem;
        @include mixins.tablet{
          font-size: 1.5rem;
        }
        @include mixins.phone{
          font-size: 1.5rem;
        }
      }
    }
    .descriptionColor {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      font-size: 1.5rem;
      text-align: left;
      @include  mixins.tablet{
        font-size: 1rem;
      }
      @include  mixins.phone{
        font-size: .8rem;
      }

      .firstTitle{
        margin: 2.1rem 0 1.4rem 0;
        @include mixins.phone{
          font-size: 1rem;
          margin: 1rem 0;
        }
      }
      .secTtile{
        display: flex;
        flex-direction: row;
        cursor: pointer;
        gap: .5rem;
        max-height: 2rem;
        margin: 2.1rem 0 1.4rem 0;
        color: #0072de;
        text-decoration: underline;
        .icon{
          font-size: 2rem;
          font-weight: var(--fw-regular);
        }
        @include mixins.phone{
          font-size: .7rem;
          margin: 1rem 0;
        }
      }
    }
    .rootSearch {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 2rem 0;
      gap: 16px;
    }
    .searchField {
      width: 40% !important;
      @include mixins.tablet{
        width: 50% !important;
        font-size: 1rem;
      }
      @include mixins.phone{
        width: 80% !important;
      }
    }

    .CardContainer {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(15.625rem, 1fr));
      grid-gap: 10px;
      width: 100%;
      height: 100%;
      margin: 1.2rem;
      @include  mixins.phone{
        grid-template-columns: repeat(auto-fill, minmax(8rem, 1fr));
        grid-gap: 10px ;
        margin:0;
      }
      .emptyContainer{
        width: 100%;
      }
    }
    .cardStyle{
      width: 15rem;
      @include  mixins.phone{
        width: 90%;
      }
    }
    .inputRoot{
      font-family: 'Montserrat', sans-serif !important;
      width: 35% !important;
      @include mixins.tablet{
        width: 50% !important;
      }
      @include mixins.phone{
        width: 90% !important;
      }
    }
    .labelTxt{
      label{
        font-size: 1rem;
        @include mixins.phone{
          font-size: .8rem;
        }
      }
    }
  }
  .emptyState {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 1.25rem;
    text-align: center;
    margin: 2rem 0;

    .title {
      font-size: 1.25rem;
      font-weight: var(--fw-semi-bold);
    }
    .rstButton{
      border-radius: 0;
      font-size: 1rem;
      @include mixins.phone{
        font-size: .9rem;
      }
    }
  }
  .subLayout4 {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    .title {
      font-size: 2rem;
      margin: 1rem 0;
      font-weight: var(--fw-semi-bold);
      color: black !important;
      @include mixins.tablet{
        font-size: 2rem;
      }
      @include mixins.phone{
        font-size: 1.5rem;
      }
    }
    .subTitle{
      width: 100%;
      font-size: 1.5rem;
      font-weight: var(--fw-bold);
      margin: 1rem 0;
      @include mixins.phone{
        font-size: 1.2rem;
      }
    }
    .productSelect{
      display: flex;
      flex-direction: row;
      width: 100%;
      gap: 3%;
      @media only screen and (max-width: 1550px) {
        flex-direction: column;
        align-items: center;
      }
      @include mixins.tablet{
        flex-direction: column;
        align-items: center;
      }
      @include mixins.phone{
        flex-direction: column;
        align-items: center;
      }
      .productSection{
        width: 15%;
        display: flex;
        flex-direction: column;
        align-items: center;
        @media only screen and (max-width: 1550px) {
          width: 100%;
          align-items: center;
        }
        .boxStyle{
          width: 100%;
          display: flex;
          justify-content: center;
          margin-bottom: 1rem;
          .productCode{
            height: 25%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            //color: #FFFFFF;
            font-size: 1rem;
            font-weight: var(--fw-semi-bold);
            text-align: center;
          }
          .productBox{
            display: flex;
            flex-direction: column;
            border-radius: 0;

            @include mixins.phone{
              width: 45%;
            }
            @include mixins.tablet{
              width: 25%;
            }
            @media only screen and (min-width: 1068px) and (max-width: 1550px) {
              width: 25%;
            }
          }
        }
        .cardStyle{
          width: 15rem;
          @include  mixins.phone{
            width: 90%;
          }
        }
        .customWith{
          @include  mixins.phone{
            width:9rem;
          }
        }
        .enableClick{
          &:hover {
            color: black;
            background: #f5f5f5;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
          }
        }
        .link{
          margin: 1rem 0;
          color: #0072de;
          cursor: pointer;
          text-decoration: underline;
          font-size: 1rem;
        }
      }

      .matchTable{
        width: 81%;
        @media only screen and (max-width: 1550px) {
          width: 100%;
        }
        .tableRoot{
          @include mixins.phoneAndTablet{
            width: 55rem;
          }
        }
        .icon{
          color: black !important;
        }
        .tabHeader{
          background-color: #999999;
          .txtheader{
            display: flex;
            align-items: center;
            justify-content: center;
            color: black;
            font-size: 1rem;
            font-weight: var(--fw-semi-bold);
          }
        }
        .tabBody{
          background-color: #F4F4F4;
        }
        .cell{
          width: 33% !important;
          border: 0.1rem solid #ffffff;;
          font-weight: var(--fw-semi-bold);
        }
        .accMatch{
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-end;
          width: 100%;
        }
        .colorLink{
          color: #0072de;
          cursor: pointer;
          text-decoration: underline;
          font-size: 1rem;
          @include mixins.phone{
            font-size: .8rem;
          }
        }
        .tempon{
          width: 40%;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          padding: 0;
          margin: 0;
          font-size: .9rem;
          @include mixins.phoneAndTablet{
            font-size: .8rem;
            padding: .1rem 0;
          }
        }
        .colorContent {
          display: flex;
          flex-direction: column;
          width: 58%;
          .colorDetails {
            display: flex;
            flex-direction: row;
            width: 100%;
            span {
              width: 50%;
              font-size: 1rem;
              font-weight: var(--fw-semi-bold);
              text-align: center;
              @include mixins.phone{
                font-size: .8rem;
              }
            }
          }

          .colorBox {
            display: flex;
            width: 100%;
            height: 1.5rem;
            overflow: hidden;
            .colorRight {
              flex: .9;
            }
            .colorLeft {
              flex: .9;
            }
          }
        }
        .centreBtn{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .buyBtn {
          border-radius: 0;
          font-size: 1rem;
          text-align: center;
          margin: .5rem;
          width: 60%;
          @include mixins.phone {
            font-size: .9rem;
            width: 100%;
          }
        }
      }
      }
    }
    .legendeMatch{
      border: 0.1rem solid #999999;
      padding: 1rem;
      .matchRow{
        display: flex;
        flex-direction: row;
        font-size: 1rem;
        @include mixins.phoneAndTablet{
          font-size: .8rem;
        }
        @include mixins.phone{
          flex-direction: column;
          align-items: flex-start;
        }
        span{
          font-weight: var(--fw-semi-bold);
          margin-right: .2rem;
        }
        .temponContainer{
          width: 20%;
          display: flex;
          justify-content: center;
          align-items: center;
          @media only screen and (max-width: 1550px) {
            width: 25%;
          }
          @include mixins.tablet{
            width: 40%;
          }
          @include mixins.phone{
            width: 100%;
          }

          .tempon{
            width: 90%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            padding: 0;
            margin: 0;
            font-size: .9rem;
            @include mixins.phoneAndTablet{
              font-size: .8rem;
              padding: .1rem 0;
            }
            @include mixins.phone{
              width: 100%;
            }
          }
        }
        .description{
          width: 100%;
          font-size: 1rem;
          justify-content: center;
          margin: .5rem 0;
          @include mixins.phoneAndTablet{
            font-size: .8rem;
          }
          @include mixins.phone{
            width: 80%;
          }
        }
      }
      .title{
        font-size: 1.5rem;
        @include mixins.phoneAndTablet{
          font-size: 1.2rem;
        }
        @include mixins.phone{
          margin: 0 0 .5rem 0;
        }
      }
      .subtitle{
        margin: 1rem 0;
        font-size: 1.2rem;
      }
    }
    .infoNote{
      width: 100%;
      margin: 1rem 0;
    }

  }
  .cardSelected{
    border: 2px solid #007AFF;
  }
  .visitPartner{
    width: 100%;
    height: 15rem;
    display: flex;
    flex-direction: row;
    margin: 2rem 0;
    @include mixins.phone{
      height: 10rem;
      text-align: center;
    }
    img{
      width: 50%;
    }
    .visitPage{
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 2rem;
      font-weight: var(--fw-semi-bold);
      background-color: #D9D9D9;
      @include mixins.tablet{
        font-size: 1.5rem;
      }
      @include mixins.phone{
        font-size: 1rem;
      }
    }
  }
  .colorCodeInfo{
    position: absolute;
    top: -16rem;
    right: 1rem;
    z-index: 2;
    width: 25rem;
    @include  mixins.tablet{
      right: -15rem;
      width: 14rem;
    }
    @include mixins.phone{
      top: -7rem;
      right: 0;
      width: 12rem;
    }
  }
  .noResult{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    font-size: 2.5rem;
    font-weight: var(--fw-semi-bold);
    margin: 2rem 0;
  }
}