$color_clear_text: #F4F4F4;
$color_link_text: #007AFF;
$color_text_dark: #333333;
$color_text_grey: #fcfcfc;

/*Normilized Colors*/

$color_primary: #007AFF;

$color_primary_darkest: #0E315D;
$color_primary_darker: #0D509B;
$color_primary_dark: #085CC5;

$color_primary_lightest: #EDFAFF;
$color_primary_lighter: #80BCFF;
$color_primary_light: #3A96FC;

$color_semantic_success_dark: #217239;
$color_semantic_success: #28A745;
$color_semantic_success_light: #A9D8B0;

$color_semantic_warning_dark: #CC8800;
$color_semantic_warning: #FFAA00;
$color_semantic_warning_light: #FFE5B3;

$color_semantic_alert_dark: #CC3E3E;
$color_semantic_alert: #FF4D4D;
$color_semantic_alert_light: #FFCCCC;

$color_neutral_dark: #000000;
$color_neutral_light: #FFFFFF;

$color_neutral_grey_dark: #333333;
$color_neutral_grey: #999999;
$color_neutral_grey_light: #F4F4F4;