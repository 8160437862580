@use '../../../../styles/abstracts/mixins';

.sidebarContainer {
  grid-area: left;
  width: 20%;
  padding: 0 2.4rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @include mixins.maxmq(lg) {
    display: none;
  }

  .header {
    width: 100%;
    font-size: 21px;

    .category {
      width: 100%;
      font-weight: var(--fw-semi-bold);
      padding: 10px 10px 0 10px;
    }

    .itemDescription {
      width: 100%;
      font-weight: var(--fw-semi-bold);
      padding: 5px 10px 10px 10px;
    }
  }

  &.mobileModal {
    align-items: start;
    width: 100%;
    @include mixins.maxmq(lg) {
      display: block;
    }

    .logoContainer {
      display: none;
    }
  }
}
