@use '../../styles/abstracts/mixins';

.header{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;


  .title{
    font-size: 2.5rem;
    font-weight: var(--fw-semi-bold);
    color: #007AFF ;
    width: 100%;
    text-align: center;
    @include mixins.phone{
      font-size: 1.5rem;
    }
  }

  .description{
    font-size:1.8rem ;
  }
  form{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 2rem;
    margin: 2rem 0;
    @include mixins.phone{
      flex-direction: column;
      align-items: flex-start;
      margin: 1rem 0;
      gap: 1rem;
    }

    .searchLayout {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      width: 50%;
      @include mixins.phone{
        width: 100%;
      }

      .searchInput {
        width: 100%;
        margin: 0;
        @include mixins.phone{
          width: 100%;
          height: 2.6rem;
        }
      }
      .inputclass{
        font-size: 1.4rem;
        @include mixins.phone{
          font-size: 1rem;
        }
      }
      .searchClass{
        @include mixins.phone{
          font-size: 2rem;
          top: .3rem;
        }
      }

      .btn {
        width: 20%;
        @include mixins.phone{
          width: 50%;
        }
      }
    }
    .buttons {
      width: 15%;
      @include mixins.phone {
        display: inline-flex;
        justify-content: space-between;
        width: 100%;
      }

      .applyBtn {
        width: 100%;
        @include mixins.phone {
          width: 49%;
        }
      }

      .clearBtn {
        display: none;
        @include mixins.phone {
          display: block;
          width: 39%;
          background: #F4F4F4;
          border-color: #F4F4F4;
          color: black;
        }
      }
    }
  }
}

.container {
  width: 100%;
  height: 40rem;
  display: inline-flex;
  flex-direction: row;
  @include mixins.maxmq(xl) {
    flex-direction: column;
  }

  .map {
    width: 50%;
    height: 100%;
    @include mixins.maxmq(xl) {
      width: 100%;
    }

    .icon {
      font-size: 1.4rem;
    }

    .infoWindow{
      display: flex;
      flex-direction: column;
      width: 20rem;
      gap: .5rem;
      @include mixins.phone{
        width: 15rem;
      }
      .title{
        font-weight: var(--fw-semi-bold);
        font-size: 1rem;
        @include mixins.phone{
          font-size: .8rem;
        }
      }
      p{
        font-size: .8rem;
        @include mixins.phone{
          font-size: .6rem;
        }
      }
      .links{
        color: #0072de;
        cursor: pointer;
        text-decoration: underline;
      }
      .Website{
        background: #F4F4F4;
        color: black;
        width: 55%;
        height: 2rem;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        border: .1rem solid black;
        font-size: .8rem;
        @include mixins.phone{
          width: 65%;
          font-size: .6rem;
        }
      }
    }
  }

  .list {
    @include mixins.maxmq(xl) {
      margin-top: 1rem;
      width: 100%;
    }
    width: 50%;
    height: 100%;
    overflow: scroll;
    display: inline-flex;
    flex-direction: column;
    border-top: 1px solid black;
    .selected{
      border: .3rem solid #007AFF;
    }
    .address {
      display: inline-flex;
      flex-direction: column;

      .title {
        width: 100%;
        padding: 0.3rem 2rem;
        display: inline-flex;
        justify-content: space-between;
        border-bottom: 1px solid #8c8c8c;
        cursor: pointer;
        z-index: 2;

        &:hover {
          background: #F4F4F4;
          box-shadow: 0 0 0.2rem rgba(0, 0, 0, 0.4);
        }

        .description {
          width: 80%;
          font-size: 1.6rem;
          font-weight: var(--fw-semi-bold);
          padding-right: 1rem;
          @include mixins.phone{
            font-size: 1rem;
          }
        }

        .arrow {
          cursor: pointer;
          font-size: 2rem;
          opacity: 1;
          transition: ease-in-out 0.3s;

          &.active {
            background: #F4F4F4;
            transform: rotate(180deg);
          }
        }

        &.active {
          background: #F4F4F4;
        }

      }

      .details {
        width: 100%;
        display: inline-flex;
        padding: 0 1rem;
        gap: 1rem;
        transition: ease 0.5s;
        opacity: 0;
        flex-direction: column;

        &.active {
          padding: 0.5rem 1rem;
          background: #F4F4F4;
          opacity: 1;
        }

        .top{
          display: flex;
          flex-direction: row;
          @include mixins.phone{
            display: flex;
            flex-direction: column;
            gap: .2rem;
          }

          .left {
            display: inline-flex;
            flex-direction: column;
            @include mixins.phone{
              width: 100%;
              display: flex;
              flex-direction: column;
              gap: .2rem;
            }

            .information {
              display: inline-flex;

              .label {
                width: 6rem;
                @include mixins.phone{
                  font-size: 0.875rem;
                }

              }

              .value {
                font-weight: var(--fw-semi-bold);
                @include mixins.phone{
                  font-size: 0.875rem;
                }
              }
              .highlight{
                color: #0072de;
              }

            }
          }

          .right {
            display: inline-flex;
            flex-direction: column;
            margin-left: 1rem;
            @include mixins.phone{
              width: 100%;
              display: flex;
              flex-direction: row;
              gap: .2rem;
            }

            .label {
              @include mixins.phone{
                font-size: 0.875rem;
              }
            }

            .value {
              font-weight: var(--fw-semi-bold);
              @include mixins.phone{
                font-size: 0.875rem;
              }
            }

            .links{
              color: #0072de;
              cursor: pointer;
              text-decoration: underline;
            }
          }
        }

        .bottom {
          width: 100%;
          display: inline-flex;
          flex-direction: column;

          .information {
            display: inline-flex;
            @include mixins.phone{
              display: flex;
              flex-direction: row;
              justify-content: space-between;
            }

            .label {
              width: 10rem;
              @include mixins.phone{
                width: fit-content;
                font-size: 0.875rem;
              }
            }

            .value {
              font-weight: var(--fw-semi-bold);
              @include mixins.phone{
                font-size: 0.875rem;
              }
            }
          }
        }
      }

    }
  }
}