@use '../../../styles/abstracts/mixins';

.cartItem {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0.625rem 0.625rem 0.625rem 0.625rem;

  .header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .title {
      font-weight: var(--fw-semi-bold);
    }
  }

  .product{
    display: flex;
    flex-direction: row;
    gap: 1rem;

    .image{
      height: 100%;
      .productImage {
        width: 8rem;
        height: 100%;
        @include mixins.phone{
          width: 7rem;
        }
      }
    }

    .specification {
      display: flex;
      flex-direction: row;
      gap: 0.5rem;
      height: 4rem;
      font-size: 1.2rem;
      @include mixins.phone{
        font-size: 1rem;
      }

      .color {
        margin-top: auto;
        margin-bottom: auto;
        width: 3rem;
        height: 3rem;
        border-radius: 50%;
        text-align: center;
        border: 0.05rem solid var(--clr-grey-400) !important;
        @include mixins.phone{
          width: 1.5rem;
          height: 1.5rem;
        }
      }

      .colorDesc {
        margin-top: auto;
        margin-bottom: auto;
        width: 70%;
        white-space: nowrap;
      }
    }


    .detail{
      font-weight: var(--fw-regular);
      margin-bottom: .3rem;
      font-size: 1rem;
      @include mixins.phone{
        font-size: .8rem;
      }
    }

  }



  .details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    @include mixins.phone{
      gap: .3rem;
    }

    .line1 {
      font-size: 1.4rem;
      display: flex;
      justify-content: space-between;
      @include mixins.phone{
        font-size: 1.3rem;
        justify-content: normal;
        gap: 1rem;
      }

      .title {
        font-weight: var(--fw-semi-bold);
      }

      .amount {
        margin-top: auto;
        margin-bottom: auto;
      }
    }

    .line2 {
      font-size: 1rem;
      font-weight: var(--fw-regular);
    }

    .line3 {
      font-size: 1rem;
      display: flex;
      justify-content: space-between;
      @include mixins.phone{
        flex-direction: column;
      }

      .specification {
        display: flex;
        flex-direction: row;
        gap: 0.5rem;
        height: 4rem;

        .color {
          margin-top: auto;
          margin-bottom: auto;
          width: 3rem;
          height: 3rem;
          border-radius: 50%;
          text-align: center;
        }

        .colorDesc {
          margin-top: auto;
          margin-bottom: auto;
          width: 70%;
          white-space: nowrap;
        }
      }

      .price {
        margin-top: auto;
        margin-bottom: auto;
        font-weight: var(--fw-semi-bold);
        width: 55%;
        text-align: right;
        white-space: nowrap;
      }
    }
  }
}
