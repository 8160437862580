@use '../../../styles/abstracts/mixins';
.loading {
	width: 100%;
	display: flex;
	justify-content: center;
	object {
		width: 30%;
		@include mixins.maxmq(lg) {
			width: 70%;
		}
	}
}
.season {
	padding-top: 10rem;
}
