.layout {
  position: relative;
  background: #F4F4F4;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  min-width: 20rem;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
    cursor: pointer;
    padding: 0.5rem;
    border: 2px solid #007AFF;
    border-radius: 3px;

    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }

    label {
      margin: auto 0;
      font-size: 1.5rem;
      line-height: 2rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .icon {
      font-size: 3rem;
      transition: transform 0.2s ease-in-out;
    }

    .icon.open {
      transform: rotate(0deg);
    }
  }

  .listLayout {
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 10;
    top: 100%;
    left: 0;
    width: 100%;
    background: #F4F4F4;
    border: 2px solid #007AFF;
    border-top: 1px solid #B4B4B4;
    border-radius: 3px;
    transition: opacity 0.2s ease-in-out;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-10px);

    .list {
      max-height: 25rem;
      overflow-y: auto;

      .list {
        overflow-y: unset;
        max-height: unset;
      }

      .element {
        padding: 1rem;
        cursor: pointer;
        font-size: 1.2rem;

        &:hover {
          background-color: rgba(0, 0, 0, 0.2);
        }

        &:not(:last-child) {
          border-bottom: 1px solid white;
        }
      }

      .group {

        h1 {
          padding: 1rem 1rem 0 1rem;
          font-size: 1.2rem;
          font-weight: var(--fw-semi-bold);
        }

        &:not(:last-child) {
          border-bottom: 1px solid white;
        }
      }

      .element.group {
        border: none;
      }

      .multiselection.element {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: auto;

        input {
          margin: 0;
          appearance: auto;
          opacity: 1;
          width: 1.5rem;
          height: 1.5rem;
          background: #F4F4F4;
          border: 2px solid #007AFF;
          border-radius: 3px;
          cursor: pointer;
          transition: background-color 0.2s ease-in-out;
        }

        label {
          padding: 0;
          margin-left: 0.5rem;
          font-size: 1.2rem;
          line-height: 2rem;
        }
      }

    }
  }

  .listLayout.open {
    opacity: 1;
    visibility: inherit;
  }
}