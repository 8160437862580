@use '../../../styles/abstracts/mixins';

.productCardContainer {
  cursor: pointer;
  position: relative;
  width: 16rem;
  height: 30rem;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /*Elevation variables did not match with those set for the mockup*/
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.25);
  transition: all 0.5s ease-out;

  &:hover {
    color: #1976d2;
    background: #fcfcfc;
    /*Elevation variables did not match with those set for the mockup*/
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.4);
  }

  .productCardFamily {
    font-size: 1.4rem;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    color: white;

    p {
      font-weight: var(--fw-semi-bold);
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;
    }
  }

  .productCardFavoriteOutlined {
    position: absolute;
    z-index: 2;
    top: 0.5rem;
    right: 0.5rem;
    color: #d9d9d9;
    font-size: 2.625rem;
    transition: all 0.5s ease-out;
    opacity: 1;
  }

  .productCardFavoriteFilled {
    position: absolute;
    z-index: 2;
    top: 0.5rem;
    right: 0.5rem;
    font-size: 2.625rem;
    transition: all 0.5s ease-out;
    color: #c90909;
    opacity: 0;

    &:hover {
      opacity: 1;
    }

    &.active {
      opacity: 1;
    }
  }

  .productCardImage {
    position: relative;
    width: 100%;
    height: 50% !important;
  }

  .productCardDetails {
    align-self: end;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    height: 100%;

    .productPrice {
      font-size: 1.6rem;

      .productAmount {
        color: var(--clr-red-700);
      }
    }

    .productCardDescription {
      font-size: 1.2rem;
      min-height: 4ch;
      line-height: 130%;
      margin: 0 0.5rem;

      .description {
        font-weight: var(--fw-semi-bold);
      }

      p {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .productCardColor {
      font-size: 1.1rem;
    }

    .productCardFormat {
      font-size: 1.1rem;
      min-height: 2ch;
      line-height: 130%;
      margin: 0.5rem;

      p {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .button {
      width: 100%;
      height: 3rem;
      font-size: 1.2rem;
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
      color: white;
      background-color: #1976D2;

      p {
        font-weight: var(--fw-semi-bold);
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
      }
    }
  }
}

