@use '../../../styles/abstracts/mixins';

.bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 20;
  background: rgba(28, 28, 28, 0.19);
  backdrop-filter: blur(6px);
  opacity: 1;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-name: modal-video;
  -webkit-transition: opacity 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out;
  -ms-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out;
}

.align {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.content {
  width: 800px;
  height: 500px;
  box-shadow: 0 100px 80px rgba(184, 184, 184, 0.07),
  0 25.8162px 19px 4px rgba(178, 178, 178, 0.0456112),
  0 7.779px 7.30492px rgba(0, 0, 0, 0.035),
  0 1.48838px 2.0843px rgba(0, 0, 0, 0.0243888);
  border-radius: 20px;
  background: transparent;
  color: #000;
  margin: 0 4rem;
}

.close {
  background-color: white;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  bottom: 50px;
  width: 32px;
  height: 32px;
  padding: 0;
}

.video_align {
  display: flex;
  position: relative;
  bottom: 37px;
}

.video_style {
  border-radius: 20px;
  z-index: 100;
}

.spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.spinner {
  animation: spin 2s linear infinite;
  font-size: 40px;
  color: #1b6aae;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 800px) {
  .content {
    margin: 0 1rem;
    width: 100%;
  }
  .video_style {
    width: 100%;
  }
}

@media screen and (max-width: 499px) {
  .content {
    background: transparent;
  }
  .video_align {
    bottom: 0;
  }
  .video_style {
    height: auto;
  }
}

