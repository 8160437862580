@use '../../../../../styles/abstracts/mixins';

.section{
  width: 100%;
  display: none;
  @include mixins.phone{
    display: block;
  }
  .productInfoSection {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem 1.5rem 0;
    width: 100%;

    .productInfoContainer {
      width: 100%;
      padding: .5525rem 0;

      .layout {
        display: flex;
        flex-direction: column;

        .toggle{
          justify-content: end;
        }
        .resetBtn{
          display: flex;
          justify-content: end;
          color: #007AFF;
          text-decoration: underline;
          cursor: pointer;
          font-size: 1rem;
          border: none;
          background: transparent;
        }
        .labelClassName{
          font-size: 1rem;
        }

        h3 {
          margin: 1rem 0 0.875rem 0;
          font-size: 1.25rem;
          font-weight: var(--fw-semi-bold);
          line-height: 1.8rem;
        }
        .search{
          margin-bottom: 1rem;
          input{
            font-size: 1rem;
          }
        }

        .element {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          background: #F4F4F4;
          padding: 0.5rem;
          margin-bottom: 1rem;
          height: 5rem;

          .text {
            display: flex;
            align-items: center;

            p {
              text-transform: uppercase;
              font-size: 1rem;
            }
          }
        }

        .selectedContainer{
          height: 8rem !important;
        }

        .containerMobile{
          position: relative;
          background-color: #F4F4F4;
          width: 100%;
          height: 28rem;
          display: flex;
          box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
          flex-direction: column;

          .buttons{
            display: flex;
            width: 100%;
            grid-gap: 0;
            gap: .1rem;
            .btn{
              border-radius: 0;
              width: 50%;
              font-size: 1rem;
              margin: 0;
            }
            .disable{
              background: var(--clr-grey-150);
              border-color: var(--clr-grey-150);
              color: var(--clr-grey-400);
            }
          }
          .scrollableContainer{
            flex-direction: column;
            align-items: center;
            gap: .5rem;
            padding: 1.5rem 0;
            display: flex;
            overflow: auto;
            height: 100%;
            width: 100%;

            .sbuttons{
              padding: 0.5rem;
              margin-top: 0;
              font-size: 1.5rem;
              line-height: 1.8rem;
              height: 5rem !important;
              width: 80% !important;
            }

            .colorCardContainer {
              width: 80%;
              cursor: pointer;
              position: relative;
              background: #ffffff;
              display: flex;
              flex-direction: row;
              transition: all 0.2s ease-out;

              &:hover {
                color: #1976d2;
                background: #fcfcfc;
              }

              .favoriteOutlined,
              .favoriteFilled {
                position: absolute;
                z-index: 2;
                top: 25px;
                right: 15px;
                color: #d9d9d9;
                font-size: 25px;
                transition: all 0.5s ease-out;
              }

              .favoriteOutlined {
                opacity: 1;
              }

              .favoriteFilled {
                color: #c90909;
                opacity: 0;

                &:hover {
                  opacity: 1;
                }

                &.active {
                  opacity: 1;
                }
              }
              .colorCardImageDiv {
                width: 50%;
                height: 80px;
                position: relative;
                border: 1px solid #333333;
              }

              .colorCardDetails {
                width: 50%;
                height: 80px;
                align-self: end;
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding: 2rem 1rem 2rem 1rem;

                p {
                  font-size: 1rem;
                  text-align: center;
                }
              }
              .colorFavorite{
                width: 20%;
                background: #ffffff;
              }
              .colorcard{
                display: flex;
                flex-direction: row;
                width: 100%;
                .colorCardDetails {
                  width: 50%;
                  height: 80px;
                  align-self: end;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  padding: 2rem 1rem 2rem 1rem;

                  p {
                    font-size: 1rem;
                    text-align: center;
                  }
                }
                .colorCardDetailsLogedInd{
                  width: 60%;
                }
                .colorCardImageDiv {
                  width: 50%;
                  height: 80px;
                  position: relative;
                  border: 1px solid #333333;
                }
                .colorCardImageDivLogedInd{
                  width: 40%;
                }

              }
            }
            .selected {
              border: 3px solid #007AFF !important;
            }
          }
          .rectangle {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 2rem;
            background-color: rgb(219 219 219 / 38%);
            z-index: 1;
          }

        }

        .inputJoint {
          margin-top: 0;
          display: block;
          select {
            background-color: #f5f5f5;
            font-size: 1rem;
          }
        }

        .after {
          &:after {
            top: 0.8rem;
          }
        }

        .information {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin-left: 2rem;

          .prices {
            p {
              font-size: 1rem;

              span {
                font-weight: var(--fw-semi-bold);
              }

              .price {
                color: #007AFF;
              }
            }

            .iconContainer {
              display: flex;
              justify-content: center;
              align-items: center;

              .icon {
                font-size: 1.5rem;
              }
            }
            .mobileMessages{
              display: flex;
              .helper {
                font-size: 1rem;
                .link {
                  font-weight: var(--fw-semi-bold);
                  font-size: 1rem;
                  cursor: pointer;
                  text-decoration: underline;
                  text-align: center;
                  color: #007AFF;
                }
                .loginlink {
                  font-weight: var(--fw-medium);
                }
              }
            }
            .warnings {
              margin: 1rem 0;

              .warning {
                display: flex;
                flex-direction: row;
                gap: 0.2rem;
                color: #E03229;
                font-size: 1.25rem;


                &.shakeError {
                  padding: 0.5rem;
                  animation: shake 0.2s ease-in-out 0s 2;
                  box-shadow: 0 0 0.5em red;
                }
              }
            }

            .info {
              margin: 1rem 0;
              display: flex;
              flex-direction: row;
              gap: 0.2rem;
              color: #28a745;
              font-size: 1rem;
            }
          }
        }

      }

      .qty {
        opacity: 1;
        pointer-events: auto;
        display: flex;
        flex-direction: column;

        h1 {
          font-size: 1rem;
          margin-top: auto;
          margin-bottom: auto;
        }

        .numbers {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          .center {
            flex-direction: column;
            display: flex;
            justify-content: space-between;
            align-items: self-start;
            gap: 2rem;
            width: 100%;

            .row{
              flex-direction: column;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;

              .qtyButtons {
                display: inline-flex;
                justify-content: flex-start;
                margin: auto 0;
                width: 100%;

                .buttons {
                  margin: 0;
                  width: 30%;
                  height: 5rem;
                  border-radius: 0;
                  font-size: 3rem;
                }

                .textInput {
                  margin: 0;
                  font-size: 3rem;
                  width: 40%;
                  height: 5rem;
                }

                .input {
                  width: 100%;
                  height: 100%;
                  margin: 0;
                  border-top: 0.125rem solid #3e4042;
                  border-bottom: 0.125rem solid #3e4042;
                  border-right: 0.125rem solid #3e4042;
                  border-left: 0.125rem solid #3e4042;
                  border-radius: 0;
                  text-align: center;
                  padding: 0;
                }
              }

              h1 {
                width: 15rem;
                font-size: 1.25rem;
                font-weight: var(--fw-semi-bold);
                text-align: center;
                line-height: 200%;
                border-right: 0.125rem solid #3e4042;
                border-left: 0.125rem solid #3e4042;
                border-bottom: 0.125rem solid #3e4042;
              }

              h1 {
                text-align: center;
                width: 100%;
              }
            }

            .right {
              width: 100%;
              .container {
                width: 100%;
                border: 1px solid #333333;
                border-radius: 7px;
                padding: .5rem;
                cursor: pointer;

                .first {
                  display: flex;
                  flex-direction: row;
                  gap: .5rem;
                  margin-bottom: .5rem;

                  .iconContainer {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    .calculator {
                      font-size: 2.5rem;
                    }

                  }

                  h1 {
                    font-size: 1.25rem;
                    font-weight: var(--fw-semi-bold);
                  }
                }

                p {
                  font-size: 1rem;
                }
              }
            }
          }

        }

        .addCartButton {
          width: 100%;
        }
      }

      Button {
        margin-top: 1.2rem;
      }

      .ColorNotFound{
        display: flex;
        justify-content: center;
        text-align: center;
        flex-direction: column;
        font-size: .75rem;
        padding: .6rem 0;
        font-weight: var(--fw-semi-bold);
        background-color: #007AFF;
        color: var(--clr-grey-50);
      }

    }
  }



}
