@use '../../../styles/abstracts/mixins';

.title {
  width: 100%;
  font-size: 2.5rem;
  font-weight: var(--fw-semi-bold);
  line-height: 125%;
  text-align: center;
  align-self: center;
  padding: 1rem 0 0.625rem 0;
  @include mixins.tablet {
    font-size: 2rem;
  }
  @include mixins.phone {
    font-size: 1.25rem;
    padding: 0;
    white-space: nowrap;
  }
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .carousel {
    position: relative;
    display: inline-flex;
    flex-direction: column;
    width: 100%;
    @include mixins.phoneAndTablet {
      height: 100%;
    }

    .slider {
      padding: 1rem 1rem;
      width: 100%;
      position: relative;
      margin: 0 auto;
      @include mixins.phone {
        padding: 0.625rem 0 1rem 0;
      }

      .slide {
        margin: 1rem;
        position: relative;

        .card {
          width: 90%;
          //height: 100%;
          cursor: pointer;
          border-radius: 0.2rem;
          overflow: hidden;
          position: relative;
          color: var(--text);
          display: inline-flex;
          flex-direction: column;

          h1 {
            margin: 0;
            padding: 0 1rem;
            font-weight: var(--fw-semi-bold);
            font-size: 1.4rem;
            background-color: #F4F4F4;

            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            @include mixins.phoneAndTablet {
              font-size: .9rem;
            }
          }

          p {
            height: 10ch;
            padding: 0.5rem 1rem 1rem;
            font-weight: var(--fw-regular);
            font-size: 0.95vw;
            background-color: #F4F4F4;

            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 4;
            @include mixins.phoneAndTablet {
              font-size: 0.8rem;
            }
          }

          img {
            width: 100%;
            object-fit: cover;
            overflow: hidden;
            margin: auto;
            display: block;
            box-sizing: border-box
          }

          transition: ease-in-out 0.1s;

          &:hover {
            transform: translateY(-0.188rem) scale(1.03);
            box-shadow: 0 0.313rem 0.938rem rgba(0, 0, 0, 0.35);
            border: 0.3rem solid;
            border-image-slice: 1;
            border-image-source: linear-gradient(#00b5ee, #0937ad);
          }
        }
      }
    }

    .btnLayout {
      position: absolute;
      display: inline-flex;
      justify-content: space-between;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      padding: 0 1rem;
      @include mixins.tablet {
        display: none;
      }
      @include mixins.phone {
        display: none;
      }

      .btn {
        z-index: 2;
        width: 3rem;
        height: 3rem;
        margin: 0;
        padding: 0;
        border: 0;
        border-radius: 15%;

        .icon {
          font-size: 3rem;
          color: var(--clr-grey-400);
        }
      }

      .btnBack {
        left: 0;
      }

      .btnNext {
        left: 100%;
      }

      button {
        background-color: var(--clr-grey-800);
      }

      button[disabled] {
        opacity: 0;
      }
    }

    .pickerDots {
      display: inline-flex;
      justify-content: center;
      gap: 0.5rem;
      padding: 1rem 0;


      .icon {
        width: 1rem;
        height: 1rem;
        cursor: pointer;
        border-radius: 50%;
        border-width: 0;
      }

      button {
        background-color: var(--clr-grey-200);
      }

      button[disabled] {
        opacity: 1;
        background-color: black;
      }
    }
  }
}