@use '../../../styles/abstracts/mixins';

.backdrop {
  @include mixins.phoneAndTablet {
    display: block;
    visibility: hidden;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 2000;
    background: #000;
    opacity: 0;

    &.open {
      visibility: visible;
      opacity: 0.68;
      transition: opacity 0.3s cubic-bezier(0.6, 0, 0.4, 1);
    }
  }
}

.wrapper {
  display: none;
  @include mixins.phoneAndTablet {
    display: flex;
    overflow: hidden;
    position: fixed;
    right: 0;
    transform: translateX(360px);
    top: 0;
    bottom: 0;
    width: 22.5rem;
    height: 100%;
    z-index: 2200;
    transition: transform 0.3s cubic-bezier(0.6, 0, 0.4, 1);
  }

  &.open {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    @include  mixins.phoneAndTablet{
      width: 100%;
    }
  }
  .main {
    @include mixins.phoneAndTablet {
      left: 0;
      transition: left 0.5s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.5s cubic-bezier(0.4, 0, 0.2, 1) 0.2s;
      opacity: 1;
      position: relative;
      overflow-x: hidden;
      overflow-y: auto;
      width: 100%;
      padding: 8rem 1.5rem 0;
      z-index: 2300;
    }

    .btnApply{
      @include mixins.phoneAndTablet {
        &.open {
          display: block;
          opacity: 1;
        }

        position: absolute;
        cursor: pointer;
        top: 1rem;
        right: 1rem;
        font-size: 1rem;
        z-index: 2300;
        opacity: 0;
        height: 2.5rem;
        width: 10rem;
        -webkit-transition: opacity .2s cubic-bezier(0.4, 0, 0.2, 1);
        transition: opacity .2s cubic-bezier(0.4, 0, 0.2, 1);
      }
    }
    .btnClear{
      @include mixins.phoneAndTablet {
        &.open {
          display: block;
          opacity: 1;
        }

        position: absolute;
        cursor: pointer;
        top: 4rem;
        right: 1rem;
        font-size: 1rem;
        z-index: 2300;
        opacity: 0;
        height: 2.5rem;
        width: 10rem;
        -webkit-transition: opacity .2s cubic-bezier(0.4, 0, 0.2, 1);
        transition: opacity .2s cubic-bezier(0.4, 0, 0.2, 1);
      }
    }
  }


  .iconClose {
    @include mixins.phoneAndTablet {
      &.open {
        display: block;
        opacity: 1;
      }

      position: absolute;
      cursor: pointer;
      top: 1.7rem;
      left: 1.5rem;
      font-size: 1.8rem;
      z-index: 2300;
      opacity: 0;
      -webkit-transition: opacity .2s cubic-bezier(0.4, 0, 0.2, 1);
      transition: opacity .2s cubic-bezier(0.4, 0, 0.2, 1);
    }
  }

  .filters{
    position: absolute;
    cursor: pointer;
    top: 1.8rem;
    left: 3.5rem;
    font-size: 1.2rem;
    z-index: 2300;
  }

  .iconBack {
    @include mixins.phoneAndTablet {
      &.open {
        display: block;
        opacity: 1;
      }

      position: absolute;
      cursor: pointer;
      left: 1rem;
      top: 0.5rem;
      font-size: 36px;
      z-index: 2300;
      opacity: 0;
      -webkit-transition: opacity .2s cubic-bezier(0.4, 0, 0.2, 1);
      transition: opacity .2s cubic-bezier(0.4, 0, 0.2, 1);
    }
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #fff;
  }
}