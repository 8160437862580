@use "../../styles/abstracts/variables" as *;

.list_card_item {
    display: flex;
    flex-direction: column;
    box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.14);
    width: 100%;

    & article {
        padding: 0.625rem;
        border: 1px solid $color_neutral_grey_light;
        border-bottom: 1px $color_neutral_grey solid;

        &:last-child {
            border-bottom-color: $color_neutral_grey_light;
        }
    }
}