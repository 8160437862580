@use '../../../styles/abstracts/mixins';
@use "../../../styles/abstracts/media-query" as *;


.wrapper {
  &:before {
    background-color: #f4f4f4;

  }

  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
  min-width: 319px;
  flex-wrap: nowrap;

  header {
    display: block;
    background-color: #fff;

    .innerHeader {
      width: 100%;
      margin: 0 auto;
      box-sizing: border-box;

      .logo {
        background-color: #ffffff;
        margin: 1rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        a {
          margin: 0;
          padding: 0;
          font-size: inherit;
          line-height: inherit;

          img {
            height: 2rem;
          }
        }

        .rli {
          position: relative;
          vertical-align: middle;
          padding-top: 1rem;

          .icon {
            font-size: 24px;
            cursor: pointer;

          }

          p {
            display: inline-block;
            overflow: hidden;
            position: relative;
            padding: 0.5rem;
            border-radius: 1.5rem;
            color: #000;
            font-size: 14px;
            font-weight: var(--fw-semi-bold);
            line-height: 1.33;
            cursor: pointer;

            &.active {
              color: #fff;
              background-color: #000;
            }
          }
        }
      }
    }
  }

  main {
    flex-grow: 0;
    margin-bottom: -1px;
    padding-top: 1.5rem;

    .innerMain {
      max-width: 40rem;
      margin: 0 auto;
      @include mixins.minmq(lg) {
        background-color: #fcfcfc;
        border-radius: 1.625rem;
        padding: 3.75rem 4.5rem;
      }

      .container {
        border: none;
        background-color: transparent;
        margin-bottom: 0;
        @include mixins.minmq(lg) {
          padding: 0;
        }

        .title {
          h1 {
            margin: 0.75rem auto 3rem;
            font-size: 1.6875rem;
            font-weight: 500;
            line-height: normal;
          }
        }

        .content {
          .description {
            padding-bottom: 1rem;
          }

          .backLogin {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            width: 100%;
            align-items: center;
            cursor: pointer;
            margin-top: 1rem;
            color: #2d2d2d;

            p {
              padding-left: 0.5rem;
            }

            .icon {
              font-size: 20px;
            }
          }

          .formWrapper {
            display: block;

            form {
              display: block;

              fieldset {
                display: block;
                min-inline-size: min-content;

                legend {
                  display: none;
                }

                div {
                  display: block;
                }

                .error {
                  color: #db332a;
                  margin-bottom: 1rem;
                }
              }

              .forgotPassword {
                max-width: 24rem;
                margin: 2.25rem auto 1.25rem;

                .forgotPassword_btn {
                  width: 100%;
                  text-align: center;
                  padding: 0;
                  margin: 0 0 1.875rem 0;

                  button {
                    padding: 1rem;
                    min-width: 16rem;
                    min-height: 2.75rem;
                    margin: 1.5rem auto;
                    text-align: center;
                    vertical-align: middle;
                    font-size: 17px;
                    border-radius: 1.5rem;
                    line-height: 1.27;
                    letter-spacing: 0.01em;
                    word-wrap: break-word;
                    word-break: break-word;
                    position: relative;
                    display: block;
                    cursor: pointer;
                    font-style: inherit;
                    font-variant: inherit;
                    font-family: inherit;
                    text-decoration: none;
                    box-sizing: border-box;
                    -webkit-box-align: center;
                    -webkit-align-items: center;
                    align-items: center;
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;

                    background-color: #0381fe;
                    border: 1px solid #0381fe;
                    color: #fff;

                    &.disable {
                      opacity: 0.4;
                    }
                  }

                  ul {
                    list-style: none;
                    display: block;
                    margin-block-start: 1em;
                    margin-block-end: 1em;
                    margin-inline-start: 0;
                    margin-inline-end: 0;

                    li {
                      margin-bottom: 10px;
                      list-style: none;
                      text-align: -webkit-match-parent;

                      a {
                        text-align: left;
                        width: 100%;
                        font-size: 15px;
                        line-height: 1.6;
                        color: #636363;
                        font-weight: 500;
                        margin: auto 2px;
                        display: inline-block;
                        letter-spacing: -0.1px;

                        background-color: transparent;
                        text-decoration: underline;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  footer {
    flex-shrink: 0;
    padding: 0.5em;
    margin-top: auto;
    text-align: inherit;
    background-color: #fff;

    .innerFooter {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin: 0 auto;
      font-size: 12px;
      max-width: 1116px;
      font-weight: 300;
      opacity: 0.8;

      .logoFooter {
        order: 1;
        width: 100%;
        flex: none;
        text-align: right;
        margin-top: 23px;
        margin-bottom: 6px;
        opacity: 1;

        img {
          width: 201px;
          height: auto;
          opacity: 1;
        }
      }

      .rightFooter {
        order: 3;
        width: auto;
        font-size: 12px;
        color: #888888;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        opacity: 1;
      }

      .linksFooter {
        order: 2;
        max-width: 650px;
        display: inline-block;
        margin-left: 0;
        margin-right: inherit;

        a {
          position: relative;
          text-decoration: none;
          display: inline-block;
          vertical-align: middle;
          font-size: 12px;
          font-weight: 500;
          color: #252525;
          padding: 2px 15px;
          opacity: 1;
        }
      }
    }
  }
}
