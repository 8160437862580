@use '../../styles/abstracts/mixins';

.layout {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  background-color: #333333;

  .left {
    position: absolute;
    bottom: 0;
    height: 60vh;
    max-width: 100vw;
    @include mixins.phone{
      max-width: 50vw;
    }
  }

  .right {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 30vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    @include mixins.phone{
      top: 20rem;
      right: 10rem;
    }

    .icon {
      height: 60vh;
    }

    .logo {
      margin-bottom: 5rem;
      height: 10vh;
    }

  }

  .text {
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    align-items: center;
    text-align: center;
    width: 60%;
    margin: 5rem 0 0 10rem;
    line-height: normal;
    @include mixins.phone {
      margin: 5rem 0 0 5rem;
      padding-bottom: 3rem;
    }

    h1 {
      font-weight: var(--fw-bold);
      font-size: 2.4rem;
      color: #1976D2;
      @include mixins.phone {
        font-size: 1.6rem;
      }
    }

    p {
      font-size: 1.8rem;
      color: var(--clr-grey-50);
      @include mixins.phone {
        font-size: 1.2rem;
      }
    }

    .bleu {
      color: #1976D2;
    }

    .bold {
      font-weight: var(--fw-bold);
    }

    .link {
      color: #1976D2;
      text-decoration: underline;
      font-weight: var(--fw-bold);
      font-size: 2rem;
      @include mixins.phone {
        font-size: 1.4rem;
      }

    }
  }
}