@use '../../styles/abstracts/mixins';

.bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 20000000;
  background: rgba(28, 28, 28, 0.19);
  backdrop-filter: blur(6px);
  opacity: 1;
}

.align {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 200000000;
}

.content {
  width: 700px;
  height: 600px;
  box-shadow: 0 100px 80px rgba(184, 184, 184, 0.07),
  0 25.8162px 19px 4px rgba(178, 178, 178, 0.0456112),
  0 7.779px 7.30492px rgba(0, 0, 0, 0.035),
  0 1.48838px 2.0843px rgba(0, 0, 0, 0.0243888);
  border-radius: 20px;
  background: white;
  color: #000;
  margin: 0 4rem;
  position: relative;
  @include  mixins.phone{
    margin: 0 1rem;
  }
  .closeButton {
    position: absolute;
    top: 1.2rem;
    right: 1.5rem;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    font-size: 1.5rem;
    color: black;
    background-color: transparent;
    border: 1px solid black;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    @include mixins.phone {
      width: 1.5rem;
      height: 1.5rem;
      font-size: 1rem;
    }
  }

  main {

    .innerMain {
      width: 90%;
      max-width: 700px;
      margin: 0 auto;

      @include mixins.minmq(lg) {
        border-radius: 1.625rem;
      }

      .container {
        border: none;
        background-color: transparent;
        margin-bottom: 0;

        @include mixins.minmq(lg) {
          padding: 0;
        }

        .title {

          h1 {
            margin-top: 1rem;
            font-size: 1.5rem;
            font-weight: 500;
            line-height: normal;
            text-align: center;
            white-space: normal;

            @include mixins.phone {
              font-size: 1.2rem;
              margin-top: 3rem;
              white-space: break-spaces;
            }

          }
        }

        .content {

          .formWrapper {
            display: block;

            form {
              display: block;

              fieldset {
                margin-inline-start: 2px;
                margin-inline-end: 2px;
                padding-block-start: 0.35em;
                padding-inline-start: 0.75em;
                padding-inline-end: 0.75em;
                padding-block-end: 0.625em;
                min-inline-size: min-content;

                display: grid;
                grid-template-columns: repeat(2, minmax(20rem, 1fr));
                column-gap: 1rem;
                row-gap: 1rem;
                @include mixins.maxmq(lg) {
                  grid-template-columns: repeat(1, minmax(20rem, 1fr));
                }

                legend {
                  display: none;
                }

                .radioLabel {
                  font-size: 24px;
                  color: black;
                  z-index: 5;

                  span {
                    position: absolute;
                    top: 50%;
                    transform: translate(0, -50%);

                  }
                }
              }
            }
          }
        }
        .stylebtn {
          font-size: 1.3rem;

          @include mixins.maxmq(lg) {
            font-size: 1rem;
          }

          @include mixins.maxmq(sm) {
            font-size: .8rem;
          }
        }

          .success{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 100%;
            margin-top: 5rem;
            .successIcon {
              display: inline-block;
              width: 8.1rem;
              height: 8.1rem;
              background-repeat: no-repeat;
              background-size: 100% 100%;
              background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1024 1024'%3E%3Cpath fill='%2317a41d' d='M512 64a448 448 0 1 1 0 896a448 448 0 0 1 0-896m-55.808 536.384l-99.52-99.584a38.4 38.4 0 1 0-54.336 54.336l126.72 126.72a38.272 38.272 0 0 0 54.336 0l262.4-262.464a38.4 38.4 0 1 0-54.272-54.336z'/%3E%3C/svg%3E");
            }
            .successTitle{
              h1{
                margin-top: 5rem;
                font-size: 1.5rem;
                font-weight: 600;
                line-height: normal;
                text-align: center;
                color: #17a41d;
                @include  mixins.phone{
                  font-size: 1rem;
                }
              }
            }
            .successSubTitle{
              text-align: center;
              margin-top: 2rem;
              color: black;
              font-size: 1.25rem;
              @include  mixins.phone{
                font-size: .9rem;
              }
            }
          }
      }
    }
  }

}


