$colors: (
	"blue": (
		50: hsl(205, 87%, 94%),
		200: hsl(194, 100%, 47%),
		700: hsl(210, 79%, 46%),
		800: hsl(223, 90%, 36%),
		900: hsl(216, 85%, 34%),
	),
	"grey": (
		50: hsl(0, 0%, 100%),
		100: hsl(0, 0%, 90%),
		150: hsl(0, 0%, 85%),
		200: hsl(0, 0%, 80%),
		300: hsl(0, 0%, 70%),
		400: hsl(0, 0%, 60%),
		500: hsl(0, 0%, 50%),
		600: hsl(0, 0%, 40%),
		700: hsl(0, 0%, 30%),
		800: hsl(0, 0%, 20%),
		900: hsl(0, 0%, 10%),
		1000: hsl(0, 0%, 0%),
	),
	"alpha": (
		0: hsla(0, 0%, 100%, 0),
		800: hsla(0, 0%, 13%, 0.8),
	),
	"red": (
		600: hsl(358, 82%, 38%),
		700: hsl(360, 81%, 28%),
	),
	"green": (
		600: hsl(134, 61%, 41%),
	),
);
:root {
	// this eachloop/map create a css variable from each of our color and shades
	@each $color, $shades in $colors {
		@each $shade, $value in $shades {
			--clr-#{$color}-#{$shade}: #{$value};
		}
	}
}
